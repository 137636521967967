import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { PaymentChannelStateInterface } from "./state";
import { SurePayDepositResponse } from "@/types/PaymentChannel/Response/SurePayDepositResponse";

const timezone = new Date().getTimezoneOffset();


export const getters: GetterTree<PaymentChannelStateInterface, RootState> = {
    getSurepayData(state): SurePayDepositResponse{
        return state.surepayDeposit;
    }
}