<template>
  <b-row
    align-h="between"
    align-v="center"
    class="h-100 pt-2 me-0 flex-nowrap w-100"
    v-if="onRefresh"
  >
    <b-col cols="auto" class="h-100 w-100">
      <b-row align-h="center" align-v="center" class="h-100 w-100">
        <b-col cols="auto" class="my-1 btn-skeleton">
          <b-skeleton type="button" width="80px"></b-skeleton>
        </b-col>
        <b-col cols="auto" class="my-1 btn-skeleton">
          <b-skeleton type="button" width="80px"></b-skeleton>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="auto" class="ps-4">
      <b-skeleton type="avatar"></b-skeleton>
    </b-col>
  </b-row>
  <div
    class="d-flex align-items-center w-100 h-100 pt-2 me-0 flex-nowrap justify-content-end"
    v-else
  >
    <div class="d-flex" v-if="!isLogin">
      <div class="loginBtnContainer">
        <b-col cols="auto" class="my-1 px-1" v-if="!isLogin">
          <b-button
            squared
            variant="main-outline"
            v-b-modal="'login-modal'"
            class="headerLoginBtn"
          >
            {{ $t("HeaderLogin.Login") }}
          </b-button>
        </b-col>
        <b-col cols="auto" class="my-1 px-1" v-if="!isLogin && !isMaintenance">
          <b-button
            squared
            variant="main-gradient"
            v-b-modal="'signup-modal'"
            class="headerLoginBtn"
          >
            {{ $t("HeaderLogin.SignUp") }}
          </b-button>
        </b-col>
        <b-col cols="auto" class="my-1 px-1" v-if="!isLogin && isMaintenance">
          <b-button
            squared
            variant="main-gradient"
            class="headerLoginBtn"
            @click="maintenancepopup"
          >
            {{ $t("HeaderLogin.SignUp") }}
          </b-button>
        </b-col>
      </div>
    </div>

    <b-col cols="auto" class="px-1 d-none d-md-block" v-if="isLogin">
      <template v-if="isMaintenance">
        <b-button
          squared
          variant="main-outline"
          @click="maintenancepopup"
          class="headerLoginBtn"
          :class="{ disabled: !checkIsLoadMemberBank }"
        >
          {{ $t("HeaderLogin.Deposit") }}
        </b-button>
      </template>
      <template v-else-if="this.checkIsMemberBankExist === true">
        <b-button
          squared
          variant="main-outline"
          v-b-modal="'deposit-modal'"
          class="headerLoginBtn"
          :class="{ disabled: !checkIsLoadMemberBank }"
        >
          {{ $t("HeaderLogin.Deposit") }}
        </b-button>
      </template>
      <template v-else>
        <b-button
          squared
          variant="main-outline"
          v-b-modal="'bankInfo-modal'"
          class="headerLoginBtn"
          :class="{ disabled: !checkIsLoadMemberBank }"
        >
          {{ $t("HeaderLogin.Deposit") }}
        </b-button>
      </template>
    </b-col>
    <b-col cols="auto" class="d-none d-md-block px-0" v-if="isLogin">
      <div class="d-flex align-items-center">
        <b-col cols class="px-0 text-end">
          <div class="position-relative ownBalanceContainer">
            <div class="me-2">
              RM {{ getBalance }}
              <span>
                <font-awesome-icon
                  :icon="['fas', 'arrows-rotate']"
                  :class="{ 'fa-spin': isSpin }"
                  @click="refreshWallet"
                  class="ms-1"
                >
                </font-awesome-icon>
              </span>
            </div>
            <div class="position-absolute ssoBalanceContainer">
              <div class="triangle"></div>
              <div
                v-for="item in this.getAllSSOBalance"
                :key="item"
                class="d-flex justify-content-between ssoContainer"
              >
                <span>{{ item.clientName }}</span>
                <span>{{ item.balance.toFixed(2) }}</span>
              </div>
            </div>
          </div>
          <div cols="12" class="welcomeName">
            <b-dropdown
              id="myAccount-dropdown"
              variant="transparent"
              class="text-white ms-1 w-100 myAccount-dropdown"
              no-caret
              :disabled="!checkIsLoadMemberBank"
            >
              <template #button-content>
                {{ $t("HeaderLogin.Hi") }} {{ getMemberID }}
                <font-awesome-icon :icon="['fas', 'angle-down']" class="ms-1">
                </font-awesome-icon>
              </template>
              <template v-for="navbar in myAccountList" :key="navbar.title">
                <template v-if="isMaintenance && !navbar.ignoreMaintenance">
                  <b-dropdown-item @click="maintenancepopup">
                    {{ $t("HeaderNavLink." + navbar.title) }}
                  </b-dropdown-item>
                </template>
                <template
                  v-else-if="
                    navbar.validateBank === false ||
                    this.checkIsMemberBankExist === true
                  "
                >
                  <router-link
                    v-if="
                      navbar.isRouteLink === true && navbar.isPopUp === false
                    "
                    :to="navbar.link"
                  >
                    <b-dropdown-item @click="doAction(navbar.title)">
                      {{ $t("HeaderNavLink." + navbar.title) }}
                    </b-dropdown-item>
                  </router-link>
                  <b-dropdown-item
                    v-else-if="
                      navbar.isRouteLink === false && navbar.isPopUp === true
                    "
                    v-b-modal="navbar.link"
                  >
                    {{ $t("HeaderNavLink." + navbar.title) }}
                  </b-dropdown-item>
                  <b-dropdown-item v-else @click="doAction(navbar.title)">
                    {{ $t("HeaderNavLink." + navbar.title) }}
                  </b-dropdown-item>
                </template>
                <template v-else>
                  <b-dropdown-item v-b-modal="'bankInfo-modal'">
                    {{ $t("HeaderNavLink." + navbar.title) }}
                  </b-dropdown-item>
                </template>
              </template>
            </b-dropdown>
          </div>
        </b-col>
      </div>
    </b-col>
    <b-col cols="auto" class="ps-2">
      <!-- <select
				v-model="$root.$i18n.locale"
				@change="changeLang()"
				variant="transparent"
				class="langDropdown bg-transparent text-white border-0"
			>
				<option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="i">
					{{ lang }}
				</option>
			</select> -->
      <b-dropdown
        variant="transparent"
        class="langDropdown bg-transparent text-white border-0 px-0"
      >
        <template #button-content>
          <img :src="getCurrentLang" class="selectedLangIcon"
        /></template>
        <b-dropdown-item
          v-for="item in clientlanguagelist"
          :key="`Lang${item.id}`"
          @click="changeLang(item.languageCountryCode.substring(0, 2))"
        >
          <img :src="langs[item.languageCountryCode.substring(0, 2)]" class="selectedLangIcon" />
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </div>
</template>

<style scoped>
.btn-skeleton {
  width: 100px;
}
.dropdown-menu {
  min-width: unset !important;
}
.langDropdown {
  text-transform: capitalize;
  text-align: center;
}
.langDropdown > option {
  background: #471210;
}
.selectedLangIcon {
  width: 36px;
  height: auto;
  border-radius: 50px;
  box-sizing: border-box;
  border: 2px #6a91d2 solid;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 80%);
}
.welcomeName {
  white-space: nowrap;
  font-size: 14px;
  text-align: right;
}
.welcomeLoginId {
  white-space: nowrap;
  font-size: 16px;
  text-align: right;
}
a,
a:hover {
  text-decoration: none;
}

.loginBtnContainer {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
}
.ssoBalanceContainer {
  background-color: white;
  color: black;
  width: 200px;
  left: -2rem;
  top: 1.9rem;
  z-index: 20;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 20px 0px gray;
  display: none;
  padding: 0.5rem 0;
}

.ssoContainer {
  margin: 0.3rem 1rem 0 1rem;
  font-size: 0.8rem;
}
.triangle {
  position: absolute;
  bottom: 100%;
  left: 100%;
  margin-left: -4.5rem;
  width: 1.25rem;
  height: 0.7rem;
  overflow: hidden;
}

.triangle::after {
  content: "";
  position: absolute;
  width: 0.72rem;
  height: 0.72rem;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background: white;
  box-shadow: 0 0.01rem 0.06rem rgb(0 0 0 / 30%);
}
.ownBalanceContainer:hover .ssoBalanceContainer {
  display: block;
}

@media (max-width: 767px) {
  .loginBtnContainer {
    flex-direction: row;
  }
}
@media (max-width: 400px) {
  .headerLoginBtn {
    padding: 8px 15px !important;
  }
}
</style>
<style>
.myAccount-dropdown > .btn {
  padding-top: 0 !important;
}
</style>
<script>
import { navBar } from "@/constant";
export default {
  name: "locale-changer",
  data() {
    return {
      langs: {
        en:
          process.env.VUE_APP_Media_Domain +
          "ibc/assets/images/NavBar/en-US.svg",
        zh:
          process.env.VUE_APP_Media_Domain + "ibc/assets/images/NavBar/zh.svg",
        ms:
          process.env.VUE_APP_Media_Domain +
          "ibc/assets/images/NavBar/ms-MY.svg",
      },
      loginForm: {
        phoneNo: "",
        password: "",
      },
      memberID: null,
      balance: 0,
      passwordFieldType: "password",
      isSpin: false,
      clientlanguagelist: []
    };
  },
  components: {},
  methods: {
    changeLang(currentLang) {
      this.$root.$i18n.locale = currentLang;
      localStorage.setItem("locale", currentLang);
    },
    doAction(goto) {
      if (goto == "Logout") {
        this.logOut();
      }
    },
    logOut() {
      let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
      this.$store.dispatch("identityServer/logOut");
      this.toast.success(this.$t("HeaderLogin.LogoutSuccess"), {
        position: toastPosition,
        timeout: 2000,
        onClose: () => this.redirectHomePage(),
      });
    },
    redirectHomePage() {
      window.location.href = "/";
    },
    async refreshWallet() {
      if (this.isMaintenance) {
        this.maintenancepopup();
      } else {
        this.isSpin = true;

        await this.$store.dispatch(
          "memberAccount/fetchVendorBalanceByTenantId"
        );
        await this.$store
          .dispatch("memberAccount/fetchVendorBalance", { isFromDB: false })
          .then(() => {
            this.isSpin = false;
          });
        const latestBalance =
          this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
        const memberWinover =
          this.$store.getters["memberAccount/getMemberWinover"];
        if (memberWinover.winoverBalances.length > 0) {
          const latestWinover = memberWinover.winoverBalances[0].winover;
          const group = memberWinover.winoverBalances[0].category;
          const lockdown = memberWinover.winoverBalances[0].lockdown;
          if (latestWinover > 0) {
            await this.getVedorCategoryList(
              latestBalance,
              group,
              latestWinover,
              lockdown
            );
          }
        }
        this.balance;
      }
    },
    async checkGotMemberBank() {
      await this.$store.dispatch("member/fetchMemberBank");
    },
    async fetchMemberWinover() {
      await this.$store.dispatch("memberAccount/fetchMemberWinover", {
        loginId: this.getLoginId,
        isFromDB: true,
      });
    },
    async getVedorCategoryList(latestBalance, group, latestWinover, lockdown) {
      await this.$store.dispatch("vendors/fetchCategoryList").then(() => {
        const vendorCategory = this.$store.getters["vendors/getCategory"];
        if (latestBalance <= vendorCategory.resetThreshold) {
          this.resetWinover(group, latestWinover, lockdown);
          this.fetchMemberWinover();
        }
      });
    },
    async resetWinover(group, latestWinover, lockdown) {
      await this.$store.dispatch("memberAccount/resetWinover", {
        group: group,
        winover: latestWinover,
        lockdown: lockdown,
      });
    },
    async maintenancepopup() {
      let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
      this.toast.error(this.$t("HeaderLogin.GameMaintenance"), {
        position: toastPosition,
        timeout: 0,
        //onClose: () => this.redirectHomePage(),
      });
    },
  },
  computed: {
    onRefresh() {
      return this.$store.getters["identityServer/getOnRefresh"];
    },
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    getCurrentLang() {
      return this.langs[this.$root.$i18n.locale];
    },
    getMemberID() {
      if (this.memberID) {
        return this.memberID;
      } else if (this.$store.getters["identityServer/getLoginId"]) {
        return "+60" + this.$store.getters["identityServer/getLoginId"];
      } else {
        return this.$t("HeaderLogin.Player");
      }
    },
    getBalance() {
      return this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
    },
    myAccountList() {
      var navbarFiltered = navBar.filter((i) => i.afterLogin == true);
      return navbarFiltered;
    },
    getLoginStatus() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    checkIsLoadMemberBank() {
      return this.$store.getters["member/getOnLoadMemberBankStatus"];
    },
    isMaintenance() {
      return this.$store.getters["vendors/getVendor"].maintenance;
    },
    getAllSSOBalance() {
      return this.$store.getters["memberAccount/getAllSSOMemberBalance"];
    },
  },
  watch: {
    async getLoginStatus(curVal) {
      if (curVal) {
        await this.$store.dispatch("vendors/fetchVendorAccount");
        await this.$store.dispatch("memberAccount/fetchVendorBalance", {
          isFromDB: false,
        });
        await this.$store.dispatch(
          "memberAccount/fetchVendorBalanceByTenantId"
        );
        await this.checkGotMemberBank();
      }
    },
  },
  async created() {
    await this.$store.dispatch("language/fetchClientLanguageList").then(() => {
       this.clientlanguagelist = this.$store.getters["language/getClientLanguage"];      
    });
  },
};
</script>
