import httpClient, { ClientId } from "./HttpClient";
import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";

const getMiniGamesList = (): Promise<MiniGamesListResponse[]> => {
	const endPoint = `/api/SpinningWheel/SpinningWheels`;
	const params = {
		clientId: ClientId,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

const getMiniGamesUrl = (lang: string): Promise<string> => {
	const endPoint = `/api/Url/Internal`;
	const params = {
		clientId: ClientId,
		lang: lang,
	};
	const data = httpClient.get(endPoint, { params }).then((response) => response.data);
	return data;
};

export { getMiniGamesList, getMiniGamesUrl };
