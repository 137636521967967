export default {
	SignUp: "注册",
	PhoneNumber: "电话号码",
	Password: "密码",
	Show: "显示",
	Hide: "隐藏",
	PhoneRequired: "*请填写手机号码",
	PhoneNumeric: "*手机号码必须是数字",
	PhoneMin: "*手机号码必须至少{minLength}位数",
	PhoneMax: "*手机号码必须最多{maxLength}位数",
	PasswordRequired: "*请填写密码",
	PasswordMin: "*密码必须至少{minLength}位数",
	PasswordMax: "*密码必须最多{maxLength}位数",
	ConfirmPassword: "确认密码",
	ConfirmPasswordRequired: "*请填写确认密码",
	Desc1: "我已经18岁以上，我接受这些 ",
	Desc2: " 以及 ",
	VerificationNumber: "验证号码",
	TACCode: "验证码",
	Resend: "重发",
	ConfirmTAC: "确认验证码",
	TACCodehasbeensenttoyourcontactnumber: "验证码已发送至您的联系电话。",
	TermsandConditions: "条款与条件",
	PrivacyPolicy: "隐私政策",
	NotSamePassword: "*确认密码与密码不相同",
	AcknowledgeUncheck: "*请同意我们的条款以及条件",
	RequestTACSuccess: "索取验证码成功",
	RequestTACFailed: "索取验证码失败",
	RegisterSuccess: "注册成功",
	RegisterError: "注册失败",
	VerifyTACError: "验证码验证失败",
	Next: "继续",
	Account: "您的户口已",
	Success: "成功注册",
	ReferralCode: "推荐码",
	InvalidReferralCode: "推荐码错误",
};
