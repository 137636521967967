import { ActionTree } from "vuex";
import { getLanguage, getClientLanguage } from "@/network/LanguageService/Language";
import { LanguageStateInterface } from "./state";
import { RootState } from "@/store/state";
import { LanguageMutations } from "./mutations";

export const actions: ActionTree<LanguageStateInterface, RootState> = {
  async fetchLanguageList(context) {
    if(context.state.languageList.length > 0){
			return;
		}
    const res = await getLanguage();
    context.commit(LanguageMutations.SET_LANGUAGE_LIST, res);
  },
  async fetchClientLanguageList(context) {
    if(context.state.clientLanguageList.length > 0){
			return;
		}
    const res = await getClientLanguage();
    context.commit(LanguageMutations.SET_CLIENT_LANGUAGE_LIST, res);
  }
};
