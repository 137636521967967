import { ActionTree } from "vuex";
import { getContact } from "@/network/BrandService/Brand";
import { BrandStateInterface } from "./state";
import { RootState } from "@/store/state";
import { BrandMutations } from "./mutations";

export const actions: ActionTree<BrandStateInterface, RootState> = {
	async fetchBrand(context) {
		if(context.state.contact.length > 0){
			return;
		  }
		const res = await getContact();
		context.commit(BrandMutations.SET_CONTACT_LIST, res);
	},
};
