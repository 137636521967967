export default {
	ForgotPassword: "Lupa Kata Laluan",
	PhoneNumber: "Nombor Telefon",
	Submit: "Hantar",
	PhoneRequired: "*Nombor Telefon diperlukan",
	PhoneNumeric: "*Nombor Telefon mesti berangka",
	PhoneMin: "*Nombor Telefon mesti sekurangnya {minLength} bilangan",
	PhoneMax: "*Nombor Telefon mesti tidak melebihi {maxLength} bilangan",
	SubmitSuccess: "Berjaya Hantar",
	SubmitFailed: "Hantar Gagal",
	requestTac: "Minta TAC",
	verificationCode: "Kod Pengesahan",
	resendInSeconds: "Serahkan semula dalam {time} saat",
	TACRequired: "*Kod Pengesahan diperlukan",
	back: "Balik",
	resendTac: "Serahkan semula TAC",
	tacSuccess: "Berjaya meminta TAC. Sila semak telefon anda.",
	tacFailed: "Permintaan TAC gagal. Sila cuba sebentar lagi.",
	tacVerificationFailed: "Pengesahan TAC gagal.",
	minLength: "Sila masukkan sekurang-kurangnya {minLength} aksara",
	maxLength: "Melebihi had aksara: {maxLength}",
};
