import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { MemberStateInterface } from "./state";
import { MemberBankInfoResponse } from "@/types/Member/Response/MemberBankInfoResponse";
import { MemberResponse } from "@/types/Member/Response/MemberResponse";
import { MemberReferralResponse } from "@/types/Member/Response/MemberReferralResponse";
import { MemberGroupResponse } from "@/types/Member/Response/MemberGroupResponse";
import { MemberCurrentTierInfoResponse } from "@/types/Member/Response/MemberCurrentTierInfoResponse";

export const getters: GetterTree<MemberStateInterface, RootState> = {
  getMemberBank(state): MemberBankInfoResponse {
    return state.memberBank;
  },
  getMember(state): MemberResponse {
    return state.member;
  },
  getMemberCount(state): number {
    return state.memberCount;
  },
  getOnLoadMemberBankStatus(state) {
    return state.onLoadMemberBank;
  },
  getIsMemberBankExist(state) {
    return state.isMemberBankExist;
  },
  getMemberReferral(state): MemberReferralResponse {
    return state.memberReferral;
  },
  getMemberGroup(state): MemberGroupResponse {
    return state.memberGroup;
  },
  getMemberCurrentTierInfo(state): MemberCurrentTierInfoResponse {
    return state.memberCurrentTierInfo;
  },
};
