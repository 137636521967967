import { WinLossesStateInterface } from "./state";
import { BetHistoryResponse } from "@/types/WinLosses/Response/BetHistoryResponse";
import { MutationTree } from "vuex";

export enum WinLossesMutations {
    SET_BET_HISTORY = "SET_BET_HISTORY",
}

export const mutations: MutationTree<WinLossesStateInterface> = {
    [WinLossesMutations.SET_BET_HISTORY](state, payload: BetHistoryResponse[]) {
        state.betHistory = payload;
    }
};
