import { ActionTree } from "vuex";
import { getDepositBank, getWithdrawalBank } from "@/network/BankService/Bank";
import { BankStateInterface } from "./state";
import { RootState } from "@/store/state";
import { BankMutations } from "./mutations";

export const actions: ActionTree<BankStateInterface, RootState> = {
  async fetchDepositBank(context) {
    const res = await getDepositBank();
    context.commit(BankMutations.SET_DEPOSIT_BANK, res);
  },
  async fetchWithdrawalBank(context) {
    const res = await getWithdrawalBank();
    const filteredWithdrawalBank = res.filter((x) => (x.type == 1 || x.bankCode == "TNG") && x.status == true);
    context.commit(BankMutations.SET_WITHDRAWAL_BANK, filteredWithdrawalBank);
  },
};
