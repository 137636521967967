export default {
	Referral: "Referral",
	MinDepositRequired: "A minimum of {minDeposit} deposits is required to participate in the referral program offer.",
	ReferralCode: "Referral Code",
	Copy: "Copy",
	ReferralLink: "Referral Link",
	SuccessfulRegistrations: "Successful Registrations",
	SuccessfulDeposited: "Successful Deposited",
	MinDeposited: "Min. {minDepositAmount}",
	ShareToFriends: "Share to Friends",
	ShareNow: "SHARE NOW",
	Facebook: "Facebook",
	WhatsApp: "WhatsApp",
	Telegram: "Telegram",
	Email: "Email",
	LINE: "LINE",
	Twitter: "Twitter",
	CopySuccess: "Copy Success",
	CopyError: "Empty Copy",
	TermConditionReferral: "Terms and conditions apply to all referrals and bonuses.",
};
