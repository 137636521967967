<template>
	<div></div>
</template>

<script>
export default {
	data() {
		return {};
	},
	props: ["token", "locale"],
	async created() {
		if (this.locale !== null) {
			this.$root.$i18n.locale = this.locale;
			localStorage.setItem("locale", this.locale);
		}
		if (this.token !== null) {
			await this.$store.dispatch("identityServer/setInterchangeStatus", true);
			const tokenString = this.token;
			await this.$store.dispatch("identityServer/tokenTransfer", { token: tokenString });
			await this.$store.dispatch("member/exchangeToken");
		}
		window.location.replace(window.location.protocol + "//" + window.location.host + "/");
	},
};
</script>
