export default {
  JoinUs: "Join Us",
  Gamblingcanbeaddictive: "Gambling can be addictive",
  Playresponsibly: "Play Responsibly.",
  Information: "Information",
  About: "About Us",
  Terms: "Terms",
  Privacy: "Privacy Policy",
  ResponsibleGaming: "Responsible Gaming",
  TalktoUs: "Talk to Us",
  Copyright: "Copyright",
  AllRightsReserved: "All Rights Reserved.",
};
