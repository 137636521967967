import { IpAddressStateInterface } from "./state";
import { MutationTree } from "vuex";

export enum IPAddressMutations {
  SET_IP_ADDRESS = "SET_IP_ADDRESS",
}

export const mutations: MutationTree<IpAddressStateInterface> = {
  [IPAddressMutations.SET_IP_ADDRESS](state, payload: string) {
    state.ipAddress = payload;
  },
};
