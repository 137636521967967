import { ActionTree } from "vuex";
import { getBanner } from "@/network/BannerService/Banner";
import { BannerStateInterface } from "./state";
import { RootState } from "@/store/state";
import { BannerMutations } from "./mutations";

export const actions: ActionTree<BannerStateInterface, RootState> = {
  async fetchBanner(context) {
    if(context.state.banner.length > 0){
      return;
    }
    const res = await getBanner();
    context.commit(BannerMutations.SET_BANNER, res);
  },
};
