import { RunningTextResponse } from "@/types/Announcement/Response/RunningTextResponse";
import { AnnouncementResponse } from "@/types/Announcement/Response/AnnouncementResponse";

export interface AnnouncementStateInterface {
  runningText: RunningTextResponse[];
  announcement: AnnouncementResponse[];
}

function state(): AnnouncementStateInterface {
  return {
    runningText: [],
    announcement: [],
  };
}

export default state;
