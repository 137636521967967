import { ActionTree } from "vuex";
import winLossesService from "@/network/WinLossesService/WinLosses";
import { WinLossesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { WinLossesMutations } from "./mutations";

export const actions: ActionTree<WinLossesStateInterface, RootState> = {
  async fetchBetHistory(context, payload) {
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const res = await winLossesService.getBetHistory(memberId, payload.from, payload.to);
    context.commit(WinLossesMutations.SET_BET_HISTORY, res);
  },
};
