export default {
	ForgotPassword: "Forgot Password",
	PhoneNumber: "Phone Number",
	Submit: "Submit",
	PhoneRequired: "*Phone Number is required",
	PhoneNumeric: "*Phone Number must be numeric",
	PhoneMin: "*Phone Number at least {minLength} digit",
	PhoneMax: "*Phone Number not more than {maxLength} digit",
	SubmitSuccess: "Submit Success",
	SubmitFailed: "Submit Failed",
	requestTac: "Request TAC",
	verificationCode: "Verification Code",
	resendInSeconds: "Resend in {time} second(s)",
	TACRequired: "*Verification Code is required",
	back: "Back",
	resendTac: "Resend TAC",
	tacSuccess: "TAC request success. Please check your phone.",
	tacFailed: "TAC request failed. Please try again later.",
	tacVerificationFailed: "TAC verification failed.",
	minLength: "Please enter a minimum of {minLength} characters",
	maxLength: "Exceeded character limit: {maxLength}",
};
