export default {
	Withdraw: "Pengeluaran",
	withdrawalAmount: "*Jumlah Pengeluaran",
	accountBank: "Bank",
	accountName: "Name Akaun",
	accountNumber: "Nombor Akaun",
	AmountRequired: "*Jumlah pengeluaran diperlukan.",
	Min: "Min: {withdrawalMin}",
	Max: "Max: {withdrawalMax}",
	Currentbalance: "Baki terkini:",
	LastLogin: "Log Masuk Kali Terakhir",
	AmountMin: "*Jumlah Nombor sekurang-kurangnya {minValue} ",
	AmountMax: "*Jumlah Nombor tidak lebih daripada {maxValue} ",
	Cancel: "Batal",
	WithdrawalSubmitted: "Pengeluaran Berjaya Dihantar",
	WithdrawalError: "Pengeluaran Gagal",
	WinoverExist: "Winover wujud",
	ReachDailyLimit: "Anda telah mencapai had pengeluaran harian anda. Sila hubungi perkhidmatan pelanggan kami.",
	InsufficientBalance: "Baki anda tidak mencukupi",
	AllowOneActiveWithdrawal: "Permintaan pengeluaran anda sebelum ini masih dalam proses. Sila hubungi perkhidmatan pelanggan kami jika anda memerlukan sebarang bantuan.",
};
