<template>
  <div class="floatingMiniGamesContainer" v-if="Object.keys(getMiniGames).length != 0">
    <div class="miniGameContainer" v-show="getMiniGames.isShow">
      <div class="imgLink" :title="getMiniGames.miniGameType.name">
        <BaseImage
          :imgSrc="getMiniGames.image"
          :isFullUrl="true"
          class="gameImg"
          @click="launchMiniGame()"
          v-if="getMiniGames.image != null"
        ></BaseImage>
        <BaseImage
          :isGameImg="true"
          :isFullUrl="false"
          :imgSrc="'MiniGames/' + getMiniGames.miniGameType.name.replace(' ', '') + '/entrance.gif'"
          class="gameImg"
          @click="launchMiniGame()"
          v-else
        ></BaseImage>
        <div class="btn-close-wheel" @click="hideThisGame()"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.floatingMiniGamesContainer {
  position: fixed;
  bottom: 0;
  left: 0.5rem;
  z-index: 60;
}
.miniGameContainer {
  position: relative;
}

.miniGameContainer .gameImg {
  width: 8rem !important;
}

.miniGameContainer .imgLink {
  cursor: pointer;
}

.btn-close-wheel {
  position: absolute;
  z-index: 65;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  background-color: #000;
  border-radius: 50%;
  border: none;
}

.btn-close-wheel::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 15px;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}

.btn-close-wheel::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 15px;
  background-color: #fff;
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media screen and (max-width: 768px) {
  .floatingMiniGameContainer {
    bottom: 65px;
  }
}
</style>
<script>
import BaseImage from "@/components/dynamic/BaseImage.vue";
import { getCultureID, getDefaultCultureCode } from "@/currentCulture";
export default {
  data() {
    return {
      miniGames: {},
      newtab: null,
    };
  },
  computed: {
    getMiniGames() {
      return this.miniGames;
    },
  },
  components: { BaseImage },
  methods: {
    async fetchMiniGamesList() {
      await this.$store.dispatch("miniGames/fetchMiniGames").then(() => {
        const miniGamesList = this.$store.getters["miniGames/getMiniGamesList"];
        if (miniGamesList.length > 0) {
          this.miniGames = miniGamesList[0];
          this.miniGames.isShow = true;
          this.getMiniGamesUrl();
        }
      });
    },
    async getMiniGamesUrl() {
      const cultureCode = getDefaultCultureCode();
      await this.$store.dispatch("miniGames/fetchMiniGamesUrl", { lang: cultureCode }).then((res) => {
        this.miniGames.url = res;
      });
    },
    hideThisGame() {
      this.miniGames.isShow = false;
    },
    launchMiniGame() {
      if (this.miniGames.url) {
        const miniGameUrl = new URL(this.miniGames.url);
        if (this.newTab && !this.newTab.closed) {
          this.newTab.postMessage("reload", miniGameUrl.protocol + "//" + miniGameUrl.host);
        } else {
          this.newTab = window.open(this.miniGames.url, "_blank");
        }
      }
    },
  },
  watch: {
    "$i18n.locale": function (newLanguage) {
      if (Object.keys(this.getMiniGames).length != 0) {
        this.getMiniGamesUrl();
      }
    },
  },
  mounted() {
    this.fetchMiniGamesList();
  },
};
</script>
