import { LanguageResponse, ClientLanguageResponse } from "@/types/Language/Response/LanguageResponse";

export interface LanguageStateInterface {
    languageList: LanguageResponse[];
    clientLanguageList: ClientLanguageResponse[];
  }
  
  function state(): LanguageStateInterface {
    return {
        languageList: [],
        clientLanguageList: []
    };
  }
  
  export default state;