export default {
	Today: "今天",
	Tomorrow: "明天",
	Upcoming: "即将到来",
	EnjoySport: "享受最佳赔率的最佳赛事之一",
	SupportYourTeam: "支持您喜欢的球队！",
	vs: "精彩对决",
	notstarted: "未开始",
	h1: "上半场",
	hf: "中场休息",
	h2: "下半场",
	finished: "已结束",
	ViewAll: "查看全部",
	WorldCupStartIn: "2022卡塔尔世界杯开幕倒数",
	days: "天",
	hours: "小时",
	minutes: "分钟",
	seconds: "秒",
	BetNow: "立即下注",
	WorldCupIsHappenNow: "2022卡塔尔世界杯已经开始",
	WorldCupIsEnd: "2022卡塔尔世界杯已经结束了",
	PreviousMatches: "已往的赛事",
};
