import { BannerResponse } from "@/types/Banner/Response/BannerResponse";

export interface BannerStateInterface {
  banner: BannerResponse[];
}

function state(): BannerStateInterface {
  return {
    banner: [],
  };
}

export default state;
