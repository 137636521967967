import { MiniGamesStateInterface } from "./state";
import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";
import { MutationTree } from "vuex";

export enum MiniGamesMutations {
  SET_MINIGAMES_LIST = "SET_MINIGAMES_LIST",
}

export const mutations: MutationTree<MiniGamesStateInterface> = {
  [MiniGamesMutations.SET_MINIGAMES_LIST](state, payload: MiniGamesListResponse[]) {
    state.miniGamesList = payload;
  },
};
