export default {
  WelcomVIPDesc: "月度排名",
  Red: "红卡",
  Silver: "银卡",
  Gold: "金卡",
  Platinum: "铂金卡",
  Diamond: "钻石卡",
  Royal: "黑金卡",
  Regular: "Regular",
  DepositPertama: "首存款",
  firstDeposit: "首存款",
  MinDeposit: "最低存款",
  VIPPrivileges: "贵宾福利",
  giftTreats: "礼物与奖品",
  tierUpgradeBonus: "升级奖金",
  birthdayBonus: "生日奖金",
  dailyRescueBonus: "每日救援金",
  weeklyRescueBonus: "每周救援金",
  designatedAccountManager: "专属 VIP 助理",
  SpecialRebate: "特别回扣",
  SlotsRebate: "老虎机返水",
  LiveCasinoRebate: "真人返水",
  SportsRebate: "体育返水",
  tierUpgradeRequirement: "等级升级要求",
  minDepositRotation: "存款数额",
  tierRetentionRequirement: "等级保留要求",
  retentionTotalDeposit: "最低存款",
  membershipRotation: "等级保留期",
  minDeposit: "存款数额",
  within_DAILY: "1天內 ",
  within_WEEKLY: "1周內 ",
  within_MONTHLY: "1个月內",
  within_QUARTERLY: "3个月內",
  "within_HALF YEARLY": "6个月後",
  within_YEARLY: "1年內",
  within_LIFETIME: "永久期限內",
  Lifetime: "永久",
  Quarterly: "每三个月",
  VIPExclusive: "贵宾专属",
  Yes: "是",
  ONCE: "一次",
  ALWAYS: "总是",
  DAILY: "每日",
  WEEKLY: "每周",
  MONTHLY: "每月",
  QUARTERLY: "三个月",
  "HALF YEARLY": "半年",
  YEARLY: "年度",
  LIFETIME: "永久",
  CurrentStatus: "当前状态",
  memberVIPDesc: "VIP 会员等级",
  FAQs: "常见问题解答",
  QuestionHowToBecomeAVIPMember: "问: 如何成为 VIP 会员？",
  AnswerHowToBecomeAVIPMember: "答: 您只需要至少存款RM{0} 在当季（{1}个月）既可成为银卡会员。",
  QuestionHowManyLevel: "问: 有多少个 VIP 级别?",
  AnswerHowManyLevel: "答: 总共有6个级别。有红卡，银卡，金卡，铂金卡，钻石卡和黑金卡。",
  QuestionHowToUpgradeNextLevel: "问: 如何晋升到下一个级别？",
  AnswerHowToUpgradeNextLevel:
    "答: 您只需要达到每一个指定级别的存款既可。例如，如果您目前是红卡会员，您需要存入至少 RM10,000 才能成为银卡会员，而从银卡会员开始，您需要再存入至少 RM30,000 才能成为金卡会员。",
  QuestionHowlongMemberstatus: "问: VIP 会员级别的有效期是多久？",
  AnswerHowlongMemberstatus: "答: 一旦升级后，有效期为一年。",
  QuestionHowDoIRetainMembership: "问: 在有效期结束之前，我要如何维持我的会员级别?",
  AnswerHowDoIRetainMembership:
    "答: 对于维持1年的会员级别，您将需要在有效期结束之前的最后一个季节达到指定的存款金额。例如：如果您的金卡会员身份在一月至十二月期间有效，您则需要在同一年的最后季度（十月）达到RM15,000 的存款，以维持下一年度的金卡会员级别。红卡和银卡的会员级别则是终身有效。",
  QuestionIsThereAnnualFees: "问： 作为 VIP 会员，是否需要支付月费或年费？",
  AnswerIsThereAnnualFees: "答：不需要，这个项目旨在为所有忠诚会员并积极支持我们的玩家给予回馈和更好的游戏体验。",
  ImportantNote: "重要公告:  ",
  TermsConditions: "条款及条件 ",
  tnc1: "首次升级时，将会要求会员提供所有必要的文件以进行账户验证。当验证核实完毕后，会员的VIP级别状态将在3个工作日内更新到系统中。.",
  TheVerificationStepsAreAsFollows: "验证账户步骤如下:",
  tnc1stepI: "验证帐户详细信息（全名，电话号码和电子邮件地址）",
  tnc1stepII: "验证帐户活动（投注方式，存款和提款记录）",
  tnc1ImportantNote: "在为期3天的验证期内，如有发现任何可疑或异常活动，管理层将保留或拒绝该会员的升级资格权利。",
  tnc2: "一旦升级后即可享有所有的 VIP 特权，并且根据当时达到的最高级别为准，有效期为一年。",
  tnc2ImportantNote: "为了保持您的会员级别状态，会员必须在其中一个季度内达到相同的需求以延长级别的有效期。",
  tnc3: "所有升级 VIP 级别状态的邀请将由 Pussy888 的 VIP 部门发出。",
  tnc4: "若发现有任何可疑或异常活动的 VIP 账户，Pussy888 将保留随时可以撤销该会员的 VIP 特权，降级或终止该会员的 VIP 级别的权利。恕不另行通知",
  tnc4ImportantNote: "每个会员级别状态都会根据帐户活动记录而降级。",
  tnc5: "所有 Pussy888 管理层决定视为最终决定。",
  welcomVIPDesc: "终极贵宾厅",
  welcomVIPDesc0: "提升您的游戏之旅",
  welcomVIPDesc1: "成为 Pussy888 VIP",
  welcomVIPDesc2:
    "介绍终极 Pussy888 VIP，为我们忠实的 Pussy888 会员提供更高层次的福利：超越一般的奖励，我们为 VIP 会员定制了独家特别促销活动，以及最高奖励率的额外游玩次数另外，我们的 VIP 会员在所有事项上都优先处理，无论是涉及存款、提款还是任何与游戏相关的问题，他们的要求都会被优先处理。",
  welcomVIPDesc3: "VIP 升级必须进行身份验证",
  welcomVIPDesc4: "推荐即可获得奖励！",
  welcomVIPDesc5:
    "我们的推荐计划为现有 VIP 会员带来了朋友带来的额外福利和家人加入我们的 VIP 计划，Pussy888 推荐计划旨在为现有会员提供纸质外的传播 VIP 会员优势的动机，也是我们的方式对您的忠心以及您在搞大Pussy888 VIP 方面所发挥的作用表示感谢社区。有朋友愿意和您一起进入 VIP 休息室吗？不要-今天就推关他们！令人兴奋的奖励和奖金即将到来！",
  Welcome: "欢迎",
  NextLevel: "Next Level",
  ByInvitation: "By Invitation",
  Current: "Current",
  Prev: "Prev",
  Next: "Next",
  10000: "MYR 10000",
  50000: "MYR 50000",
  500000: "MYR 500000",
  100000: "MYR 100000",
  1000000: "MYR 1000000",
};
