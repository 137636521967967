export default {
  StartDate: "开始日期*",
  EndDate: "结束日期*",
  Submit: "提交",
  ID: "ID",
  BetID : "投注号",
  GameName : "游戏名",
  Provider: "游戏供应商",
  BetAmount: "投注金额",
  WinLossAmount: "盈利/亏损",
  GameDate: "日期",
};
