import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { BannerStateInterface } from "./state";
import { BannerResponse } from "@/types/Banner/Response/BannerResponse";

export const getters: GetterTree<BannerStateInterface, RootState> = {
	getBanner(state) {
		return (isMobile?: boolean): BannerResponse[] => {
			const bannerList = state.banner
				.filter((bannerItem) => {
					return isMobile ? bannerItem.isMobile : !bannerItem.isMobile;
				})
				.sort(function (a, b) {
					return a.sequence - b.sequence;
				});

			return bannerList;
		};
	},
};
