<template>
	<b-container fluid class="px-0 qatarbanner">
		<base-image :imgSrc="homeQatarImg" mode="qatarBannerImg" :isFullUrl="false" />
	</b-container>
</template>

<script>
export default {
	data() {
		return {
			homeQatarImg: "ibc/assets/images/WorldCup/homeSabaSportBanner.png",
		};
	},
	methods: {},
	computed: {},
};
</script>

<style scoped>
.qatarbanner {
	background: #d8e2e8;
}
</style>
