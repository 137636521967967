export default {
	SignUp: "立即注册",
	Login: "登入",
	ForgotYourPassword: "忘记你的密码?",
	NotAMemberYet: "还不是会员？",
	PhoneNumber: "电话号码",
	Password: "密码",
	LoginWin: "登入与赢取",
	Show: "显示",
	Hide: "隐藏",
	PhoneRequired: "*请填写手机号码",
	PhoneNumeric: "*手机号码必须是数字",
	PhoneMin: "*手机号码必须至少{minLength}位数",
	PhoneMax: "*手机号码必须最多{maxLength}位数",
	PasswordRequired: "*请填写密码",
	PasswordMin: "*密码必须至少{minLength}位数",
	PasswordMax: "*密码必须最多{maxLength}位数",
	LoginSuccess: "登入成功",
	LoginError: "登入失败",
	GameError: "游戏正在维护中",
	VerifyTACError: "验证码验证失败",
	VerificationNumber: "验证号码",
	TACCode: "验证码",
	Resend: "重发",
	ConfirmTAC: "确认验证码",
	TACCodehasbeensenttoyourcontactnumber: "验证码已发送至您的联系电话。",
	RequestTACSuccess: "索取验证码成功",
	RequestTACFailed: "索取验证码失败",
};
