import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";

export interface MiniGamesStateInterface {
  miniGamesList: MiniGamesListResponse[];
}

function state(): MiniGamesStateInterface {
  return {
    miniGamesList: [],
  };
}

export default state;
