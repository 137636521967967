export default {
	Today: "Hari Ini",
	Tomorrow: "Hari Esok",
	Upcoming: "Akan Datang",
	EnjoySport: "Nikmati salah satu acara terbaik dengan peluang terbaik",
	SupportYourTeam: "Sokong pasukan kegemaran anda!",
	vs: "VS",
	notstarted: "Belum Dimulakan",
	h1: "Separuh masa pertama",
	hf: "Separuh masa",
	h2: "Separuh masa ke-2",
	finished: "Selesai",
	ViewAll: "Lihat Semua",
	WorldCupStartIn: "FIFA Piala Dunia Qatar 2022 bermula pada",
	days: "Hari",
	hours: "Jam",
	minutes: "Minit",
	seconds: "Saat",
	BetNow: "Bertaruh Sekarang",
	WorldCupIsHappenNow: "FIFA Piala Dunia Qatar 2022 sedang berlangsung sekarang",
	WorldCupIsEnd: "FIFA World Cup Qatar 2022 telah berakhir",
	PreviousMatches: "Persaingan Lepas",
};
