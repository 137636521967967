export default {
	SignUp: "Daftar sekarang",
	Login: "Log Masuk",
	ForgotYourPassword: "Lupa kata laluan?",
	NotAMemberYet: "Belum ada akaun?",
	PhoneNumber: "Telefon",
	Password: "Kata Laluan",
	LoginWin: "Log Masuk & Menang",
	Show: "Tunjuk",
	Hide: "Sembunyi",
	PhoneRequired: "*Nombor Telefon diperlukan",
	PhoneNumeric: "*Nombor Telefon mesti berangka",
	PhoneMin: "*Nombor Telefon mesti sekurangnya {minLength} bilangan",
	PhoneMax: "*Nombor Telefon mesti tidak melebihi {maxLength} bilangan",
	PasswordRequired: "*Kata laluan diperlukan",
	PasswordMin: "*Kata Laluan mesti sekurangnya {minLength} bilangan",
	PasswordMax: "*Kata Laluan mesti tidak melebihi {maxLength} bilangan",
	LoginSuccess: "Berjaya Log Masuk",
	LoginError: "Login Gagal",
	GameError: "Permainan Sedang Diselenggara",
	VerifyTACError: "TAC Pengesahan Gagal",
	VerificationNumber: "Nombor Pengesahan",
	TACCode: "Kod TAC",
	Resend: "Hantar Semula",
	ConfirmTAC: "Sahkan TAC",
	TACCodehasbeensenttoyourcontactnumber: "Kod TAC telah dihantar ke nombor hubungan anda.",
	RequestTACSuccess: "Permintaan TAC Berjaya",
	RequestTACFailed: "Permintaan TAC Gagal",
};
