import { WorldCupStateInterface } from "./state";
import { WorldCupByMatchesResponse } from "@/types/WorldCup/Response/WorldCupByMatchesResponse";
import { WorldCupMatchesResponse } from "@/types/WorldCup/Response/WorldCupMatchesResponse";
import { WorldCupStandingResponse } from "@/types/WorldCup/Response/WorldCupStandingResponse";
import { WorldCupTeamsResponse } from "@/types/WorldCup/Response/WorldCupTeamsResponse";
import { MutationTree } from "vuex";

export enum WorldCupMutations {
	SET_WORLDCUP_BYMATCHES = "SET_WORLDCUP_BYMATCHES",
	SET_WORLDCUP_MATCHES = "SET_WORLDCUP_MATCHES",
	SET_WORLDCUP_STANDING = "SET_WORLDCUP_STANDING",
	SET_WORLDCUP_TEAMS = "SET_WORLDCUP_TEAMS",
}

export const mutations: MutationTree<WorldCupStateInterface> = {
	[WorldCupMutations.SET_WORLDCUP_BYMATCHES](state, payload: WorldCupByMatchesResponse[]) {
		state.worldCupByMatches = payload;
	},
	[WorldCupMutations.SET_WORLDCUP_MATCHES](state, payload: WorldCupMatchesResponse[]) {
		state.worldCupMatches = payload;
	},
	[WorldCupMutations.SET_WORLDCUP_STANDING](state, payload: WorldCupStandingResponse[]) {
		state.worldCupStanding = payload;
	},
	[WorldCupMutations.SET_WORLDCUP_TEAMS](state, payload: WorldCupTeamsResponse[]) {
		state.worldCupTeam = payload;
	},
};
