export default {
	Group: "Group {group}",
	Teams: "Pasukan",
	P: "P",
	W: "W",
	D: "D",
	L: "L",
	GF: "GF",
	GA: "GA",
	"+-": "+/-",
	PTS: "PTS",
	HideMatches: "Sembunyikan Padanan",
	ShowMatches: "Tunjukkan Padanan",
	TodayMatches: "Persaingan Hari Ini",
	TomorrowMatches: "Persaingan Hari Esok",
	UpcomingMatches: "Persaingan Akan Datang",
	PreviousMatches: "Persaingan Lepas",
	notstarted: "Belum Dimulakan",
	h1: "Separuh masa pertama",
	hf: "Separuh masa",
	h2: "Separuh masa ke-2",
	finished: "Selesai",
	Date: "Tarikh",
	Time: "Masa",
	HomeTeam: "Pasukan Rumah",
	HomeScore: "Mata Pasukan Rumah",
	AwayTeam: "Pasukan Lawan",
	AwayScore: "Mata Pasukan Lawan",
	Status: "Status",
};
