import httpClient from "./HttpClient";
import { ContactResponse } from "@/types/Brand/Response/ContactResponse";

const getContact = (): Promise<ContactResponse[]> => {
  const endPoint = `/api/Brand/Contacts`;
  const params = {
    type: "",
  };
  return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getContact };
