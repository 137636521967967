export default {
	SignUp: "注册",
	Login: "登入",
	Hi: "嗨",
	Deposit: "存款",
	LoginID: "账号",
	LogoutSuccess: "登出成功",
	Player: "玩家",
	GameMaintenance: "游戏正在维护中。 请稍后再试。",
};
