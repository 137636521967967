export default {
	Username: "Game ID",
	Password: "Game Password",
	Copy: "Copy",
	CopySuccess: "Copy Success",
	CopyError: "Empty Copy",
	Update: "Update",
	Save: "Save",
	newPasswordRequired: "*New Password is Required",
	ChangePasswordSuccess: "Change Password Successful",
	ChangePasswordError: "Change Password Failed",
};
