import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_footer = _resolveComponent("the-footer")!
  const _component_FloatingSwitchSite = _resolveComponent("FloatingSwitchSite")!
  const _component_FloatingMiniGames = _resolveComponent("FloatingMiniGames")!
  const _component_MobileFooter = _resolveComponent("MobileFooter")!
  const _component_the_signalr = _resolveComponent("the-signalr")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_the_header),
    _createVNode(_component_router_view),
    _createVNode(_component_the_footer),
    _createVNode(_component_FloatingSwitchSite),
    _createVNode(_component_FloatingMiniGames),
    _createVNode(_component_MobileFooter),
    (_ctx.isLogin)
      ? (_openBlock(), _createBlock(_component_the_signalr, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}