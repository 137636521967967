export default {
	ForgotPassword: "Lupa Kata Laluan",
	NewPassword: "Kata Laluan Baharu",
	ConfirmPassword: "Sahkan Kata Laluan Baharu",
	Submit: "Tukar Kata Laluan",
	newPasswordRequired: "*Kata Laluan Baharu Diperlukan",
	confirmPasswordRequired: "*Sahkan Kata Laluan Baharu Adalah Diperlukan",
	NotSamePassword: "*Kata Laluan Tidak Sama",
	PasswordMin: "*Kata Laluan mesti sekurangnya {minLength} bilangan",
	PasswordMax: "*Kata Laluan mesti tidak melebihi {maxLength} bilangan",
	invalidToken: "Token tidak sah",
	ResetSuccess: "Berjaya Semula kata laluan",
	ResetError: "Semula kata laluan gagal",
};
