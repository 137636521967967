import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { IdentityServerStateInterface } from "./state";

export const getters: GetterTree<IdentityServerStateInterface, RootState> = {
	getLoginStatus(state) {
		return state.isLogin;
	},
	didAutoLogout(state) {
		return state.didAutoLogout;
	},
	getLoginId(state) {
		return state.loginId;
	},
	getMemberId(state) {
		return state.memberId;
	},
	getOnRefresh(state) {
		return state.onRefresh;
	},
	getOnSubmitLogin(state) {
		return state.onSubmitLogin;
	},
	getInterchangeStatus(state) {
		return state.isInterchange;
	},
};
