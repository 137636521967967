export default {
	SignUp: "Sign Up",
	Login: "Login",
	Hi: "Hi",
	Deposit: "Deposit",
	LoginID: "Login ID",
	LogoutSuccess: "Logout Success",
	Player: "Player",
	GameMaintenance: "Game is under maintenance. Please try again later.",
};
