import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { AnnouncementStateInterface } from "./state";
import { AnnouncementResponse } from "@/types/Announcement/Response/AnnouncementResponse";
import { getCultureID } from "@/currentCulture";

export const getters: GetterTree<AnnouncementStateInterface, RootState> = {
	getRunningText(state) {
		return (languageCode = ""): string[] => {
			const currentLocaleID = getCultureID(languageCode);
			const runningTextList = state.runningText.sort(function (a, b) {
				return a.sequence - b.sequence;
			});
			const filteredRunningTextArray: string[] = runningTextList.map((runningTextItem) => {
				return runningTextItem.announcementTexts
					.filter((x) => x.languageId == currentLocaleID)
					.map((runningText) => {
						return runningText.text;
					})[0];
			});
			return filteredRunningTextArray;
		};
	},
	getAnnouncement(state): AnnouncementResponse[] {
		return state.announcement;
	},
};
