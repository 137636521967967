import { BankStateInterface } from "./state";
import { DepositBankResponse } from "@/types/Bank/Response/DepositBankResponse";
import { WithdrawalBankResponse } from "@/types/Bank/Response/WithdrawalBankResponse";
import { MutationTree } from "vuex";

export enum BankMutations {
  SET_DEPOSIT_BANK = "SET_DEPOSIT_BANK",
  SET_WITHDRAWAL_BANK = "SET_WITHDRAWAL_BANK",
}

export const mutations: MutationTree<BankStateInterface> = {
  [BankMutations.SET_DEPOSIT_BANK](state, payload: DepositBankResponse[]) {
    state.depositBankList = payload;
  },
  [BankMutations.SET_WITHDRAWAL_BANK](state, payload: WithdrawalBankResponse[]) {
    state.withdrawalBankList = payload;
  },
};
