import { ActionTree } from "vuex";
import { getIpAddress} from "@/network/IpAddressService/IpAddress";
import { IpAddressStateInterface } from "./state";
import { RootState } from "@/store/state";
import { IPAddressMutations } from "./mutations";

export const actions: ActionTree<IpAddressStateInterface, RootState> = {
  async fetchIPAddress(context) {
    const res = await getIpAddress();
    context.commit(IPAddressMutations.SET_IP_ADDRESS, res);
    return res;
  }
};
