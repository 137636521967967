import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { RebateStateInterface } from "./state";
import { TierSettingGroupByTierResponse } from "@/types/Rebate/Response/TierSettingGroupByTierResponse";

export const getters: GetterTree<RebateStateInterface, RootState> = {
  getTierSettingGroupByTier(state): TierSettingGroupByTierResponse {
    return state.TierSettingGroupByTier;
  },
};
