import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { PaymentStateInterface } from "./state";
import { StatementResponse } from "@/types/Payment/Response/StatementResponse";
import { BonusReferralResponse } from "@/types/Payment/Response/BonusReferralResponse";

const timezone = new Date().getTimezoneOffset();

export const getters: GetterTree<PaymentStateInterface, RootState> = {
	getMemberDepositStatement(state): StatementResponse[] {
		state.depositStatement.map((depositItem) => {
			const createDate = new Date(new Date(depositItem.createdDate).getTime() - timezone * 60 * 1000);
			depositItem.createdDate = createDate;
			return depositItem;
		});
		state.depositStatement.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.depositStatement;
	},

	getMemberWithdrawalStatement(state): StatementResponse[] {
		state.withdrawalStatement.map((withdrawalItem) => {
			const createDate = new Date(new Date(withdrawalItem.createdDate).getTime() - timezone * 60 * 1000);
			withdrawalItem.createdDate = createDate;
			return withdrawalItem;
		});
		state.withdrawalStatement.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.withdrawalStatement;
	},

	getMemberTransferStatement(state, _, rootState, rootGetters): StatementResponse[] {
		const name = rootGetters["vendors/getVendor"].name;
		state.transferStatement.map((transferItem) => {
			const createDate = new Date(new Date(transferItem.createdDate).getTime() - timezone * 60 * 1000);
			transferItem.createdDate = createDate;
			return transferItem;
		});
		state.transferStatement.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.transferStatement.filter((x) => x.vendorFrom == name || x.vendorTo == name);
	},

	getMemberAdjustment(state): StatementResponse[] {
		state.adjustment.map((adjustmentItem) => {
			const createDate = new Date(new Date(adjustmentItem.createdDate).getTime() - timezone * 60 * 1000);
			adjustmentItem.createdDate = createDate;
			return adjustmentItem;
		});
		state.adjustment.sort((a, b) => {
			return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
		});
		return state.adjustment;
	},

	getMemberBonusReferral(state): BonusReferralResponse[] {
		return state.bonusReferral;
	},
};
