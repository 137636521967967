export default {
	FastProcessingTime: "Fast Processing Time",
	FastProcessingTimeText: "Deposit > 5 Minutes {newline} Withdrawal > 15 Minutes",
	OptimizedForMobile: "Optimized for mobile",
	OptimizedForMobileText: "Our games are accessible on all popular mobile platforms",
	SafeAndSecure: "Safe & Secure",
	SafeAndSecureText: "Enjoy games with a peace of mind",
	LivechatSupport: "24/7 Live Chat Support",
	LivechatSupportText: "We are dedicated to serve, {newline} 24 hours a day, 7 days a week.",
};
