import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_MemberAPI_URI,
  headers: {
    Accept: "application/json",
    "X-Forwarded-Host": location.host,
    "Content-Type": "application/json",
    // anything you want to add to the headers
  },
});

export default httpClient;