import httpClient, {ClientId} from "./HttpClient";
import { DepositBankResponse } from "@/types/Bank/Response/DepositBankResponse";
import { WithdrawalBankResponse } from "@/types/Bank/Response/WithdrawalBankResponse";

const getDepositBank = (): Promise<DepositBankResponse[]> => {
  const endPoint = `/api/Bank/DepositBank`;
  return httpClient.get(endPoint).then((response) => response.data);
};

const getWithdrawalBank = (): Promise<WithdrawalBankResponse[]> => {
  const endPoint = `/api/Bank/getWithdrawalBank/${ClientId}`;
  return httpClient.get(endPoint).then((response) => response.data);
};

export { getDepositBank, getWithdrawalBank };
