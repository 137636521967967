import { MemberBankInfoResponse } from "@/types/Member/Response/MemberBankInfoResponse";
import { MemberResponse } from "@/types/Member/Response/MemberResponse";
import { AspUserClientResponse } from "@/types/Member/Response/AspUserClientResponse";
import { MemberReferralResponse } from "@/types/Member/Response/MemberReferralResponse";
import { MemberGroupResponse } from "@/types/Member/Response/MemberGroupResponse";
import { MemberCurrentTierInfoResponse } from "@/types/Member/Response/MemberCurrentTierInfoResponse";

export interface MemberStateInterface {
  memberBank: MemberBankInfoResponse;
  member: MemberResponse;
  memberCount: number;
  aspUserClient: AspUserClientResponse;
  onLoadMemberBank: boolean;
  isMemberBankExist: boolean;
  memberReferral: MemberReferralResponse;
  memberGroup: MemberGroupResponse;
  memberCurrentTierInfo: MemberCurrentTierInfoResponse;
}

function state(): MemberStateInterface {
  return {
    memberBank: {} as MemberBankInfoResponse,
    member: {} as MemberResponse,
    memberCount: 0,
    aspUserClient: {} as AspUserClientResponse,
    onLoadMemberBank: false,
    isMemberBankExist: false,
    memberReferral: {} as MemberReferralResponse,
    memberGroup: {} as MemberGroupResponse,
    memberCurrentTierInfo: {} as MemberCurrentTierInfoResponse,
  };
}

export default state;
