import { Module } from "vuex";
import { RootState } from "../state";
import state, { PaymentChannelStateInterface } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutation";
import { actions } from "./action";

export const paymentchannel: Module<PaymentChannelStateInterface, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
