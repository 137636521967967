export default {
	ForgotPassword: "Forgot Password",
	NewPassword: "New Password",
	ConfirmPassword: "Confirm Password",
	Submit: "Update Password",
	newPasswordRequired: "*New Password Required",
	confirmPasswordRequired: "*Confirm Password Required",
	NotSamePassword: "*Not Same Password",
	PasswordMin: "*Password at least {minLength} character",
	PasswordMax: "*Password not more than {maxLength} character",
	invalidToken: "Invalid Token",
	ResetSuccess: "Reset Password Success",
	ResetError: "Reset Password Error",
};
