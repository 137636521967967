export default {
	Withdraw: "Withdrawal",
	withdrawalAmount: "*Withdrawal Amount",
	accountBank: "Bank",
	accountName: "Account Name",
	accountNumber: "Account Number",
	AmountRequired: "*Withdrawal Amount Required",
	Cancel: "Cancel",
	Min: "Min: {withdrawalMin}",
	Max: "Max: {withdrawalMax}",
	Currentbalance: "Current balance:",
	LastLogin: "Last Login",
	AmountMin: "*Amount Number at least {minValue} ",
	AmountMax: "*Amount Number not more than {maxValue} ",
	WithdrawalSubmitted: "Withdrawal Submitted Successfully",
	WithdrawalError: "Withdrawal Error",
	WinoverExist: "Winover Exist",
	ReachDailyLimit: "You've reached your daily withdrawal limit. Please contact our customer service.",
	InsufficientBalance: "Your balance is insufficient",
	AllowOneActiveWithdrawal: "Your previous withdrawal request is still in processing. Please contact our customer service if you need any assistance.",
};
