export default {
	ForgotPassword: "忘记密码",
	NewPassword: "新密码",
	ConfirmPassword: "确认密码",
	Submit: "更新密码",
	newPasswordRequired: "*请填写新密码",
	confirmPasswordRequired: "*请填写确认新密码",
	NotSamePassword: "*密码不一样",
	PasswordMin: "*密码必须至少{minLength}位数",
	PasswordMax: "*密码必须最多{maxLength}位数",
	invalidToken: "令牌无效",
	ResetSuccess: "重置密码成功",
	ResetError: "重置密码失败",
};
