export default {
	SignUp: "Sign Up",
	PhoneNumber: "Phone Number",
	Password: "Password",
	Show: "Show",
	Hide: "Hide",
	PhoneRequired: "*Phone Number is required",
	PhoneNumeric: "*Phone Number must be numeric",
	PhoneMin: "*Phone Number at least {minLength} digit",
	PhoneMax: "*Phone Number not more than {maxLength} digit",
	PasswordRequired: "*Password is required",
	PasswordMin: "*Password at least {minLength} character",
	PasswordMax: "*Password not more than {maxLength} character",
	ConfirmPassword: "Confirm Password",
	ConfirmPasswordRequired: "*Confirm Password is required",
	Desc1: "I’m over 18 years of age and I accept these ",
	Desc2: " and ",
	VerificationNumber: "Verification Number",
	TACCode: "TAC Code",
	Resend: "Resend",
	ConfirmTAC: "Confirm TAC",
	TACCodehasbeensenttoyourcontactnumber: "TAC Code has been sent to your contact number",
	TermsandConditions: "Terms and Conditions",
	PrivacyPolicy: "Privacy Policy",
	NotSamePassword: "*Confirm Password is not the same as your password",
	AcknowledgeUncheck: "*Please accept our Terms of Service before continuing",
	RequestTACSuccess: "Request TAC Success",
	RequestTACFailed: "Request TAC Failed",
	RegisterSuccess: "Register Success",
	RegisterError: "Register Failed",
	VerifyTACError: "TAC Verification Failed",
	Next: "Next",
	Account: "Your account has been",
	Success: "Successfully Created",
	ReferralCode: "Referral Code",
	InvalidReferralCode: "Invalid Referral Code",
};
