import { ActionTree } from "vuex";
import { getGlobalSetting } from "@/network/GlobalSettingService/GlobalSetting";
import { GlobalSettingStateInterface } from "./state";
import { RootState } from "@/store/state";
import { GlobalSettingMutations } from "./mutations";

export const actions: ActionTree<GlobalSettingStateInterface, RootState> = {
  async fetchGlobalSetting(context, payload) {
    if(context.state.globalSetting.length > 0){
			return;
		}
    const res = await getGlobalSetting(payload.remark);
    context.commit(GlobalSettingMutations.SET_GLOBAL_SETTING, res);
  },
};
