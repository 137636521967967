export default {
	Username: "Nama Pengguna Game",
	Password: "Kata Laluan Game",
	Copy: "Salinan",
	CopySuccess: "Salin Berjaya",
	CopyError: "Salinan Kosong",
	Update: "Kemas Kini",
	Save: "Simpan",
	newPasswordRequired: "*Kata Laluan yang baru diperlukan",
	ChangePasswordSuccess: "Berjaya Tukar Kata Laluan",
	ChangePasswordError: "Tukar Kata Laluan Gagal",
};
