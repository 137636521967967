export default {
  Transfer: "转账",
  StartDate: "开始日期*",
  EndDate: "结束日期*",
  Submit: "提交",
  TransactionID: "备注号",
  TransactionType: "方式",
  Amount: "共计",
  From: "从",
  To: "至",
  CreatedDate: "日期",
  Status: "状态",
  AutoDeposit: "自动存款",
  ManualDeposit: "手动存款",
};
