export default {
  Transfer: "Transfer",
  StartDate: "Start Date*",
  EndDate: "End Date*",
  Submit: "Submit",
  TransactionID: "Transaction ID",
  TransactionType: "Transaction Type",
  Amount: "Amount",
  From: "From",
  To: "To",
  CreatedDate: "Created Date",
  Status: "Status",
  AutoDeposit: "Auto Deposit",
  ManualDeposit: "Manual Deposit",
};
