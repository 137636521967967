export default {
  StartDate: "Start Date*",
  EndDate: "End Date*",
  Submit: "Submit",
  BetID: "Bet ID",
  ID: "ID",
  GameName: "Game Name",
  Provider: "Provider",
  BetAmount: "Bet Amount",
  WinLossAmount: "Win Loss Amount",
  GameDate: "Game Date",
};
