<template>
	<b-modal
		id="forgotPassword-modal"
		class="default-modal"
		size="md"
		hide-footer
		centered
		data-backdrop="static"
		:title="$t('ForgotPasswordModal.ForgotPassword')"
		v-model="ForgotPasswordModalShow"
	>
		<b-container class="px-5">
			<b-row>
				<b-col cols="12">
					<b-form name="forgotForm">
						<b-input-group class="mt-3" prepend="+60">
							<b-form-input
								v-model="forgotForm.phoneNo"
								:placeholder="$t('ForgotPasswordModal.PhoneNumber')"
								type="text"
								required
								inputmode="numeric"
								:disabled="currentProgress == 2"
							>
							</b-form-input>
						</b-input-group>
						<div class="input-errors" v-for="(error, index) of v$.forgotForm.phoneNo.$errors" :key="index">
							<div class="error-msg">{{ error.$message }}</div>
						</div>
						<template v-if="currentProgress == 2">
							<b-form-group
								:label="$t('ForgotPasswordModal.verificationCode')"
								label-for="tacInput"
								class="mt-3"
								v-if="currentProgress == 2"
							>
								<b-form-input
									v-if="currentProgress == 2"
									v-model="tac.tacCode"
									type="text"
									required
									inputmode="numeric"
									:minLength="TAC_LENGTH"
									:maxLength="TAC_LENGTH"
									class="tacInput"
									ref="tacInput"
									id="tacInput"
								>
								</b-form-input>
							</b-form-group>
							<div class="input-errors" v-for="(error, index) of v$.tac.tacCode.$errors" :key="index">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</template>
					</b-form>
				</b-col>
			</b-row>
			<b-row class="mx-0 my-3 pt-4">
				<b-button variant="main" class="mt-3" block @click="onRequestTAC" v-if="currentProgress == 1">
					{{ $t("ForgotPasswordModal.Submit") }}
				</b-button>
				<b-button
					variant="link"
					type="button"
					class="backButton"
					@click="currentProgress = 1"
					v-if="currentProgress == 2"
				>
					{{ $t("ForgotPasswordModal.back") }}
				</b-button>

				<b-button
					variant="main-gradient"
					class="mt-1"
					block
					@click.once="requestTAC"
					v-if="currentProgress == 2"
					:disabled="timeRemaining"
					:key="timeRemaining"
				>
					{{
						timeRemaining
							? $t("ForgotPasswordModal.resendInSeconds", { time: timeRemaining })
							: $t("ForgotPasswordModal.resendTac")
					}}
				</b-button>
				<b-button variant="main" class="mt-3" block @click="onSubmitForm" v-if="currentProgress == 2">
					{{ $t("ForgotPasswordModal.Submit") }}
				</b-button>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, numeric, maxLength } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { nextTick } from "vue";
export default {
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			forgotForm: {
				phoneNo: {
					required: helpers.withMessage(() => this.$t("ForgotPasswordModal.PhoneRequired"), required),
					numeric: helpers.withMessage(() => this.$t("ForgotPasswordModal.PhoneNumeric"), numeric),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("ForgotPasswordModal.PhoneMin", { minLength: $params.min }),
						minLength(9)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("ForgotPasswordModal.PhoneMax", { maxLength: $params.max }),
						maxLength(10)
					),
				},
			},
			tac: {
				tacCode: {
					required: helpers.withMessage(() => this.$t("ForgotPasswordModal.TACRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("ForgotPasswordModal.minLength", { minLength: $params.min }),
						minLength(this.TAC_LENGTH)
					),
				},
			},
		};
	},
	data() {
		return {
			forgotForm: {
				phoneNo: "",
			},
			tac: {
				tacCode: "",
			},
			phoneMin: 9,
			phoneMax: 10,
			ForgotPasswordModalShow: false,
			currentProgress: 1,
			TAC_LENGTH: 6,
			timeRemaining: 0,
			TAC_COOLDOWN: 60,
		};
	},
	computed: {},
	components: {},
	methods: {
		async forgotPassword() {
			const toast = useToast();
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			return await this.$store
				.dispatch("member/forgotPassword", {
					phoneNo: this.forgotForm.phoneNo,
					byPassSms: true,
					tokenOTP: this.tac.tacCode,
				})
				.then((res) => {
					return res;
				})
				.catch((err) => {
					toast.error(this.$t("ForgotPasswordModal.tacVerificationFailed"), { position: toastPosition });
					return false;
				});
		},
		async onRequestTAC() {
			const validity = await this.v$.forgotForm.$validate();
			if (!validity) return;
			let loader = this.$loading.show({
				container: this.fullPage ? null : this.$refs.formContainer,
			});
			this.tac.tacCode = "";
			await this.requestTAC();
			this.currentProgress = 2;
			loader.hide();
			this.focusTACInput();
		},
		async requestTAC() {
			const toast = useToast();
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			return await this.$store
				.dispatch("register/requestTAC", {
					phoneNo: this.forgotForm.phoneNo,
					password: "",
				})
				.then(() => {
					toast.success(this.$t("ForgotPasswordModal.tacSuccess"), { position: toastPosition });
					this.startCountdown();
					this.focusTACInput();
				})
				.catch(() => {
					toast.error(this.$t("ForgotPasswordModal.tacFailed"), { position: toastPosition });
					this.startCountdown(5);
				});
		},
		async onSubmitForm() {
			const validity = await this.v$.tac.$validate();
			if (!validity) return;

			let loader = this.$loading.show({
				container: this.fullPage ? null : this.$refs.formContainer,
			});
			const result = await this.forgotPassword();
			if (result?.url) {
				this.hideModal();
				window.open(result?.url, "_self");
			}
			loader.hide();
			return;
		},
		startCountdown(cooldown) {
			this.timeRemaining = cooldown || this.TAC_COOLDOWN;
			const timer = setInterval(() => {
				this.timeRemaining -= 1;
				if (this.timeRemaining <= 0) clearInterval(timer);
			}, 1000);
		},
		clearForm() {
			this.tac.tacCode = "";
			this.forgotForm.phoneNo = "";
			this.v$.$reset();
			this.currentProgress = 1;
		},
		hideModal() {
			this.clearForm();
			this.ForgotPasswordModalShow = false;
		},
		async focusTACInput() {
			await nextTick();
			setTimeout(() => {
				this.$refs.tacInput?.focus();
			}, 500);
		},
	},
};
</script>
<style scoped>
.tacInput {
	text-align: center;
	font-size: 2rem;
	letter-spacing: 1rem;
}
.backButton,
.backButton:hover {
	text-decoration: none;
	color: #000000;
}
</style>
