export default {
	PROFILE: "个人资料",
	AccountID: "账户号码",
	GameID: "游戏账号",
	DateOfBorth: "出生日期",
	FullName: "全名",
	Update: "更新",
	UpdateSuccess: "更新成功",
	doBirth: "出生日期",
	DOBHolderRequired: "*出生日期必填",
	birthdayIsCorrect: "请确保生日日期正确！您只能设置一次，不能更改！",
	Cancel: "取消",
	OK: "是，我确定！",
	AreYouSure: "你确定？",
};
