import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { LanguageStateInterface } from "./state";

export const getters: GetterTree<LanguageStateInterface, RootState> = {
  getLanguage(state) {
    return state.languageList;
  },
  getClientLanguage(state) {
    return state.clientLanguageList;
  }
};
