export default {
	Referral: "转介",
	MinDepositRequired: "您需要至少存款 {minDeposit} 次才能参加此推荐计划。",
	ReferralCode: "推荐代码",
	Copy: "复制",
	ReferralLink: "推荐链接",
	SuccessfulRegistrations: "成功注册",
	SuccessfulDeposited: "成功存款",
	MinDeposited: "最低 {minDepositAmount}",
	ShareToFriends: "分享给您的好友",
	ShareNow: "现在分享",
	Facebook: "Facebook",
	WhatsApp: "WhatsApp",
	Telegram: "Telegram",
	Email: "邮件",
	LINE: "LINE",
	Twitter: "Twitter",
	CopySuccess: "复制成功",
	CopyError: "复制失败",
	TermConditionReferral: "条款和条件适用于所有的推荐金。",
};
