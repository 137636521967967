import { ActionTree } from "vuex";
import registerService from "@/network/RegisterService/Register";
import { RegisterStateInterface } from "./state";
import { RootState } from "@/store/state";

export const actions: ActionTree<RegisterStateInterface, RootState> = {
	async registerAccount(context, payload) {
		const token = await context.dispatch("identityServer/fetchRegisterToken", null, { root: true });
		await registerService.registerAccount(
			payload.phoneNo,
			payload.password,
			payload.countryCode,
			payload.referralCode,
			token
		);
		context.dispatch("requestTAC", payload);
	},
	async requestTAC(context, payload) {
		const token = await context.dispatch("identityServer/fetchRegisterToken", null, { root: true });
		await registerService.requestTAC(payload.phoneNo, payload.password, token);
	},
	async verifyTAC(context, payload) {
		const token = await context.dispatch("identityServer/fetchRegisterToken", null, { root: true });
		await registerService.verifyTAC(payload.phoneNo, payload.token, token);
	},
};
