<template>
	<b-container fluid class="homeReputation px-0" :style="{ 'background-image': 'url(' + homeReputationBg + ')' }">
		<b-container class="homeReputationContainer">
			<div class="reputationImgContainer">
				<base-image :imgSrc="homeReputation" mode="homeReputationImg" :isFullUrl="false" />
			</div>
			<div class="reputationContent">
				<div class="reputationTitle">
					{{ $t("HomeReputation.20YearsReputation") }}
				</div>
				<div class="reputationDesc">
					{{ $t("HomeReputation.reputationDesc") }}
				</div>
				<div class="reputationButton">
					<template v-if="!isLogin">
						<b-button variant="reputation" v-b-modal="'login-modal'">
							{{ $t("HomeReputation.PlayNow") }}
						</b-button>
					</template>
					<template v-if="isLogin">
						<b-button variant="reputation" v-if="isMaintenance" @click="maintenancepopup">
							{{ $t("HomeReputation.PlayNow") }}
						</b-button>
						<b-button variant="reputation" v-else @click="startPlay">
							{{ $t("HomeReputation.PlayNow") }}
						</b-button>
					</template>
				</div>
			</div>
		</b-container>

		<HomeSwitchSite></HomeSwitchSite>
		<div class="pt-5"></div>
		<b-container fluid class="homeGoalBackground-triangle"></b-container>
		<b-container fluid class="homeGoalBackground">
			<HomeGoal></HomeGoal>
		</b-container>
		<div class="football-imgContainer">
			<base-image :imgSrc="footballImg" mode="footballImg" :isFullUrl="false"></base-image>
		</div>
		<div class="soccerPlayer-imgContainer">
			<base-image :imgSrc="soccerPlayerImg" mode="soccerPlayerImg" :isFullUrl="false"></base-image>
		</div>
	</b-container>
</template>
<style scoped>
.homeReputation {
	background: #ffffff;
	position: relative;
	overflow-x: hidden;
	background-size: cover;
	background-repeat: no-repeat;
}
.homeReputationContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3rem 0px;
}
.reputationImgContainer {
	width: 60%;
}
.reputationContent {
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #000000;
}

.reputationContent .reputationTitle {
	font-size: 3rem;
	border-bottom: 1px solid currentColor;
	text-transform: uppercase;
	font-weight: bold;
	text-align: left;
	width: 100%;
}
.reputationContent .reputationDesc {
	margin-top: 1rem;
	line-height: 2em;
	text-align: justify;
}

.reputationContent .btn-reputation {
	width: 15rem;
	color: #e13b28;
	background: transparent;
	border-radius: 50px;
	border: 1px solid #e13b28;
	font-size: 1.2rem !important;
}

.reputationContent .btn-reputation:hover {
	color: #ffffff;
	background: #e13b28;
}

.reputationButton {
	text-align: left;
	width: 100%;
	margin-top: 1.5rem;
}

.homeGoalBackground {
	background: #d8e2e8;
}

.homeGoalBackground-triangle {
	height: 140px;
	clip-path: polygon(0 73%, 100% 0, 100% 100%, 0% 100%);
	background: #d8e2e8;
	margin-bottom: -1px;
}

.football-imgContainer {
	position: absolute;
	width: 10vw;
	left: -3vw;
	bottom: var(--float-football-top);
	animation: footballBounce 3s infinite;
}

.soccerPlayer-imgContainer {
	position: absolute;
	width: 20vw;
	right: -4vw;
	bottom: 0;
}

.betNowBtn {
	font-weight: bold;
	font-size: 1rem;
}
.betNowMobileContainer {
	display: none;
}

@media (max-width: 1200px) {
	.soccerPlayer-imgContainer {
		display: none;
	}

	.football-imgContainer {
		display: none;
	}
}

@keyframes footballBounce {
	0% {
		bottom: var(--float-football-top);
	}
	25% {
		bottom: var(--float-football-top2);
	}
	50% {
		bottom: var(--float-football-top);
	}
	75% {
		bottom: var(--float-football-top2);
	}
	100% {
		bottom: var(--float-football-top);
	}
}

@media (max-width: 767px) {
	.homeReputationContainer {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.reputationImgContainer {
		width: 80%;
		margin-top: 2rem;
	}
	.reputationContent {
		width: 80%;
	}

	.reputationContent .reputationTitle {
		font-size: 1.8rem;
		text-align: center;
	}
	.reputationContent .reputationDesc {
		font-size: 0.9rem;
		padding: 0 1rem;
	}

	.reputationButton {
		text-align: center;
		padding: 0 1rem;
	}

	.homeGoalBackground-triangle {
		display: none;
		height: 80px;
	}
	.betNowContainer {
		display: none;
	}
	.betNowMobileContainer {
		display: block;
	}
	.betNowMobileBtn {
		background: linear-gradient(180deg, #6a91d2 0%, #28356a 100%) !important;
		width: 100%;
		border-radius: 19.5px !important;
		font-size: 1rem !important;
	}
}
</style>
<style>
:root {
	--float-football-top: 18vw;
	--float-football-top2: 17vw;
}
</style>
<script>
import { GameType } from "@/common/GameType.ts";
import HomeGoal from "@/components/content/Home/HomeGoal.vue";
import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";

export default {
	data() {
		return {
			footballImg: "ibc/assets/images/WorldCup/football.png",
			soccerPlayerImg: "ibc/assets/images/WorldCup/soccerPlayer.png",
			homeReputation: "ibc/assets/images/Home/homeReputation.png",
			homeReputationBg: process.env.VUE_APP_Media_Domain + "ibc/assets/images/Home/homeReputation_bg.jpg",
		};
	},
	components: {
		HomeGoal,
		HomeSwitchSite,
	},
	methods: {
		async startPlay() {
			let loader = this.$loading.show();
			await this.$store
				.dispatch("games/fetchLaunchGameUrl", {
					GameType: GameType.SPORTS,
					IsMobile: this.$isMobile(),
					Lang: localStorage.getItem("locale"),
				})
				.then((url) => {
					loader.hide();
					window.open(url, "_blank");
				})
				.catch(() => {
					loader.hide();
					let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
					this.toast.error(this.$t("HomeHotSport.LaunchGameError"), { position: toastPosition });
				});
		},
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HeaderLogin.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		},
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		checkIsMemberBankExist() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
		isMaintenance() {
			return this.$store.getters["vendors/getVendor"].maintenance;
		},
	},
};
</script>
