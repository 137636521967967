export default {
	BankInfo: "MAKLUMAT BANK",
	BankName: "Name Bank",
	AccountHolder: "Pemegang Akaun",
	AccountNumber: "Nombor Akaun",
	RequestTAC: "Mohon TAC",
	Add: "Tambah",
	AccountHolderRequired: "*Pemegang Akaun diperlukan",
	AccountNumberRequired: "*Nombor Akaun diperlukan",
	TACCode: "Kod TAC",
	accountNameMin: "*Panjang maksimum Pemegang Akaun ialah {minLength}",
	accountNameMax: "*Panjang minimum Pemegang Akaun ialah {maxLength}",
	accountNumberMax: "*Panjang maksimum Nombor Akaun ialah {minAccNoLength}",
	accountNumberMin: "*Panjang minimum Nombor Akaun ialah {maxAccNoLength}",
	SubmitSuccess: "Berjaya Hantar",
	SubmitError: "Hantar Gagal",
	VerifyTACError: "TAC Pengesahan Gagal",
	RequestTACSuccess: "Permintaan TAC Berjaya",
	RequestTACFailed: "Permintaan TAC Gagal",
	tacLength: "*Panjang TAC ialah {tacLength}",
	TACRequired: "*KOD TAC diperlukan",
	DuplicateBank: "Akaun Nomber Tidak Sah",
};
