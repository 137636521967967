export default {
	FastProcessingTime: "快速处理时间",
	FastProcessingTimeText: "存款 > 5 分钟 {newline} 提款 > 15 分钟",
	OptimizedForMobile: "为移动设备优化",
	OptimizedForMobileText: "我们的游戏可在所有流行的手机平台上访问",
	SafeAndSecure: "安全可靠",
	SafeAndSecureText: "您可以安心享受游戏",
	LivechatSupport: "24/7 在线客服",
	LivechatSupportText: "我们的客服团队每周24/7致力为您服务",
};
