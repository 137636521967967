import { DownloadLinksResponse } from "@/types/DownloadLinks/Response/DownloadLinksResponse";

export interface DownloadLinksStateInterface {
  downloadLinks: DownloadLinksResponse[];
}

function state(): DownloadLinksStateInterface {
  return {
    downloadLinks: [],
  };
}

export default state;
