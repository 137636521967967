import { ClientTenantInfoResponse } from "@/types/Tenant/Response/ClientTenantInfoResponse";

export interface TenantStateInterface {
    ClientTenantInfo : ClientTenantInfoResponse[]
}

function state(): TenantStateInterface {
  return {
    ClientTenantInfo : []
  };
}

export default state;