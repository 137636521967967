export default {
	SignUp: "Sign Up now",
	Login: "Login",
	ForgotYourPassword: "Forgot Your Password?",
	NotAMemberYet: "Not a member yet?",
	PhoneNumber: "Phone Number",
	Password: "Password",
	LoginWin: "Login & Win",
	Show: "Show",
	Hide: "Hide",
	PhoneRequired: "*Phone Number is required",
	PhoneNumeric: "*Phone Number must be numeric",
	PhoneMin: "*Phone Number at least {minLength} digit",
	PhoneMax: "*Phone Number not more than {maxLength} digit",
	PasswordRequired: "*Password is required",
	PasswordMin: "*Password at least {minLength} character",
	PasswordMax: "*Password not more than {maxLength} character",
	LoginSuccess: "Login Success",
	LoginError: "Login Failed",
	GameError: "Game is Under Maintenance",
	VerifyTACError: "TAC Verification Failed",
	VerificationNumber: "Verification Number",
	TACCode: "TAC Code",
	Resend: "Resend",
	ConfirmTAC: "Confirm TAC",
	TACCodehasbeensenttoyourcontactnumber: "TAC Code has been sent to your contact number",
	RequestTACSuccess: "Request TAC Success",
	RequestTACFailed: "Request TAC Failed",
};
