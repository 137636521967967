import { VendorResponse } from "@/types/Vendor/Response/VendorResponse";
import { VendorAccountResponse } from "@/types/Vendor/Response/VendorAccountResponse";
import { CategoryResponse } from "@/types/Vendor/Response/CategoryResponse";


export interface VendorStateInterface {
    vendor : VendorResponse,
    vendorAccount : VendorAccountResponse
    categoryList : CategoryResponse[]
}

function state(): VendorStateInterface {
  return {
      vendor : {} as VendorResponse,
      vendorAccount : {} as VendorAccountResponse,
      categoryList : [] as CategoryResponse[]
  };
}

export default state;