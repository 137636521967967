export default {
  ChangePassword: "更改密码",
  CurrentPassword: "当前密码",
  NewPassword: "新密码",
  ConfirmPassword: "确认密码",
  Submit: "更新密码",
  Cancel: "取消",
  ForgotYourPassword: "忘记密码",
  currentPasswordRequired: "*请填写当前密码",
  newPasswordRequired: "*请填写新密码",
  confirmPasswordRequired: "*请填写确认新密码",
  NotSamePassword: "*密码不一样",
  PasswordMin: "*密码必须至少{minLength}位数",
  PasswordMax: "*密码必须最多{maxLength}位数",
  ChangePasswordSuccess : "更改密码成功",
  ChangePasswordFailed : "更改密码失败"
};
