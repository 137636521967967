import httpClient, {ClientId} from "./HttpClient";
import { SurePayDepositRequest } from "@/types/PaymentChannel/Request/SurePayDepositRequest";
import { SurePayDepositResponse } from "@/types/PaymentChannel/Response/SurePayDepositResponse";

const addSurePayDepositTicket = (bankCode: string, amount: number, bonusId: number, loginId: string, paymentGatewayMethodId: number, ipAddress: string): Promise<SurePayDepositResponse> => {
	const endPoint = `/api/SurePay/CreditTransfer`;
	const request: SurePayDepositRequest = {
		loginId: loginId,
		bankCode: bankCode,
		ip: ipAddress,
		amount: amount,
		paymentGatewayMethodId: paymentGatewayMethodId,
		failurl: location.origin + "/statement",
		successurl: location.origin + "/statement",
		isMW: false,
		bonusId: bonusId
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

export default {
	addSurePayDepositTicket
};