export default {
  Statement: "Penyata",
  Deposit: "Deposit",
  Withdrawal: "Pengeluaran",
  BetHistory: "Sejarah Pertaruhan",
  Bonus: "Bonus",
  Adjustment: "Pelarasan",
  Referral: "Rujukan",
  Transfer: "Pemindahan",
};
