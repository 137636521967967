<template>
  <b-container class="loadGoalContent">
    <b-row>
      <b-col md="3" cols="6" class="textGoal p-4">
        <b-col class="mx-auto my-4 roundedGoal">
          <base-image :imgSrc="experienceImg" mode="goalImg" :isFullUrl="false"></base-image>
        </b-col>
        <div class="textContain">
          <h4>{{ $t("HomeGoal.FastProcessingTime") }}</h4>
          <i18n-t tag="div" keypath="HomeGoal.FastProcessingTimeText" class="textDesc">
            <template #newline><br /></template>
          </i18n-t>
        </div>
      </b-col>
      <b-col md="3" cols="6" class="textGoal p-4">
        <b-col class="mx-auto my-4 roundedGoal">
          <base-image :imgSrc="mobileImg" mode="goalImg" :isFullUrl="false"></base-image>
        </b-col>
        <div class="textContain">
          <h4>{{ $t("HomeGoal.OptimizedForMobile") }}</h4>
          <i18n-t tag="div" keypath="HomeGoal.OptimizedForMobileText" class="textDesc">
            <template #newline><br /></template>
          </i18n-t>
        </div>
      </b-col>
      <b-col md="3" cols="6" class="textGoal p-4">
        <b-col class="mx-auto my-4 roundedGoal">
          <base-image :imgSrc="securityImg" mode="goalImg" :isFullUrl="false"></base-image>
        </b-col>
        <div class="textContain">
          <h4>{{ $t("HomeGoal.SafeAndSecure") }}</h4>
          <i18n-t tag="div" keypath="HomeGoal.SafeAndSecureText" class="textDesc">
            <template #newline><br /></template>
          </i18n-t>
        </div>
      </b-col>
      <b-col md="3" cols="6" class="textGoal p-4">
        <b-col class="mx-auto my-4 roundedGoal">
          <base-image :imgSrc="csImg" mode="goalImg" :isFullUrl="false"></base-image>
        </b-col>
        <div class="textContain">
          <h4>{{ $t("HomeGoal.LivechatSupport") }}</h4>
          <i18n-t tag="div" keypath="HomeGoal.LivechatSupportText" class="textDesc">
            <template #newline><br /></template>
          </i18n-t>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.textGoal {
  font-size: 1rem;
}
.loadGoalContent {
  width: 100%;
  border-radius: 25px;
  padding: 0px 0px 3rem 0px;
  font-family: "Lato";
}
.roundedGoal {
  background: #c1d7fb;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
}
.textContain {
  color: #28356a;
  width: 150px;
  margin: 0 auto;
}
.textContain > h4 {
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textDesc {
  text-align: center;
}

.textContain h4 {
  font-weight: bold;
}
@media (max-width: 767px) {
  .loadGoalContent {
    margin-top: 0px;
  }
  .roundedGoal {
    width: 80px;
    height: 80px;
    padding: 1.5rem;
  }
  .textContain {
    padding: 0px 0.5rem;
  }
  .textGoal {
    font-size: 0.85rem;
  }
}
</style>
<script>
export default {
  data() {
    return {
      homeGoalImg: "ibc/assets/images/WorldCup/championship.png",
      csImg: "ibc/assets/images/Home/cs.png",
      mobileImg: "ibc/assets/images/Home/mobile.png",
      securityImg: "ibc/assets/images/Home/security.png",
      experienceImg: "ibc/assets/images/Home/experience.png",
    };
  },
  methods: {},
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
  },
};
</script>
