export default {
  Deposit: "Deposit",
  StartDate: "Start Date*",
  EndDate: "End Date*",
  Submit: "Submit",
  TransactionID: "Transaction ID",
  TransactionType: "Transaction Type",
  Amount: "Amount",
  CreatedDate: "Created Date",
  Status: "Status",
  AutoDeposit: "Auto Deposit",
  ManualDeposit: "Manual Deposit",
};
