import { DepositBankResponse } from "@/types/Bank/Response/DepositBankResponse";
import { WithdrawalBankResponse } from "@/types/Bank/Response/WithdrawalBankResponse";

export interface BankStateInterface {
  depositBankList: DepositBankResponse[];
  withdrawalBankList: WithdrawalBankResponse[];
}

function state(): BankStateInterface {
  return {
    depositBankList: [],
    withdrawalBankList: [],
  };
}

export default state;
