import { BrandStateInterface } from "./state";
import { ContactResponse } from "@/types/Brand/Response/ContactResponse";
import { MutationTree } from "vuex";

export enum BrandMutations {
  SET_CONTACT_LIST = "SET_CONTACT_LIST",
}

export const mutations: MutationTree<BrandStateInterface> = {
  [BrandMutations.SET_CONTACT_LIST](state, payload: ContactResponse[]) {
    state.contact = payload;
  },
};
