import httpClient from "./HttpClient";
import { ActionAuditRequest } from "@/types/ActionAudit/Request/ActionAuditRequest";

const addActionAuditTrail = (module: string, action: string, content: string, memberId: string): Promise<boolean> => {
	const endPoint = "/api/ActionAudit";
	const request: ActionAuditRequest = {
		module: module,
		action: action,
		content: content,
		createdBy: memberId,
	};
	return httpClient.post(endPoint, JSON.stringify(request)).then(() => true);
};

export default { addActionAuditTrail };
