export default {
  Statement: "Statement",
  Deposit: "Deposit",
  Withdrawal: "Withdrawal",
  BetHistory: "Bet History",
  Bonus: "Bonus",
  Adjustment: "Adjustment",
  Referral: "Referral",
  Transfer: "Transfer",
};
