export default {
	Today: "Today",
	Tomorrow: "Tomorrow",
	Upcoming: "Upcoming",
	EnjoySport: "Enjoy one of the best sports book with the best odds",
	SupportYourTeam: "Support your favourite team!",
	vs: "VS",
	notstarted: "Not Started",
	h1: "1st Half",
	hf: "Half Time",
	h2: "2nd Half",
	finished: "Finished",
	ViewAll: "View All",
	WorldCupStartIn: "FIFA World Cup Qatar 2022 starts in",
	days: "Days",
	hours: "Hours",
	minutes: "Minutes",
	seconds: "Seconds",
	BetNow: "Bet Now",
	WorldCupIsHappenNow: "FIFA World Cup Qatar 2022 is happening now",
	WorldCupIsEnd: "FIFA World Cup Qatar 2022 is over",
	PreviousMatches: "Previous Matches",
};
