import { BonusStateInterface } from "./state";
import { BonusResponse } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";
import { MutationTree } from "vuex";

export enum BonusMutations {
  SET_BONUS_LIST = "SET_BONUS_LIST",
  SET_MEMBER_BONUS = "SET_MEMBER_BONUS",
  SET_UNIQUE_CODE = "SET_UNIQUE_CODE",
  SET_BONUS_CLAIMED = "SET_BONUS_CLAIMED",
}

export const mutations: MutationTree<BonusStateInterface> = {
  [BonusMutations.SET_BONUS_LIST](state, payload: BonusResponse[]) {
    state.bonusList = payload;
  },
  [BonusMutations.SET_MEMBER_BONUS](state, payload: BonusResponse[]) {
    state.memberBonus = payload;
  },
  [BonusMutations.SET_UNIQUE_CODE](state, payload: UniqueCodeResponse[]) {
    state.uniqueCode = payload;
  },
  [BonusMutations.SET_BONUS_CLAIMED](state, payload: BonusClaimedResponse[]) {
    state.bonusClaimed = payload;
  },
};
