import { ActionTree } from "vuex";
import paymentService from "@/network/PaymentService/Payment";
import { PaymentStateInterface } from "./state";
import { RootState } from "@/store/state";
import { PaymentMutations } from "./mutations";
import { TransferErrorResponse } from "@/types/Payment/Response/TransferErrorResponse";

export const actions: ActionTree<PaymentStateInterface, RootState> = {
	async fetchMemberStatement(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.getStatement(loginId, payload.type, payload.from, payload.to);
		if (payload.type == "Deposit") {
			context.commit(PaymentMutations.SET_MEMBER_STATEMENT_DEPOSIT, res);
		} else if (payload.type == "Withdrawal") {
			context.commit(PaymentMutations.SET_MEMBER_STATEMENT_WITHDRAWAL, res);
		}
	},
	async fetchMemberTransferStatement(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const toIncludeToday = new Date(new Date(payload.to).getTime() + 24 * 60 * 60 * 1000);
		const res = await paymentService.getTransferStatement(loginId, payload.from, toIncludeToday);
		context.commit(PaymentMutations.SET_MEMBER_STATEMENT_TRANSFER, res);
	},
	async addAutoDeposit(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const response =  paymentService.addDeposit(loginId, payload.amount, payload.bank, payload.bonusId, 0);
		return response;
	},
	async addManualDeposit(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		await paymentService.addManualDeposit(
			loginId,
			payload.amount,
			payload.bank,
			payload.channel,
			payload.receipt,
			payload.bonusId,
			""
		);
	},
	async addWithdrawal(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const memberBank = context.rootGetters["member/getMemberBank"];
		await paymentService.addWithdrawal(
			loginId,
			payload.amount,
			memberBank.bankCode,
			memberBank.accountNumber,
			memberBank.accountHolder
		);
	},
	async fetchMemberAdjustment(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const depositAdjustment = await paymentService.getStatement(loginId, "DepositAdj", payload.from, payload.to);
		const withdrawalAdjustment = await paymentService.getStatement(loginId, "WithdrawalAdj", payload.from, payload.to);
		const rebateAdjustment = await paymentService.getStatement(loginId, "RebateAdj", payload.from, payload.to);
		const adjustmentList = depositAdjustment.concat(withdrawalAdjustment, rebateAdjustment);
		context.commit(PaymentMutations.SET_MEMBER_ADJUSTMENT, adjustmentList);
	},
	async fetchBonusReferral(context) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const res = await paymentService.getBonusReferral(loginId);
		context.commit(PaymentMutations.SET_MEMBER_BONUS_REFERRAL, res);
	},
	async transferBalance(context, payload) {
		const loginId = context.rootGetters["identityServer/getLoginId"];
		const transferResponse: TransferErrorResponse = {} as TransferErrorResponse;
		const addTransferRes = await paymentService.addTransfer(loginId, payload.vendorFrom, payload.vendorTo);
		if (addTransferRes.result) {
			const completeTransferRes = await paymentService.completeTransfer(addTransferRes.transferResponseDto.TicketId);
			if (completeTransferRes.succeeded) {
				transferResponse.succeeded = true;
				transferResponse.errorMsg = "";
			} else {
				transferResponse.succeeded = false;
				transferResponse.errorMsg = completeTransferRes.errors[0];
			}
		} else {
			transferResponse.succeeded = false;
			transferResponse.errorMsg = addTransferRes.errorMsg;
		}

		return transferResponse;
	},
};
