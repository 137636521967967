import httpClient, { ClientId } from "./HttpClient";
import { WorldCupByMatchesResponse } from "@/types/WorldCup/Response/WorldCupByMatchesResponse";
import { WorldCupMatchesResponse } from "@/types/WorldCup/Response/WorldCupMatchesResponse";
import { WorldCupStandingResponse } from "@/types/WorldCup/Response/WorldCupStandingResponse";
import { WorldCupTeamsResponse } from "@/types/WorldCup/Response/WorldCupTeamsResponse";

const getMatchesByDay = (day: number): Promise<WorldCupByMatchesResponse[]> => {
	const endPoint = `/api/WorldCup/ByMatches/${day}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getMatches = (): Promise<WorldCupMatchesResponse[]> => {
	const endPoint = `/api/WorldCup/Matches`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getMatchesById = (id: number): Promise<WorldCupMatchesResponse[]> => {
	const endPoint = `/api/WorldCup/Matches/${id}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getStanding = (): Promise<WorldCupStandingResponse[]> => {
	const endPoint = `/api/WorldCup/Standings`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getStandingByGroup = (group: string): Promise<WorldCupStandingResponse[]> => {
	const endPoint = `/api/WorldCup/Standings/${group}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getTeams = (): Promise<WorldCupTeamsResponse[]> => {
	const endPoint = `/api/WorldCup/Teams`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getTeamsById = (id: number): Promise<WorldCupTeamsResponse[]> => {
	const endPoint = `/api/WorldCup/Teams/${id}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

export default {
	getMatchesByDay,
	getMatches,
	getMatchesById,
	getStanding,
	getStandingByGroup,
	getTeams,
	getTeamsById,
};
