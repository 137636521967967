import { ActionTree } from "vuex";
import rebateService from "@/network/RebateService/Rebate";
import { RebateStateInterface } from "./state";
import { RootState } from "@/store/state";
import { RebateMutations } from "./mutations";

export const actions: ActionTree<RebateStateInterface, RootState> = {
  async fetchTierSettingGroupByTier(context) {
    const res = await rebateService.getTierSettingGroupByTier();
    context.commit(RebateMutations.SET_TIER_SETTING_BROUP_BY_TIER, res);
  },
};
