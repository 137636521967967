export default {
  JoinUs: "Sertai Kami",
  Gamblingcanbeaddictive: "Perjudian boleh menyebabkan ketagihan",
  Playresponsibly: "Sila bermain secara bertanggungjawab.",
  Information: "Maklumat",
  About: "Tentang Kami",
  Terms: "Tempoh",
  Privacy: "Dasar Privasi",
  ResponsibleGaming: "Tanggungjawab Permainan",
  TalktoUs: "Hubungi Kami",
  Copyright: "Hak Cipta Terpelihara",
  AllRightsReserved: " ",
};
