import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_WebAPI_URI,
  headers: {
    Accept: "application/json",
    "X-Forwarded-Host": location.host,
    // anything you want to add to the headers
  },
});
export const ClientId = `${process.env.VUE_APP_IdentityServer_ClientId}`;
export default httpClient;