export default {
	BankInfo: "银行资料",
	BankName: "银行",
	AccountHolder: "账户持有人",
	AccountNumber: "账号",
	RequestTAC: "发送 TAC",
	Add: "添加",
	AccountHolderRequired: "*请填写账户持有人",
	AccountNumberRequired: "*请填写账号",
	TACCode: "验证码",
	accountNameMin: "*账户持有人的最小长度为 {minLength}",
	accountNameMax: "*账户持有人的最大长度为 {maxLength}",
	accountNumberMax: "*账号的最大长度为 {minAccNoLength}",
	accountNumberMin: "*账号的最小长度为 {maxAccNoLength}",
	SubmitSuccess: "提交成功",
	SubmitError: "提交失败",
	VerifyTACError: "验证码验证失败",
	RequestTACSuccess: "索取验证码成功",
	RequestTACFailed: "索取验证码失败",
	tacLength: "*验证码长度是 {tacLength}",
	TACRequired: "*请填写验证码",
	DuplicateBank: "银行户口无效",
};
