import store from "@/store/index";
interface languageItem {
	languageID: number;
	languageCode: string;
}

// const language: languageItem[] = [
// 	{
// 		languageID: 1,
// 		languageCode: "en",
// 	},
// 	{
// 		languageID: 2,
// 		languageCode: "zh",
// 	},
// 	{
// 		languageID: 3,
// 		languageCode: "ms",
// 	},
// ];

const language = () : languageItem[] => {
    // Dispatch the action and store the promise it returns
    const emptyArray = store.getters["language/getClientLanguage"];
    const newlang = [];

    emptyArray.forEach(item => {
        const templang = {
            languageID: item.id,
            languageCode: item.languageCountryCode.substring(0, 2),
        };

        newlang.push(templang);
    });

    return newlang;
};

const getDefaultCultureCode = () => {
	let currentLocale = localStorage.getItem("locale") || navigator.language.slice(0, 2);
	const result = language();
	
	if (result.length > 0 && !result.some((x) => x.languageCode === currentLocale)) {
		currentLocale = "en";
	}
	if(currentLocale == ""){
		currentLocale = "en";
	}
	
	localStorage.setItem("locale", currentLocale);
	//const currentLocaleID = language.filter((languageItem) => languageItem.languageCode == currentLocale)[0].languageID;
	return currentLocale;
};

const getCultureID = (cultureCode?: string): number => {
	if (cultureCode == "") {
		cultureCode = getDefaultCultureCode();
	}
	const result = language();
	if(result.length > 0){
		const currentLocaleID = result.filter((languageItem) => languageItem.languageCode == cultureCode)[0].languageID;
		return currentLocaleID;
	}
	return 1;
};
export { getDefaultCultureCode, languageItem, getCultureID };
