import { ActionTree } from "vuex";
import TenantService from "@/network/TenantService/Tenant";
import { TenantStateInterface } from "./state";
import { RootState } from "@/store/state";
import { TenantMutations } from "./mutations";

export const actions: ActionTree<TenantStateInterface, RootState> = {
	async fetchClientTenantInfo(context) {
		if (context.state.ClientTenantInfo.length > 0) {
			return;
		}
		const res = await TenantService.getTenantClients();
		context.commit(TenantMutations.SET_CLIENT_TENANT_INFO, res);
	},
};
