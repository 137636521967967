import httpClient, { ClientId } from "./HttpClient";
import { BonusResponse } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";

const getBonusList = (): Promise<BonusResponse[]> => {
	const endPoint = `/api/Bonus/${ClientId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getBonusByMemberGroup = (memberId: string): Promise<BonusResponse[]> => {
	const tenantId = process.env.VUE_APP_IdentityServer_TenantId;
	const endPoint = `/api/Bonus/${tenantId}/${ClientId}/MemberGroup/${memberId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getUniqueCode = (): Promise<UniqueCodeResponse[]> => {
	const endPoint = `/api/Bonus/UniqueCode/${ClientId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getUniqueCodeClaimedStatus = (loginId: string, uniqueCodeId: number): Promise<boolean> => {
	const endPoint = `/api/Bonus/UniqueCode/${ClientId}/loginId/${loginId}/${uniqueCodeId}`;
	return httpClient.get(endPoint).then((response) => response.data);
};

const getBonusClaimed = (memberId: string, from: string, to: string): Promise<BonusClaimedResponse[]> => {
	const endPoint = `/api/Bonus/Claims/${ClientId}/${memberId}`;
	const params = {
		clientId: ClientId,
		memberGUID: memberId,
		from: from,
		to: to,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
	getBonusList,
	getBonusByMemberGroup,
	getUniqueCode,
	getUniqueCodeClaimedStatus,
	getBonusClaimed,
};
