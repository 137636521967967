import { BannerStateInterface } from "./state";
import { BannerResponse } from "@/types/Banner/Response/BannerResponse";
import { MutationTree } from "vuex";

export enum BannerMutations {
  SET_BANNER = "SET_BANNER"
}

export const mutations: MutationTree<BannerStateInterface> = {
  [BannerMutations.SET_BANNER](
    state,
    payload: BannerResponse[]
  ) {
    state.banner = payload;
  },
};
