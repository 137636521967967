export default {
  Statement: "账单",
  Deposit: "存款",
  Withdrawal: "提款",
  BetHistory: "投注历史",
  Bonus: "优惠",
  Adjustment: "余额调整",
  Referral: "转介人",
  Transfer: "转账",
};
