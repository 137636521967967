import { WorldCupByMatchesResponse } from "@/types/WorldCup/Response/WorldCupByMatchesResponse";
import { WorldCupMatchesResponse } from "@/types/WorldCup/Response/WorldCupMatchesResponse";
import { WorldCupStandingResponse } from "@/types/WorldCup/Response/WorldCupStandingResponse";
import { WorldCupTeamsResponse } from "@/types/WorldCup/Response/WorldCupTeamsResponse";

export interface WorldCupStateInterface {
	worldCupByMatches: WorldCupByMatchesResponse[];
	worldCupMatches: WorldCupMatchesResponse[];
	worldCupStanding: WorldCupStandingResponse[];
	worldCupTeam: WorldCupTeamsResponse[];
}

function state(): WorldCupStateInterface {
	return {
		worldCupByMatches: [],
		worldCupMatches: [],
		worldCupStanding: [],
		worldCupTeam: [],
	};
}

export default state;
