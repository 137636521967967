import { ActionTree } from "vuex";
import worldCupService from "@/network/WorldCupService/WorldCup";
import { WorldCupStateInterface } from "./state";
import { RootState } from "@/store/state";
import { WorldCupMutations } from "./mutations";

export const actions: ActionTree<WorldCupStateInterface, RootState> = {
	async fetchMatchesByDay(context, payload) {
		const res = await worldCupService.getMatchesByDay(payload.day);
		context.commit(WorldCupMutations.SET_WORLDCUP_BYMATCHES, res);
	},
	async fetchMatches(context, payload) {
		const res = await worldCupService.getMatches();
		context.commit(WorldCupMutations.SET_WORLDCUP_MATCHES, res);
	},
	async fetchMatchesById(context, payload) {
		const res = await worldCupService.getMatchesById(payload.id);
		context.commit(WorldCupMutations.SET_WORLDCUP_MATCHES, res);
	},
	async fetchStanding(context, payload) {
		const res = await worldCupService.getStanding();
		context.commit(WorldCupMutations.SET_WORLDCUP_STANDING, res);
	},
	async fetchStandingByGroup(context, payload) {
		const res = await worldCupService.getStandingByGroup(payload.group);
		context.commit(WorldCupMutations.SET_WORLDCUP_STANDING, res);
	},
	async fetchTeams(context, payload) {
		const res = await worldCupService.getTeams();
		context.commit(WorldCupMutations.SET_WORLDCUP_TEAMS, res);
	},
	async fetchTeamsById(context, payload) {
		const res = await worldCupService.getTeamsById(payload.id);
		context.commit(WorldCupMutations.SET_WORLDCUP_TEAMS, res);
	},
};
