import { ActionTree } from "vuex";
import { getMiniGamesList, getMiniGamesUrl } from "@/network/MiniGamesService/MiniGames";
import { MiniGamesStateInterface } from "./state";
import { RootState } from "@/store/state";
import { MiniGamesMutations } from "./mutations";
import moment from "moment";
const timezone = new Date().getTimezoneOffset();

export const actions: ActionTree<MiniGamesStateInterface, RootState> = {
  async fetchMiniGames(context) {
    const dateNow = moment.utc(new Date()).format();
    if (context.state.miniGamesList.length > 0) {
      return;
    }
    let miniGameList = await getMiniGamesList();
    miniGameList = miniGameList.filter(
      (x) =>
        x.status == true && moment(dateNow).isAfter(moment(x.startDate)) && moment(dateNow).isBefore(moment(x.endDate))
    );
    context.commit(MiniGamesMutations.SET_MINIGAMES_LIST, miniGameList);
  },
  async fetchMiniGamesUrl(context, payload) {
    const res = await getMiniGamesUrl(payload.lang);
    return res;
  },
};
