export default {
  JoinUs: "关注我们",
  Gamblingcanbeaddictive: "赌博会让人上瘾",
  Playresponsibly: "玩游戏请自律。",
  Information: "资讯",
  About: "关于我们",
  Terms: "条款",
  Privacy: "隐私",
  ResponsibleGaming: "游戏责任",
  TalktoUs: "联系我们",
  Copyright: "保留所有版权",
  AllRightsReserved: " ",
};
