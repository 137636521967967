export default {
	SignUp: "Daftar",
	Login: "Log Masuk",
	Hi: "Hai",
	Deposit: "Deposit",
	LoginID: "Login ID",
	LogoutSuccess: "Berjaya Log Keluar",
	Player: "Pemain",
	GameMaintenance: "Permainan sedang diselenggara. Sila cuba sebentar lagi.",
};
