
export interface GamesStateInterface {
}

function state(): GamesStateInterface {
  return {
  };
}

export default state;
