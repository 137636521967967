<template>
  <b-modal
    id="deposit-modal"
    class="myaccount-modal"
    v-model="modalShow"
    size="lg"
    hide-footer
    centered
    cancel-disabled
    data-backdrop="static"
    :title="$t('DepositModal.TopUp')"
    @show="depositModalShow"
  >
    <b-container class="overlay" :class="{ 'd-none': !disabledForm }">
      <div class="d-flex justify-content-center align-items-center w-100 h-100">
        <div class="h1">{{ $t("DepositModal.WinoverExist") }}</div>
      </div>
    </b-container>
    <b-container>
      <b-row class="depositSwitchContainer">
        <b-col align-self="start">
          <b-input-group class="mt-3 text-dark">
            <b-form-group v-bind:label="$t('WithdrawalModal.Currentbalance')" class="mb-0">
              <h3 class="text-nowrap">MYR {{ getCurrentBalance }}</h3>
            </b-form-group>
          </b-input-group>
        </b-col>

        <b-col class="switchContainer">
          <b-input-group class="switchInputGroup text-dark">
            <div class="d-flex">
              <b-button
                variant="transparent"
                class="btn-deposit-tab"
                :class="{
                  active: currentDepositTab == 'Auto',
                  disabled: autoDepositDisabled,
                }"
                @click="switchDeposit('Auto')"
                :disabled="autoDepositDisabled"
                >{{ $t("DepositModal.AutoDeposit") }}
              </b-button>
              <span class="divider"> | </span>
              <b-button
                variant="transparent"
                class="btn-deposit-tab"
                :class="{
                  active: currentDepositTab == 'Manual',
                  disabled: manualDepositDisabled,
                }"
                @click="switchDeposit('Manual')"
                :disabled="manualDepositDisabled"
                >{{ $t("DepositModal.ManualDeposit") }}
              </b-button>
              <span class="divider"> | </span>
              <b-button
                variant="transparent"
                class="btn-deposit-tab"
                :class="{
                  active: currentDepositTab == 'QR',
                  disabled: qrDepositDisabled,
                }"
                @click="switchDeposit('QR')"
                :disabled="qrDepositDisabled"
                >{{ $t("DepositModal.QRPay") }}
              </b-button>
            </div>
          </b-input-group>
        </b-col>
      </b-row>

      <b-container v-if="this.currentDepositTab == 'Auto'">
        <b-col>
          <b-row>
            <b-form name="autoDepositForm" class="p-0">
              <b-col class="my-3">
                <b-input-group class="justify-content-start">
                  <b-form-group v-bind:label="$t('DepositModal.PaymentMethod')" class="mb-0 text-dark w-100">
                    <b-row cols="2" cols-lg="5" class="w-100">
                      <b-col cols v-for="channel in autoDepositBank" :key="channel.name">
                        <label class="bankLabel my-2">
                          <input
                            type="radio"
                            name="Channel"
                            :value="channel.name.toUpperCase()"
                            class="channelBank"
                            hidden
                            required
                            v-model="autoDepositForm.channel"
                            :disabled="disabledForm"
                            @change="setAutoBank(channel)"
                          />
                          <img
                            :src="channel.thumbnail"
                            class="channelBankImg rounded"
                            :alt="channel.name"
                            :title="channel.name"
                          />
                        </label>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-input-group>
              </b-col>
              <b-col class="my-3">
                <b-input-group class="justify-content-start">
                  <b-form-group v-bind:label="$t('DepositModal.Bank')" class="mb-0 text-dark w-100">
                    <b-row cols="2" cols-lg="5" class="w-100">
                      <b-col cols v-for="BankItem in this.AutoBankList" :key="BankItem.bankId">
                        <label class="bankLabel my-2">
                          <input
                            type="radio"
                            name="BankCode"
                            :value="BankItem"
                            class="depositBank"
                            hidden
                            required
                            v-model="autoDepositForm.bank"
                            :disabled="disabledForm"
                            @change="checkAutoMinMax(BankItem)"
                          />
                          <img
                            :src="BankItem.bankImgUrl"
                            class="depositBankImg rounded"
                            :alt="BankItem.bankName"
                            :title="BankItem.bankName"
                          />
                        </label>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-input-group>
                <div class="input-errors" v-for="(error, index) of v$.autoDepositForm.bank.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </b-col>
              <b-row cols="1" cols-lg="2" class="mb-4">
                <b-col>
                  <b-input-group class="mt-4">
                    <b-form-input
                      id="autodeposit-amount"
                      v-model="autoDepositForm.amount"
                      type="number"
                      v-bind:placeholder="$t('DepositModal.depositAmount')"
                      required
                      :disabled="disabledForm"
                    ></b-form-input>
                  </b-input-group>
                  <label class="text-start text-dark"
                    >{{
                      $t("DepositModal.Min", {
                        depositMin: this.autoDepositForm.depositMin,
                      })
                    }}
                    /
                    {{
                      $t("DepositModal.Max", {
                        depositMax: this.autoDepositForm.depositMax,
                      })
                    }}
                  </label>
                  <div class="input-errors" v-for="(error, index) of v$.autoDepositForm.amount.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </b-col>
                <b-col>
                  <div class="amountBox-container">
                    <b-button
                      variant="amountBox"
                      v-for="boxAmount in amountBox"
                      :key="boxAmount"
                      @click="setBoxAmount(boxAmount)"
                    >
                      {{ boxAmount }}
                    </b-button>
                  </div>
                </b-col>
                <b-col>
                  <b-input-group class="mt-4">
                    <b-form-select
                      id="deposit-channel"
                      v-model="autoDepositForm.bonusId"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      required="required"
                      :disabled="disabledForm"
                      @change="checkDepositBonus('Auto', autoDepositForm.bonusId)"
                    >
                      <option value="0">
                        {{ $t("DepositModal.PleaseSelectBonus") }}
                      </option>
                      <option value="0">
                        {{ $t("DepositModal.NoApplyAnyBonus") }}
                      </option>
                      <option v-for="bonusItem in memberAutoBonus" :value="bonusItem.bonusId" :key="bonusItem.bonusId">
                        {{ bonusItem.title }}
                      </option>
                    </b-form-select>
                  </b-input-group>
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.autoDepositForm.memberBonus.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </b-row>
          <b-row class="justify-content-center my-3" v-if="!disabledForm">
            <b-col cols="6">
              <b-button variant="main" inline @click="submitSurePayDepositForm(false)" class="w-100">{{
                $t("DepositModal.Deposit")
              }}</b-button>
              <b-button variant="none" data-bs-dismiss="modal" class="text-dark w-100">{{
                $t("DepositModal.Cancel")
              }}</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-container>

      <b-container v-else-if="this.currentDepositTab == 'Manual'">
        <b-col>
          <b-row>
            <b-form name="manualDepositForm" enctype="multipart/form-data" class="p-0">
              <b-col class="my-3">
                <b-input-group>
                  <b-form-group v-bind:label="$t('DepositModal.Bank')" class="mb-0 text-dark w-100">
                    <b-row cols="2" cols-lg="5" class="w-100">
                      <b-col v-for="BankItem in manualDepositBank" :key="BankItem.id">
                        <label class="bankLabel my-2">
                          <input
                            type="radio"
                            name="BankCode"
                            :value="BankItem.bankCode"
                            class="depositBank"
                            hidden
                            required
                            :disabled="disabledForm || getIsSelectRescueBonus"
                            v-model="manualDepositForm.bank"
                            @change="
                              setDepositAccountDetails(BankItem.id);
                              checkManualMinMax(BankItem);
                            "
                          />
                          <img
                            :src="BankItem.bankImgUrl"
                            class="depositBankImg rounded"
                            :alt="BankItem.bankName"
                            :title="BankItem.bankName"
                            :class="{ disabled: getIsSelectRescueBonus }"
                          />
                        </label>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-input-group>
                <div class="input-errors" v-for="(error, index) of v$.manualDepositForm.bank.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </b-col>
              <b-row cols="1" cols-lg="2">
                <b-col>
                  <b-input-group class="mt-3" align-self="start">
                    <b-form-input
                      id="manualdeposit-amount"
                      v-model="manualDepositForm.amount"
                      type="number"
                      v-bind:placeholder="$t('DepositModal.depositAmount')"
                      required
                      :disabled="disabledForm || getIsSelectRescueBonus"
                    ></b-form-input>
                  </b-input-group>
                  <div class="text-start text-dark">
                    {{
                      $t("DepositModal.Min", {
                        depositMin: this.manualDepositForm.depositMin,
                      })
                    }}
                    /
                    {{
                      $t("DepositModal.Max", {
                        depositMax: this.manualDepositForm.depositMax,
                      })
                    }}
                  </div>
                  <div class="input-errors" v-for="(error, index) of v$.manualDepositForm.amount.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </b-col>
                <b-col>
                  <div class="amountBox-container">
                    <b-button
                      variant="amountBox"
                      v-for="boxAmount in amountBox"
                      :key="boxAmount"
                      @click="setBoxAmount(boxAmount)"
                    >
                      {{ boxAmount }}
                    </b-button>
                  </div>
                </b-col>
                <b-col>
                  <b-input-group class="mt-3">
                    <b-form-input
                      id="deposit-accountname"
                      v-model="accountName"
                      type="text"
                      v-bind:placeholder="$t('DepositModal.accountName')"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group class="mt-3">
                    <b-form-input
                      id="deposit-accountnumber"
                      v-model="accountNumber"
                      type="text"
                      v-bind:placeholder="$t('DepositModal.accountNumber')"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group class="mt-3">
                    <b-form-select
                      id="deposit-channel"
                      v-model="manualDepositForm.channel"
                      :options="optionChannel"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      required="required"
                      :disabled="disabledForm || getIsSelectRescueBonus"
                    ></b-form-select>
                  </b-input-group>
                  <div class="input-errors" v-for="(error, index) of v$.manualDepositForm.channel.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </b-col>
                <b-col>
                  <b-input-group class="mt-3">
                    <b-form-select
                      id="deposit-bonus"
                      v-model="manualDepositForm.bonusId"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      required="required"
                      :disabled="disabledForm"
                      @change="checkDepositBonus('Manual', manualDepositForm.bonusId)"
                    >
                      <option value="0">
                        {{ $t("DepositModal.PleaseSelectBonus") }}
                      </option>
                      <option value="0">
                        {{ $t("DepositModal.NoApplyAnyBonus") }}
                      </option>
                      <option
                        v-for="bonusItem in memberManualBonus"
                        :value="bonusItem.bonusId"
                        :key="bonusItem.bonusId"
                      >
                        {{ bonusItem.title }}
                      </option>
                    </b-form-select>
                  </b-input-group>
                  <div
                    class="input-errors"
                    v-for="(error, index) of v$.manualDepositForm.memberBonus.$errors"
                    :key="index"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </b-col>
                <b-col>
                  <label class="my-3 w-100" for="uploadReceipt">
                    <b-input-group>
                      <b-form-input
                        readonly
                        class="bg-white"
                        v-model="manualDepositForm.receiptText"
                        :placeholder="$t('DepositModal.ChooseFile')"
                        :disabled="disabledForm || getIsSelectRescueBonus"
                      ></b-form-input>
                      <b-input-group-append>
                        <div
                          class="btn btn-main rounded-0"
                          :class="{
                            disabled: disabledForm || getIsSelectRescueBonus,
                          }"
                        >
                          {{ $t("DepositModal.Browse") }}
                        </div>
                      </b-input-group-append>
                      <input
                        type="file"
                        hidden
                        id="uploadReceipt"
                        @change="uploadReceipt"
                        :disabled="disabledForm || getIsSelectRescueBonus"
                      />
                    </b-input-group>
                    <div
                      class="input-errors"
                      v-for="(error, index) of v$.manualDepositForm.receipt.$errors"
                      :key="index"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </label>
                </b-col>
              </b-row>
              <b-row class="justify-content-center my-3" v-if="!disabledForm">
                <b-col cols="6">
                  <b-button variant="main" inline @click="submitManualDepositForm" class="w-100">{{
                    $t("DepositModal.Deposit")
                  }}</b-button>
                  <b-button variant="none" class="text-dark w-100" data-bs-dismiss="modal">{{
                    $t("DepositModal.Cancel")
                  }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-row>
        </b-col>
      </b-container>
      <b-container v-else-if="this.currentDepositTab == 'QR'">
        <b-col>
          <b-row>
            <b-form name="qrDepositForm" class="p-0">
              <b-col class="my-3">
                <b-input-group class="justify-content-start">
                  <b-form-group v-bind:label="$t('DepositModal.PaymentMethod')" class="mb-0 text-dark w-100">
                    <b-row cols="2" cols-lg="5" class="w-100">
                      <b-col cols v-for="channel in qrDepositBank" :key="channel.name">
                        <label class="bankLabel my-2">
                          <input
                            type="radio"
                            name="Channel"
                            :value="channel.name.toUpperCase()"
                            class="channelBank"
                            hidden
                            required
                            v-model="qrDepositForm.channel"
                            :disabled="disabledForm"
                            @change="setQRBank(channel)"
                          />
                          <img
                            :src="channel.thumbnail"
                            class="channelBankImg rounded"
                            :alt="channel.name"
                            :title="channel.name"
                          />
                        </label>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-input-group>
              </b-col>
              <b-col class="my-3">
                <b-input-group class="justify-content-start">
                  <b-form-group v-bind:label="$t('DepositModal.Bank')" class="mb-0 text-dark w-100">
                    <b-row cols="2" cols-lg="5" class="w-100">
                      <b-col cols v-for="BankItem in this.QRBankList" :key="BankItem.bankId">
                        <label class="bankLabel my-2">
                          <input
                            type="radio"
                            name="BankCode"
                            :value="BankItem"
                            class="depositBank"
                            hidden
                            required
                            v-model="qrDepositForm.bank"
                            :disabled="disabledForm"
                            @change="checkQRMinMax(BankItem)"
                          />
                          <img
                            :src="BankItem.bankImgUrl"
                            class="depositBankImg rounded"
                            :alt="BankItem.bankName"
                            :title="BankItem.bankName"
                          />
                        </label>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-input-group>
                <div class="input-errors" v-for="(error, index) of v$.qrDepositForm.bank.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </b-col>
              <b-row cols="1" cols-lg="2" class="mb-4">
                <b-col>
                  <b-input-group class="mt-4">
                    <b-form-input
                      id="autodeposit-amount"
                      v-model="qrDepositForm.amount"
                      type="number"
                      v-bind:placeholder="$t('DepositModal.depositAmount')"
                      required
                      :disabled="disabledForm"
                    ></b-form-input>
                  </b-input-group>
                  <label class="text-start text-dark"
                    >{{
                      $t("DepositModal.Min", {
                        depositMin: this.qrDepositForm.depositMin,
                      })
                    }}
                    /
                    {{
                      $t("DepositModal.Max", {
                        depositMax: this.qrDepositForm.depositMax,
                      })
                    }}
                  </label>
                  <div class="input-errors" v-for="(error, index) of v$.qrDepositForm.amount.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </b-col>
                <b-col>
                  <div class="amountBox-container">
                    <b-button
                      variant="amountBox"
                      v-for="boxAmount in amountBox"
                      :key="boxAmount"
                      @click="setBoxAmount(boxAmount)"
                    >
                      {{ boxAmount }}
                    </b-button>
                  </div>
                </b-col>
                <b-col>
                  <b-input-group class="mt-4">
                    <b-form-select
                      id="deposit-channel"
                      v-model="qrDepositForm.bonusId"
                      value-field="item"
                      text-field="name"
                      disabled-field="notEnabled"
                      required="required"
                      :disabled="disabledForm"
                      @change="checkDepositBonus('QR', qrDepositForm.bonusId)"
                    >
                      <option value="0">
                        {{ $t("DepositModal.PleaseSelectBonus") }}
                      </option>
                      <option value="0">
                        {{ $t("DepositModal.NoApplyAnyBonus") }}
                      </option>
                      <option v-for="bonusItem in memberQRBonus" :value="bonusItem.bonusId" :key="bonusItem.bonusId">
                        {{ bonusItem.title }}
                      </option>
                    </b-form-select>
                  </b-input-group>
                  <div class="input-errors" v-for="(error, index) of v$.qrDepositForm.memberBonus.$errors" :key="index">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </b-row>
          <b-row class="justify-content-center my-3" v-if="!disabledForm">
            <b-col cols="6">
              <b-button variant="main" inline @click="submitSurePayDepositForm(true)" class="w-100">{{
                $t("DepositModal.Deposit")
              }}</b-button>
              <b-button variant="none" data-bs-dismiss="modal" class="text-dark w-100">{{
                $t("DepositModal.Cancel")
              }}</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-container>
      <b-container v-else>
        <div class="text-dark h2 my-3 text-center">
          {{ $t("DepositModal.DepositNotAvailable") }}
        </div>
      </b-container>
    </b-container>
  </b-modal>
</template>

<style>
.btn-deposit-tab {
  font-size: 1rem !important;
  color: #28356a !important;
}

.switchContainer {
  justify-content: end;
}

.switchInputGroup {
  margin-top: 1rem;
  justify-content: end;
}

.btn-deposit-tab.disabled {
  color: #6c757d !important;
}
.btn-deposit-tab.active {
  color: #6a91d2 !important;
  text-decoration: underline;
}
.bankLabel {
  width: 100%;
}
.depositBankImg {
  width: 100%;
  height: 45px;
  border: 1px solid #ced4da;
  padding: 1px;
}
.channelBankImg {
  width: 4.5rem;
  height: 4.5rem;
  border: 1px solid #ced4da;
  padding: 1px;
}

.channelBankImg.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.bankLabel .channelBank:checked ~ .channelBankImg {
  border: 2px solid #3b0073;
}
.depositBankImg.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.bankLabel .depositBank:checked ~ .depositBankImg {
  border: 2px solid #002673;
}
.divider {
  line-height: 35px;
  font-weight: 100;
  font-size: 16px;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.btn-deposit-tab {
  min-width: 50px;
}

.btn-deposit-tab:hover,
.btn-deposit-tab:active {
  color: unset !important;
  filter: brightness(10%) !important;
}

.btn.btn-amountBox {
  color: #000000 !important;
  width: 19% !important;
  border: 1px solid #ced4da !important;
  border-radius: 10px !important;
  background: #ffffff !important;
}

.amountBox-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.btn.btn-amountBox:hover,
.btn.btn-amountBox:active {
  color: #000000;
  background: #6c757d !important;
}

@media (max-width: 767px) {
  .btn-deposit-tab {
    font-size: 14px !important;
  }
  .depositSwitchContainer {
    flex-direction: column-reverse !important;
  }
  .switchInputGroup {
    width: 100%;
    justify-content: center;
  }
  .switchContainer {
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .depositBankImg {
    width: auto !important;
  }
}
</style>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minValue, maxValue } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { getCultureID } from "@/currentCulture";
import { bank } from "@/store/BankModule";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      autoDepositForm: {
        amount: {
          required: helpers.withMessage(() => this.$t("DepositModal.AmountRequired"), required),
          minValue: helpers.withMessage(
            ({ $params }) =>
              this.$t("DepositModal.AmountMin", {
                minValue: this.currency + $params.min,
              }),
            minValue(this.autoDepositForm.depositMin)
          ),
          maxValue: helpers.withMessage(
            ({ $params }) =>
              this.$t("DepositModal.AmountMax", {
                maxValue: this.currency + $params.max,
              }),
            maxValue(this.autoDepositForm.depositMax)
          ),
        },
        bank: {
          required: helpers.withMessage(() => this.$t("DepositModal.bankRequired"), required),
        },
        channel: {
          required: helpers.withMessage(() => this.$t("DepositModal.DepositChannelRequired"), required),
        },
        memberBonus: {},
      },
      manualDepositForm: {
        amount: {
          required: helpers.withMessage(() => this.$t("DepositModal.AmountRequired"), required),
          minValue: helpers.withMessage(
            ({ $params }) =>
              this.$t("DepositModal.AmountMin", {
                minValue: this.currency + $params.min,
              }),
            minValue(this.manualDepositForm.depositMin)
          ),
          maxValue: helpers.withMessage(
            ({ $params }) =>
              this.$t("DepositModal.AmountMax", {
                maxValue: this.currency + $params.max,
              }),
            maxValue(this.manualDepositForm.depositMax)
          ),
        },
        channel: {
          required: helpers.withMessage(() => this.$t("DepositModal.DepositChannelRequired"), required),
        },
        receipt: {
          required: helpers.withMessage(() => this.$t("DepositModal.FileNameRequired"), required),
        },
        bank: {
          required: helpers.withMessage(() => this.$t("DepositModal.bankRequired"), required),
        },
        memberBonus: {},
      },
      qrDepositForm: {
        amount: {
          required: helpers.withMessage(() => this.$t("DepositModal.AmountRequired"), required),
          minValue: helpers.withMessage(
            ({ $params }) =>
              this.$t("DepositModal.AmountMin", {
                minValue: this.currency + $params.min,
              }),
            minValue(this.autoDepositForm.depositMin)
          ),
          maxValue: helpers.withMessage(
            ({ $params }) =>
              this.$t("DepositModal.AmountMax", {
                maxValue: this.currency + $params.max,
              }),
            maxValue(this.autoDepositForm.depositMax)
          ),
        },
        bank: {
          required: helpers.withMessage(() => this.$t("DepositModal.bankRequired"), required),
        },
        channel: {
          required: helpers.withMessage(() => this.$t("DepositModal.DepositChannelRequired"), required),
        },
        memberBonus: {},
      },
    };
  },
  data() {
    return {
      autoDepositForm: {
        amount: "",
        channel: "",
        bank: "",
        bonusId: "0",
        depositMin: 0,
        depositMax: 0,
      },
      manualDepositForm: {
        amount: "",
        channel: "",
        receipt: "",
        receiptText: "",
        bank: "",
        bonusId: "0",
        depositMin: 0,
        depositMax: 0,
      },
      qrDepositForm: {
        amount: "",
        channel: "",
        bank: "",
        bonusId: "0",
        depositMin: 0,
        depositMax: 0,
      },
      autoDepositDisabled: false,
      manualDepositDisabled: false,
      qrDepositDisabled: false,
      disabledForm: false,
      isSelectRescueBonus: false,
      AutoBankList: [],
      QRBankList: [],
      currentDepositTab: "",
      accountName: "",
      accountNumber: "",
      currency: "RM",
      depositMin: 1,
      depositMax: 99999,
      settingMin: 0,
      settingMax: 0,
      autoSettingMin: 0,
      autoSettingMax: 0,
      qrSettingMin: 0,
      qrSettingMax: 0,
      isSettingMin: false,
      isSettingMax: false,
      isAutoSettingMin: false,
      isAutoSettingMax: false,
      isQRSettingMin: false,
      isQRSettingMax: false,
      isAutoSelectBonus: false,
      isManualSelectBonus: false,
      isQRSelectBonus: false,
      currentBalance: 0,
      autoDepositBank: [],
      manualDepositBank: [],
      qrDepositBank: [],
      depositChannel: "1",
      optionChannel: [],
      memberBonusList: [],
      memberAutoBonus: [],
      memberManualBonus: [],
      memberQRBonus: [],
      vendorCategory: [],
      memberID: null,
      modalShow: false,
      manualSelectedBank: null,
      amountBox: [50, 100, 300, 500, 1000],
    };
  },
  computed: {
    getMemberID() {
      if (this.memberID) {
        return this.memberID;
      } else if (this.getLoginId) {
        return "+60" + this.getLoginId;
      } else {
        return this.$t("DepositModal.Player");
      }
    },
    getLoginStatus() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    getLoginId() {
      return this.$store.getters["identityServer/getLoginId"];
    },
    getCurrentBalance() {
      return this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
    },
    getAutoDepositBonusId() {
      return this.autoDepositForm.bonusId;
    },
    getManualDepositBonusId() {
      return this.manualDepositForm.bonusId;
    },
    getQRDepositBonusId() {
      return this.qrDepositForm.bonusId;
    },
    getVendorCategory() {
      return this.$store.getters["vendors/getCategory"];
    },
    getIsSelectRescueBonus() {
      return this.isSelectRescueBonus;
    },
  },
  components: {},
  methods: {
    async submitSurePayDepositForm(isQR) {
      const toast = useToast();
      let toastPosition = this.isMobile ? "bottom-center" : "top-center";
      const result = isQR ? await this.v$.qrDepositForm.$validate() : await this.v$.autoDepositForm.$validate();
      if (!result) {
        return;
      }
      const formData = isQR ? this.qrDepositForm : this.autoDepositForm;

      if (formData.bonusId > 0) {
        if (this.getCurrentBalance > this.getVendorCategory.resetThreshold) {
          toast.error(
            this.$t("DepositModal.MakeSureBalanceBelowThreshold", {
              thresholdAmount: this.currency + this.getVendorCategory.resetThreshold,
            }),
            { position: toastPosition }
          );
          return;
        }
      }

      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });

      loader.hide();
      this.hideModal();

      let routeData = this.$router.resolve({
        path: "/surepaydeposit",
        query: {
          bankCode: formData.bank.bankCode,
          amount: formData.amount,
          bonusId: formData.bonusId,
          methodId: formData.bank.methodId,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async submitManualDepositForm() {
      const toast = useToast();
      let toastPosition = this.isMobile ? "bottom-center" : "top-center";

      if (this.manualDepositForm.bonusId > 0) {
        const selectedBonus = this.memberBonusList.find((x) => x.id == this.manualDepositForm.bonusId);
        if (this.getCurrentBalance > this.getVendorCategory.resetThreshold) {
          toast.error(
            this.$t("DepositModal.MakeSureBalanceBelowThreshold", {
              thresholdAmount: this.currency + this.getVendorCategory.resetThreshold,
            }),
            { position: toastPosition }
          );
          return;
        }
        if (selectedBonus.bonusGroup.group.toUpperCase() == "RESCUE") {
          this.manualDepositForm.amount = 0;
          let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
          });
          await this.$store
            .dispatch("payment/addManualDeposit", this.manualDepositForm)
            .then(() => {
              loader.hide();
              toast.success(this.$t("DepositModal.DepositSubmitted"), {
                position: toastPosition,
              });
              this.hideModal();
            })
            .catch((err) => {
              loader.hide();
              toast.error(this.$t("DepositModal.DepositError", { error: err?.response.data.title }), {
                position: toastPosition,
                duration: 5000,
              });
            });
          return;
        }
      }
      const result = await this.v$.manualDepositForm.$validate();
      if (!result) {
        return;
      }
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });

      await this.$store
        .dispatch("payment/addManualDeposit", this.manualDepositForm)
        .then(() => {
          loader.hide();
          toast.success(this.$t("DepositModal.DepositSubmitted"), {
            position: toastPosition,
          });
          this.hideModal();
        })
        .catch((err) => {
          loader.hide();
          toast.error(this.$t("DepositModal.DepositError", { error: err?.response.data.title }), {
            position: toastPosition,
            duration: 5000,
          });
        });
    },
    async depositModalShow() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      this.clearData();
      this.setOptionChannel();
      await this.fetchVendorCategory();
      await this.getSettingMinMax();
      await this.checkWinover();
      await this.getBankInfo();
      await this.getMemberBonus();
      this.bindDefaultAutoBank();
      this.bindDefaultQRBank();
      loader.hide();
    },
    async getBankInfo() {
      await this.$store
        .dispatch("bank/fetchDepositBank")
        .then(() => {
          let bankList = this.$store.getters["bank/getDepositBank"];
          this.manualDepositBank = bankList.filter((x) => x.depositType == 2);
          this.autoDepositBank = bankList.filter((x) => x.depositType == 1);
          this.qrDepositBank = bankList.filter((x) => x.depositType == 3);

          this.autoDepositBank = this.autoDepositBank[0].channel;
          this.manualDepositBank = this.manualDepositBank[0].account;
          this.qrDepositBank = this.qrDepositBank[0].channel;

          if (this.autoDepositBank.length == 0) {
            this.autoDepositDisabled = true;
          }
          if (this.manualDepositBank.length == 0) {
            this.manualDepositDisabled = true;
          }
          if (this.qrDepositBank.length == 0) {
            this.qrDepositDisabled = true;
          }

          if (this.autoDepositDisabled == false) this.currentDepositTab = "Auto";
          else if (this.manualDepositDisabled == false) this.currentDepositTab = "Manual";
          else if (this.qrDepositDisabled == false) this.currentDepositTab = "QR";
          else this.currentDepositTab = "";
        })
        .catch(() => {
          this.currentDepositTab = "";
          this.autoDepositDisabled = true;
          this.manualDepositDisabled = true;
          this.qrDepositDisabled = true;
        });
    },
    async checkWinover() {
      await this.$store.dispatch("memberAccount/fetchVendorBalance", { isFromDB: false }).then(() => {
        const latestBalance = this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
        const memberWinover = this.$store.getters["memberAccount/getMemberWinover"];
        if (memberWinover.winoverBalances.length > 0) {
          const latestWinover = memberWinover.winoverBalances[0].winover;
          const group = memberWinover.winoverBalances[0].category;
          const lockdown = memberWinover.winoverBalances[0].lockdown;

          if (latestWinover > 0) {
            this.disabledForm = true;
            if (latestBalance <= this.getVendorCategory.resetThreshold) {
              this.$store.dispatch("memberAccount/resetWinover", {
                group: group,
                winover: latestWinover,
                lockdown: lockdown,
              });
              this.$store
                .dispatch("memberAccount/fetchMemberWinover", {
                  loginId: this.getLoginId,
                  isFromDB: true,
                })
                .then(() => {
                  this.disabledForm = false;
                });
            }
          } else {
            this.disabledForm = false;
          }
        } else {
          this.disabledForm = false;
        }
      });
    },
    fetchVendorCategory() {
      this.$store.dispatch("vendors/fetchCategoryList");
    },
    async getSettingMinMax() {
      await this.$store.dispatch("globalSetting/fetchGlobalSetting", { remark: "" }).then(() => {
        this.settingMin = this.getSysSettingValue("DepositMin", "Manual");
        this.settingMax = this.getSysSettingValue("DepositMax", "Manual");

        this.autoSettingMin = this.getSysSettingValue("AutoDepositMin", "Auto");
        this.autoSettingMax = this.getSysSettingValue("AutoDepositMax", "Auto");

        this.qrSettingMin = this.getSysSettingValue("AutoDepositQRMin", "QR");
        this.qrSettingMax = this.getSysSettingValue("AutoDepositQRMax", "QR");

        this.checkAutoMinMax([]);
        this.checkManualMinMax(this.manualSelectedBank);
        this.checkQRMinMax([]);
      });
    },
    getSysSettingValue(remarks, depositType) {
      const depositSetting = this.$store.getters["globalSetting/getGlobalSetting"];
      const depositRemarkSetting = depositSetting.find((x) => x.remarks == remarks);
      if (depositRemarkSetting == null || depositRemarkSetting == undefined) {
        return 0;
      } else if (!depositRemarkSetting.status) {
        return 0;
      } else {
        if (remarks == "DepositMin") this.isSettingMin = true;
        if (remarks == "DepositMax") this.isSettingMax = true;
        if (remarks == "AutoDepositMin") this.isAutoSettingMin = true;
        if (remarks == "AutoDepositMax") this.isAutoSettingMax = true;
        if (remarks == "AutoDepositQRMin") this.isQRSettingMin = true;
        if (remarks == "AutoDepositQRMax") this.isQRSettingMax = true;
        return depositRemarkSetting.content;
      }
    },
    async checkAutoMinMax(BankItem) {
      if (!this.isAutoSelectBonus) {
        if (this.isAutoSettingMin) this.autoDepositForm.depositMin = this.autoSettingMin;
        else this.autoDepositForm.depositMin = BankItem.length == 0 ? this.depositMin : BankItem.depositMin;
        if (this.isAutoSettingMax) this.autoDepositForm.depositMax = this.autoSettingMax;
        else this.autoDepositForm.depositMax = BankItem.length == 0 ? this.depositMax : BankItem.depositMax;
      }
    },
    async checkQRMinMax(BankItem) {
      if (!this.isQRSelectBonus) {
        if (this.isQRSettingMin) this.qrDepositForm.depositMin = this.qrSettingMin;
        else this.qrDepositForm.depositMin = BankItem.length == 0 ? this.depositMin : BankItem.depositMin;
        if (this.isQRSettingMax) this.qrDepositForm.depositMax = this.qrSettingMax;
        else this.qrDepositForm.depositMax = BankItem.length == 0 ? this.depositMax : BankItem.depositMax;
      }
    },
    async checkManualMinMax(BankItem) {
      if (!this.isManualSelectBonus) {
        this.manualDepositForm.depositMin = this.isSettingMin ? this.settingMin : this.depositMin;
        this.manualDepositForm.depositMax = this.isSettingMax ? this.settingMax : this.depositMax;

        if (BankItem != null && BankItem != undefined) {
          if (BankItem.depositMin != 0) this.manualDepositForm.depositMin = BankItem.depositMin;
          if (BankItem.depositMax != 0) this.manualDepositForm.depositMax = BankItem.depositMax;
        }
      }
    },
    checkDepositBonus(depositType, selectedBonusId) {
      if (depositType == "Auto") {
        this.isAutoSelectBonus = false;
        this.checkAutoMinMax([]);
      } else if (depositType == "Manual") {
        this.isManualSelectBonus = false;
        this.checkManualMinMax(this.manualSelectedBank);
      } else if (depositType == "QR") {
        this.isQRSelectBonus = false;
        this.checkQRMinMax([]);
      }
      this.isSelectRescueBonus = false;
      if (selectedBonusId !== 0) {
        const selectedBonus = this.memberBonusList.find((x) => x.id == selectedBonusId);
        if (selectedBonus !== undefined) {
          if (depositType == "Auto") {
            this.isAutoSelectBonus = true;
            this.autoDepositForm.depositMin = selectedBonus.min;
          } else if (depositType == "QR") {
            this.isQRSelectBonus = true;
            this.qrDepositForm.depositMin = selectedBonus.min;
          } else if (depositType == "Manual") {
            if (selectedBonus.bonusGroup.group.toUpperCase() == "RESCUE") {
              this.isSelectRescueBonus = true;
            }
            this.isManualSelectBonus = true;
            this.manualDepositForm.depositMin = selectedBonus.min;
          }
        }
      }
    },
    async getMemberBonus() {
      await this.$store.dispatch("bonus/fetchMemberBonusList").then(() => {
        this.memberBonusList = this.$store.getters["bonus/getMemberBonus"]();
        this.filterMemberBonus("");
      });
    },
    async filterMemberBonus(languageCode) {
      const currentLocaleID = getCultureID(languageCode);
      const filterMemberBonusStatus = this.memberBonusList.filter((x) => x.status == true);
      const filterAutoMemberBonusStatus = filterMemberBonusStatus.filter(
        (x) => x.bonusGroup.group.toUpperCase() !== "RESCUE"
      );
      this.memberAutoBonus = filterAutoMemberBonusStatus.map((memberBonus) => {
        return memberBonus.description.filter((x) => x.languageId == currentLocaleID)[0];
      });
      this.memberManualBonus = filterMemberBonusStatus.map((memberBonus) => {
        return memberBonus.description.filter((x) => x.languageId == currentLocaleID)[0];
      });
      this.memberQRBonus = filterAutoMemberBonusStatus.map((memberBonus) => {
        return memberBonus.description.filter((x) => x.languageId == currentLocaleID)[0];
      });
    },
    hideModal() {
      this.modalShow = false;
    },
    switchDeposit(depositTab) {
      this.currentDepositTab = depositTab;
      this.clearData();
    },
    uploadReceipt(event) {
      if (event.target.files.length) {
        var fileData = event.target.files[0];
        this.manualDepositForm.receipt = fileData;
        this.manualDepositForm.receiptText = fileData.name;
      } else {
        this.manualDepositForm.receipt = "";
        this.manualDepositForm.receiptText = "";
      }
    },
    setDepositAccountDetails(bankId) {
      const selectedBank = this.manualDepositBank.filter((x) => x.id == bankId);
      this.accountName = selectedBank[0].accountHolder;
      this.accountNumber = selectedBank[0].accountNumber;
      this.manualSelectedBank = selectedBank[0];
    },
    clearData() {
      (this.autoDepositForm.amount = ""),
        (this.autoDepositForm.bank = ""),
        (this.autoDepositForm.bonusId = "0"),
        (this.autoDepositForm.channel = ""),
        (this.manualDepositForm.amount = ""),
        (this.manualDepositForm.channel = ""),
        (this.manualDepositForm.receipt = ""),
        (this.manualDepositForm.receiptText = ""),
        (this.manualDepositForm.bank = ""),
        (this.manualDepositForm.bonusId = "0"),
        (this.accountNumber = ""),
        (this.accountName = ""),
        (this.manualSelectedBank = null),
        (this.qrDepositForm.amount = ""),
        (this.qrDepositForm.bank = ""),
        (this.qrDepositForm.bonusId = "0"),
        (this.qrDepositForm.channel = "");
      this.$nextTick(() => {
        this.bindDefaultAutoBank();
        this.bindDefaultQRBank();
        this.checkManualMinMax(this.manualSelectedBank);
        this.v$.$reset();
      });
      if (document.getElementById("uploadReceipt") !== null) {
        document.getElementById("uploadReceipt").value = null;
      }
    },
    setOptionChannel() {
      this.optionChannel = [
        { item: "", name: this.$t("DepositModal.PleaseSelect") },
        {
          item: "onlineTransfer",
          name: this.$t("DepositModal.OnlineTransfer"),
        },
        { item: "cdm", name: this.$t("DepositModal.CashDepositMachine") },
        { item: "atm", name: this.$t("DepositModal.ATM") },
        { item: "otc", name: this.$t("DepositModal.OverTheCounter") },
        { item: "cheque", name: this.$t("DepositModal.Cheque") },
      ];
    },
    setAutoBank(channel) {
      this.AutoBankList = channel.accountList;
      this.autoDepositForm.bank = "";
    },
    setQRBank(channel) {
      this.QRBankList = channel.accountList;
      this.qrDepositForm.bank = "";
    },
    bindDefaultAutoBank() {
      if (this.autoDepositBank.length > 0) {
        this.autoDepositForm.channel = this.autoDepositBank[0].name.toUpperCase();
        this.setAutoBank(this.autoDepositBank[0]);
      }
    },
    bindDefaultQRBank() {
      if (this.qrDepositBank.length > 0) {
        this.qrDepositForm.channel = this.qrDepositBank[0].name.toUpperCase();
        this.setQRBank(this.qrDepositBank[0]);
      }
    },
    setBoxAmount(boxAmount) {
      if (this.currentDepositTab == "Auto") {
        this.autoDepositForm.amount = boxAmount;
      } else if (this.currentDepositTab == "Manual") {
        this.manualDepositForm.amount = boxAmount;
      } else if (this.currentDepositTab == "QR") {
        this.qrDepositForm.amount = boxAmount;
      }
    },
  },
  watch: {
    "$i18n.locale": function (newLanguage) {
      this.filterMemberBonus(newLanguage);
    },
    getAutoDepositBonusId(bonusId) {
      this.checkDepositBonus("Auto", bonusId);
    },
    getManualDepositBonusId(bonusId) {
      this.checkDepositBonus("Manual", bonusId);
    },
    getQRDepositBonusId(bonusId) {
      this.checkDepositBonus("QR", bonusId);
    },
    getIsSelectRescueBonus(isSelectRescueBonus) {
      if (isSelectRescueBonus) {
        (this.manualDepositForm.amount = ""),
          (this.manualDepositForm.channel = ""),
          (this.manualDepositForm.receipt = ""),
          (this.manualDepositForm.receiptText = ""),
          (this.manualDepositForm.bank = ""),
          (this.accountNumber = ""),
          (this.accountName = ""),
          this.$nextTick(() => {
            this.v$.$reset();
          });
        if (document.getElementById("uploadReceipt") !== null) {
          document.getElementById("uploadReceipt").value = null;
        }
      }
    },
  },
  async created() {
    await this.$store.dispatch("ipAddress/fetchIPAddress");
  },
};
</script>
