import { TierSettingGroupByTierResponse } from "@/types/Rebate/Response/TierSettingGroupByTierResponse";
import { RebateStateInterface } from "./state";
import { MutationTree } from "vuex";

export enum RebateMutations {
  SET_TIER_SETTING_BROUP_BY_TIER = "SET_TIER_SETTING_BROUP_BY_TIER",
}

export const mutations: MutationTree<RebateStateInterface> = {
  [RebateMutations.SET_TIER_SETTING_BROUP_BY_TIER](state, payload: TierSettingGroupByTierResponse) {
    state.TierSettingGroupByTier = payload;
  },
};
