import httpClient, { ClientId } from "./HttpClient";
import { DownloadLinksResponse } from "@/types/DownloadLinks/Response/DownloadLinksResponse";

const getDownloadLinks = (vendorId: number, downloadType: string): Promise<DownloadLinksResponse[]> => {
	const endPoint = `/api/DownLoadLinks`;
	const params = {
		vendorId: vendorId,
		downloadType: downloadType,
		clientId: ClientId,
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getDownloadLinks };
