export default {
	Deposit: "存款",
	depositAmount: "*存款金额",
	accountBank: "银行",
	accountName: "户口名字",
	accountNumber: "户口号码",
	AmountRequired: "*需要提款金额",
	Min: "最低金额: {depositMin}",
	Max: "最高金额: {depositMax}",
	AutoDeposit: "自动存款",
	ManualDeposit: "手动存款",
	TopUp: "充值",
	Currentbalance: "当前余额:",
	Browse: "选择",
	Cancel: "取消",
	ChooseFile: "选择文件",
	FileNameRequired: "*需要上传收据",
	DepositChannelRequired: "*需要存款途径",
	LastLogin: "上次登录",
	AmountMin: "*金额数量少于 {minValue} ",
	AmountMax: "*金额数量不超过 {maxValue} ",
	Copy: "复制",
	CopySuccess: "复制成功",
	CopyError: "复制失败",
	bankRequired: "*請選擇銀行",
	DepositError: "存款失败。 {error}",
	DepositSubmitted: "存款提交成功",
	Player: "玩家",
	PleaseSelect: "请选择",
	OnlineTransfer: "在线转账",
	CashDepositMachine: "现金存款机",
	ATM: "自动取款机",
	OverTheCounter: "柜台",
	Cheque: "支票",
	PleaseSelectBonus: "请选择优惠",
	NoApplyAnyBonus: "不领取任何优惠",
	WinoverExist: "总赢额存在",
	DepositNotAvailable: "无法使用存款功能",
	ClearBalanceBeforeClaimBonus: "请在索取优惠之前先清除您的余额",
	MakeSureBalanceBelowThreshold: "请在索取优惠之前先确保您的游戏余额低于 {thresholdAmount}",
	AllowOneActiveDeposit: "您之前的存款申请仍在处理中。 如果您需要任何帮助，请联系我们的客服。",
	DepositChannel: "存款途径:",
	QRPay: "QR支付",
	Bank: "银行：",
	PaymentMethod: "付款方式:",
};
