import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { BonusStateInterface } from "./state";
import { BonusResponse, Description } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";
import moment from "moment";
import { getCultureID } from "@/currentCulture";
const timezone = new Date().getTimezoneOffset();

export const getters: GetterTree<BonusStateInterface, RootState> = {
	getBonusList(state): BonusResponse[] {
		const dateNow = moment.utc(new Date()).format();
		const bonusList = state.bonusList
			.filter(
				(bonus) =>
					bonus.status == 1 &&
					moment(dateNow).isAfter(moment(bonus.previewDate)) &&
					moment(dateNow).isBefore(moment(bonus.endDate)) &&
					bonus.showFE
			)
			.sort(function (a, b) {
				return a.priority - b.priority;
			});
		return bonusList;
	},
	getMemberBonus(state) {
		return (languageCode = ""): BonusResponse[] => {
			const currentLocaleID = getCultureID(languageCode);
			const filterMemberBonusStatus = state.memberBonus.filter((x) => x.status == 1 && x.showDeposit == true);
			const filterMemberBonus = filterMemberBonusStatus.map((memberBonus) => {
				return memberBonus.description.filter((x) => x.languageId == currentLocaleID)[0];
			});
			return filterMemberBonusStatus;
		};
	},
	getBonusClaimed(state): BonusClaimedResponse[] {
		state.bonusClaimed.map((bonusItem) => {
			const createDate = new Date(new Date(bonusItem.created).getTime() - timezone * 60 * 1000);
			bonusItem.created = createDate;
			return bonusItem;
		});
		return state.bonusClaimed;
	},
	getUniqueCode(state): UniqueCodeResponse[] {
		return state.uniqueCode;
	},
};
