import httpClient from "./HttpClient";

const getIpAddress = (): Promise<any> => {
  const endPoint = "";
  return httpClient
    .get(endPoint)
    .then((response) => response.data)
    .catch(() => "1.1.1.1");
};

export { getIpAddress };
