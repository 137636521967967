<template>
  <b-modal
    id="updateProfile-modal"
    class="myaccount-modal"
    v-model="modalShow"
    size="md"
    hide-footer
    centered
    data-backdrop="static"
    :title="$t('ProfileModal.PROFILE')"
    @show="getProfileData()"
  >
    <b-container>
      <b-col>
        <b-row>
          <b-form>
            <div class="text-dark">
              <b-form-group :label="$t('ProfileModal.AccountID')">
                <b-form-input
                  id="accountId"
                  v-model="accountId"
                  :placeholder="$t('ProfileModal.AccountID')"
                  trim
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group :label="$t('ProfileModal.GameID')" style="display: none">
                <b-form-input id="gameId" v-model="gameId" trim disabled></b-form-input>
              </b-form-group>

              <b-form-group :label="$t('ProfileModal.FullName')">
                <b-form-input
                  id="fullname"
                  v-model="fullname"
                  :placeholder="$t('ProfileModal.FullName')"
                  trim
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group :label="$t('ProfileModal.doBirth')">
                <b-form-input
                  id="dobirth"
                  v-model="profileForm.dobirth"
                  type="date"
                  :placeholder="$t('ProfileModal.doBirth')"
                  trim
                  :disabled="viewOnly"
                ></b-form-input>

                <div class="input-errors" v-for="(error, index) of v$.profileForm.dobirth.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </b-form-group>
            </div>
          </b-form>
        </b-row>
        <b-row class="mx-0 my-3" v-if="!viewOnly">
          <b-button variant="main" block @click="propNoticeDialog">{{ $t("ProfileModal.Update") }}</b-button>
        </b-row>
      </b-col>
    </b-container>
    <b-overlay :show="propNotice" no-wrap @shown="onShown" class="w-100">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center"
        >
          <div class="h4 text-black">{{ $t("ProfileModal.AreYouSure") }}</div>
          <p>
            <strong id="form-confirm-label" class="text-black">{{ $t("ProfileModal.birthdayIsCorrect") }}</strong>
          </p>
          <div class="d-flex justify-content-around">
            <b-button variant="danger" @click="onCancel"> {{ $t("ProfileModal.Cancel") }} </b-button>
            <b-button variant="success" @click="submitForm">{{ $t("ProfileModal.OK") }}</b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      profileForm: {
        dobirth: {
          required: helpers.withMessage(() => this.$t("ProfileModal.DOBHolderRequired"), required),
        },
      },
    };
  },
  data() {
    return {
      profileForm: {
        dobirth: "",
      },
      accountId: "",
      fullname: "",
      gameId: "",
      modalShow: false,
      viewOnly: false,
      propNotice: false,
    };
  },
  computed: {},
  components: {},
  methods: {
    async getProfileData() {
      await this.$store.dispatch("member/fetchMember").then(() => {
        const memberDetail = this.$store.getters["member/getMember"];
        this.accountId = memberDetail.phoneNumber;
        this.fullname = !memberDetail.fullName ? " " : memberDetail.fullname;
        if (memberDetail.dob) {
          this.profileForm.dobirth = memberDetail.dob.substr(0, 10);
          this.viewOnly = true;
        } else {
          this.viewOnly = false;
          this.propNotice = false;
        }
      });
      await this.$store.dispatch("memberAccount/fetchMemberAccount").then(() => {
        this.gameId = this.$store.getters["memberAccount/getMemberAccount"].vendorGameId;
      });
    },
    async submitForm() {
      const toast = useToast();
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      await this.$store.dispatch("member/updateDOB", this.profileForm);
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      let toastPosition = this.isMobile ? "bottom-center" : "top-center";
      setTimeout(() => {
        loader.hide();
        toast.success(this.$t("ProfileModal.UpdateSuccess"), { position: toastPosition });
        this.hideModal();
      }, 1000);
    },
    async propNoticeDialog() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      this.propNotice = true;
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus();
    },
    hideModal() {
      this.modalShow = false;
    },
    onCancel() {
      this.propNotice = false;
    },
  },
};
</script>
