import { MemberAccountStateInterface } from "./state";
import { MemberVendorBalanceResponse } from "@/types/MemberAccount/Response/MemberVendorBalanceResponse";
import { MemberWinoverResponse } from "@/types/MemberAccount/Response/MemberWinoverResponse";
import { MemberAccountResponse } from "@/types/MemberAccount/Response/MemberAccountResponse";
import { MutationTree } from "vuex";
import { MemberVendorBalanceByTenantIdResponse } from "@/types/MemberAccount/Response/MemberVendorBalanceByTenantIdResponse";

export enum MemberAccountMutations {
	SET_VENDOR_BALANCE = "SET_VENDOR_BALANCE",
	SET_MEMBER_WINOVER = "SET_MEMBER_WINOVER",
	SET_MEMBER_ACCOUNT = "SET_MEMBER_ACCOUNT",
	SET_MEMBER_BANK_STATUS = "SET_MEMBER_BANK_STATUS",
	SET_ALL_SSO_MEMBER_BALANCE = "SET_ALL_SSO_MEMBER_BALANCE"
}

export const mutations: MutationTree<MemberAccountStateInterface> = {
	[MemberAccountMutations.SET_VENDOR_BALANCE](state, payload: MemberVendorBalanceResponse) {
		state.balance = payload.balance;
	},
	[MemberAccountMutations.SET_MEMBER_WINOVER](state, payload: MemberWinoverResponse) {
		state.memberWinover = payload;
	},
	[MemberAccountMutations.SET_MEMBER_ACCOUNT](state, payload: MemberAccountResponse) {
		state.memberAccount = payload;
	},
	[MemberAccountMutations.SET_ALL_SSO_MEMBER_BALANCE](state, payload: MemberVendorBalanceByTenantIdResponse[]){
		state.MemberVendorBalanceByTenantId = payload;
	}
};
