export default {
	ForgotPassword: "忘记密码",
	PhoneNumber: "电话号码",
	Submit: "确认",
	PhoneRequired: "*请填写手机号码",
	PhoneNumeric: "*手机号码必须是数字",
	PhoneMin: "*手机号码必须至少{minLength}位数",
	PhoneMax: "*手机号码必须最多{maxLength}位数",
	SubmitSuccess: "成功提交",
	SubmitFailed: "提交失敗",
	requestTac: "请求TAC",
	verificationCode: "验证码",
	resendInSeconds: "{time}秒后可重发",
	TACRequired: "*请填写验证码",
	back: "返回",
	resendTac: "重发TAC",
	tacSuccess: "TAC请求成功。请检查您的手机",
	tacFailed: "TAC请求失败。请稍后再试",
	tacVerificationFailed: "TAC验证失败",
	minLength: "请至少输入 {minLength} 个字符",
	maxLength: "超出字符限制：{maxLength}",
};
