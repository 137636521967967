<template>
	<b-modal
		id="bankInfo-modal"
		class="myaccount-modal"
		v-model="modalShow"
		size="md"
		hide-footer
		centered
		data-backdrop="static"
		:title="$t('BankInfoModal.BankInfo')"
		@show="bankInfoShow"
	>
		<b-container v-if="bankInfoContainer">
			<b-col>
				<b-row>
					<b-form @submit.prevent="submitForm" @keyup.enter="submitForm">
						<b-form-group class="text-dark" :label="$t('BankInfoModal.BankName')">
							<v-select
								label="name"
								:options="options"
								:searchable="false"
								:clearable="false"
								v-model="selectedBank"
								:items="options"
								@input="changeRoute"
							>
								<template v-slot:option="option">
									<div class="d-flex align-items-center option-box">
										<img :src="option.url" class="bankImg" />
										<span class="ms-2">{{ option.bankName }}</span>
									</div>
								</template>
								<template v-slot:selected-option="option">
									<div class="selected d-flex align-items-center option-box">
										<img :src="option.url" class="bankImg" />
										<span class="ms-2">{{ option.bankName }}</span>
									</div>
								</template>
							</v-select>
							<!-- <b-form-select
								v-model="selected"
								:options="options"
								class="mb-3"
								value-field="item"
								text-field="name"
								disabled-field="notEnabled"
							></b-form-select> -->
						</b-form-group>

						<b-form-group class="text-dark" :label="$t('BankInfoModal.AccountHolder')">
							<b-form-input
								id="accountHolder"
								v-model="bankinfoForm.accountHolder"
								:placeholder="$t('BankInfoModal.AccountHolder')"
							></b-form-input>
							<div class="input-errors" v-for="(error, index) of v$.bankinfoForm.accountHolder.$errors" :key="index">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</b-form-group>

						<b-form-group class="text-dark" :label="$t('BankInfoModal.AccountNumber')">
							<b-form-input
								id="AccountNumber"
								v-model="bankinfoForm.accountNumber"
								:placeholder="$t('BankInfoModal.AccountNumber')"
								trim
							></b-form-input>
							<div class="input-errors" v-for="(error, index) of v$.bankinfoForm.accountNumber.$errors" :key="index">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</b-form-group>

						<b-col style="background: #f0f0f0; padding: 5%; border-radius: 0.25rem">
							<input
								id="inputTAC"
								class="form-control"
								:placeholder="$t('BankInfoModal.TACCode')"
								v-model="bankinfoForm.tacCode"
							/>
							<div class="input-errors" v-for="(error, index) of v$.bankinfoForm.tacCode.$errors" :key="index">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
							<br />
							<b-button id="btnResendTAC" type="button" class="form-control" variant="main" @click="requestTAC">
								{{ $t("BankInfoModal.RequestTAC") }}
							</b-button>
						</b-col>
					</b-form>
				</b-row>
				<b-row class="mx-0 my-3">
					<b-button variant="main" block @click="submitForm">{{ $t("BankInfoModal.Add") }}</b-button>
				</b-row>
			</b-col>
		</b-container>
		<b-container v-else>
			<b-col>
				<b-row>
					<b-form>
						<b-form-group class="text-dark" :label="$t('BankInfoModal.BankName')">
							<b-form-input id="account-bankName" v-model="bankinfoForm.bankName" trim disabled></b-form-input>
							<!-- <b-form-select
                v-model="selected"
                value-field="item"
                :options="options"
                text-field="name"
                disabled-field="notEnabled"
              ></b-form-select> -->
						</b-form-group>

						<b-form-group class="text-dark" :label="$t('BankInfoModal.AccountHolder')">
							<b-form-input
								id="accountholder"
								v-model="bankinfoForm.accountHolder"
								:placeholder="$t('BankInfoModal.AccountHolder')"
								disabled
							></b-form-input>
							<div class="input-errors" v-for="(error, index) of v$.bankinfoForm.accountHolder.$errors" :key="index">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</b-form-group>

						<b-form-group class="text-dark" :label="$t('BankInfoModal.AccountNumber')">
							<b-form-input
								id="accountNumber"
								:placeholder="$t('BankInfoModal.AccountNumber')"
								v-model="bankinfoForm.accountNumber"
								trim
								disabled
							></b-form-input>
							<div class="input-errors" v-for="(error, index) of v$.bankinfoForm.accountNumber.$errors" :key="index">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</b-form-group>
					</b-form>
				</b-row>
			</b-col>
		</b-container>
	</b-modal>
</template>
<style scoped>
.bankImg {
	width: 25%;
}
</style>
<style>
.vs__search {
	display: none;
}
.option-box {
	min-height: 50px;
}
</style>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
export default {
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			bankinfoForm: {
				accountHolder: {
					required: helpers.withMessage(() => this.$t("BankInfoModal.AccountHolderRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("BankInfoModal.accountNameMin", { minLength: $params.min }),
						minLength(this.accountNameMin)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("BankInfoModal.accountNameMax", { maxLength: $params.max }),
						maxLength(this.accountNameMax)
					),
				},
				accountNumber: {
					required: helpers.withMessage(() => this.$t("BankInfoModal.AccountNumberRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("BankInfoModal.accountNumberMin", { minAccNoLength: $params.min }),
						minLength(this.accountNumberMin)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("BankInfoModal.accountNumberMax", { maxAccNoLength: $params.max }),
						maxLength(this.accountNumberMax)
					),
				},
				tacCode: {
					required: helpers.withMessage(() => this.$t("BankInfoModal.TACRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("BankInfoModal.tacLength", { tacLength: $params.min }),
						minLength(this.tacLength)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("BankInfoModal.tacLength", { tacLength: $params.max }),
						maxLength(this.tacLength)
					),
				},
			},
		};
	},
	data() {
		return {
			bankinfoForm: {
				bankId: "",
				accountHolder: "",
				accountNumber: "",
				bankName: "",
				tacCode: "",
			},
			bankInfoContainer: true,
			options: [],
			modalShow: false,
			selectedBank: {},
			accountNameMin: 5,
			accountNameMax: 100,
			accountNumberMin: 7,
			accountNumberMax: 20,
			tacLength: 6,
			toastPosition: this.isMobile ? "bottom-center" : "top-center",
		};
	},
	computed: {},
	components: {},
	methods: {
		async submitForm() {
			this.bankinfoForm.bankId = this.selectedBank.item;
			const result = await this.v$.$validate();
			if (!result) {
				return;
			}

			let loader = this.$loading.show({
				container: this.fullPage ? null : this.$refs.formContainer,
			});
			const loginId = this.$store.getters["identityServer/getLoginId"];
			await this.$store
				.dispatch("register/verifyTAC", { phoneNo: loginId, token: this.bankinfoForm.tacCode })
				.then(() => {
					this.$store
						.dispatch("member/addMemberBank", this.bankinfoForm)
						.then((data) => {
							if(data.succeeded){
								loader.hide();
								this.toast.success(this.$t("BankInfoModal.SubmitSuccess"), { position: this.toastPosition });
								this.bankInfoShow();
							}else{
								if(data.error[0] == "Bank Account Duplicate"){
									this.toast.error(this.$t("BankInfoModal.DuplicateBank"), { position: this.toastPosition });
								}else{
									this.toast.error(this.$t("BankInfoModal.SubmitError"), { position: this.toastPosition });
								}
								loader.hide();
							}
						})
						.catch(() => {
							this.toast.error(this.$t("BankInfoModal.SubmitError"), { position: this.toastPosition });
							loader.hide();
						});
				})
				.catch(() => {
					this.toast.error(this.$t("BankInfoModal.VerifyTACError"), { position: this.toastPosition });
					loader.hide();
				});
		},
		hideModal() {
			this.modalShow = false;
		},
		changeRoute() {
			//console.log(this.selected);
		},
		async requestTAC() {
			const loginId = this.$store.getters["identityServer/getLoginId"];
			await this.$store
				.dispatch("register/requestTAC", { phoneNo: loginId, password: "" })
				.then(() => {
					this.toast.success(this.$t("BankInfoModal.RequestTACSuccess"), { position: this.toastPosition });
				})
				.catch(() => {
					this.toast.error(this.$t("BankInfoModal.RequestTACFailed"), { position: this.toastPosition });
				});
		},
		async bankInfoShow() {
			await this.$store.dispatch("member/fetchMemberBank").then(() => {
				let memberBank = this.$store.getters["member/getMemberBank"];
				if (memberBank == undefined || memberBank.length == 0) {
					this.bankInfoContainer = true;
				} else {
					this.bankInfoContainer = false;
					this.bankinfoForm.accountHolder = memberBank.accountHolder;
					this.bankinfoForm.accountNumber = memberBank.accountNumber;
					this.bankinfoForm.bankName = memberBank.bankName;
				}
			});

			await this.$store.dispatch("bank/fetchWithdrawalBank");
			this.options = this.$store.getters["bank/getWithdrawalBank"];
			this.selectedBank = this.options[0];
		},
	},
};
</script>
