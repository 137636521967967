import { IdentityServerStateInterface } from "./state";
import { MutationTree } from "vuex";

export enum IdentityServerMutations {
	SET_LOGIN_STATUS = "SET_LOGIN_STATUS",
	SET_AUTO_LOGOUT = "SET_AUTO_LOGOUT",
	SET_MEMBER_INFO = "SET_MEMBER_INFO",
	SET_ONREFRESH_STATUS = "SET_ONREFRESH_STATUS",
	SET_ONSUBMIT_LOGIN = "SET_ONSUBMIT_LOGIN",
	SET_INTERCHANGE = "SET_INTERCHANGE",
}

export const mutations: MutationTree<IdentityServerStateInterface> = {
	[IdentityServerMutations.SET_LOGIN_STATUS](state, payload) {
		state.isLogin = payload.loginStatus;
	},
	[IdentityServerMutations.SET_AUTO_LOGOUT](state, payload) {
		state.didAutoLogout = payload.autoLogoutStatus;
	},
	[IdentityServerMutations.SET_MEMBER_INFO](state, payload) {
		state.loginId = payload.name;
		state.memberId = payload.sub;
	},
	[IdentityServerMutations.SET_ONREFRESH_STATUS](state, payload) {
		state.onRefresh = payload.refreshStatus;
	},
	[IdentityServerMutations.SET_ONSUBMIT_LOGIN](state, payload) {
		state.onSubmitLogin = payload.submitLoginStatus;
	},
	[IdentityServerMutations.SET_INTERCHANGE](state,payload) {
		state.isInterchange = payload;
	},
};
