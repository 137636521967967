import { ActionTree } from "vuex";
import { getLaunchGameUrl } from "@/network/GamesService/Games";
import { GamesStateInterface } from "./state";
import { RootState } from "@/store/state";

export const actions: ActionTree<GamesStateInterface, RootState> = {
	async fetchLaunchGameUrl(context, payload) {
		const vendorId = context.rootGetters["vendors/getVendor"].id;
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await getLaunchGameUrl(vendorId, memberId, payload.GameType, payload.IsMobile, payload.Lang);
		return res;
	},
};
