export default {
  Withdraw: "Pengeluaran",
  StartDate: "Tarikh Mula*",
  EndDate: "Tarikh Tamat*",
  Submit: "Hantar",
  TransactionID: "ID Transaksi",
  TransactionType: "Jenis Transaksi",
  Amount: "Jumlah",
  CreatedDate: "Tarih Transaksi",
  Status: "Status",
};
