import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { TenantStateInterface } from "./state";
import { ClientTenantInfoResponse } from "@/types/Tenant/Response/ClientTenantInfoResponse";

export const getters: GetterTree<TenantStateInterface, RootState> = {
	getClientTenantInfo(state): ClientTenantInfoResponse[] {
		return state.ClientTenantInfo;
	},
};
