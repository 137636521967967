export default {
	Interchange: "切换我",
	WinoverExist: "总赢额存在",
	NotTransferred: "请注意，余额将不会被转移。",
	WaitingCreateAccountMessage: "我们正在创建您的帐户，请稍候...",
	WaitingTransferMessage: "您的账户余额正在转移...",
	ExternalWinoverExistMessage: "余额转帐失败，您所选的网站仍有总赢额存在。",
	OK: "好的",
	Cancel: "取消",
	TransferFailed: "余额转帐失败。",
	Proceed: "继续",
	DoYouWishToProceed: "请问您还需要继续吗?",
};
