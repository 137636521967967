import { BonusResponse } from "@/types/Bonus/Response/BonusResponse";
import { BonusClaimedResponse } from "@/types/Bonus/Response/BonusClaimedResponse";
import { UniqueCodeResponse } from "@/types/Bonus/Response/UniqueCodeResponse";

export interface BonusStateInterface {
  bonusList: BonusResponse[];
  memberBonus: BonusResponse[];
  uniqueCode: UniqueCodeResponse[];
  bonusClaimed: BonusClaimedResponse[];
}

function state(): BonusStateInterface {
  return {
    bonusList: [],
    memberBonus: [],
    uniqueCode: [],
    bonusClaimed: [],
  };
}

export default state;
