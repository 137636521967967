import { createI18n } from "vue-i18n";
import zh from "./zh";
import en from "./en";
import ms from "./ms";
import { getDefaultCultureCode } from "@/currentCulture";

const i18n = createI18n({
	locale: getDefaultCultureCode(),
	legacy: false,
	messages: {
		zh: zh,
		en: en,
		ms: ms,
	},
});

export default i18n;
