import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { MiniGamesStateInterface } from "./state";
import { MiniGamesListResponse } from "@/types/MiniGames/Response/MiniGamesListResponse";

export const getters: GetterTree<MiniGamesStateInterface, RootState> = {
  getMiniGamesList(state): MiniGamesListResponse[] {
    return state.miniGamesList;
  },
};
