export default {
	SignUp: "Daftar",
	PhoneNumber: "Telefon",
	Password: "Kata Laluan",
	Show: "Tunjuk",
	Hide: "Sembunyi",
	PhoneRequired: "*Nombor Telefon diperlukan",
	PhoneNumeric: "*Nombor Telefon mesti berangka",
	PhoneMin: "*Nombor Telefon mesti sekurangnya {minLength} bilangan",
	PhoneMax: "*Nombor Telefon mesti tidak melebihi {maxLength} bilangan",
	PasswordRequired: "*Kata laluan diperlukan",
	PasswordMin: "*Kata Laluan mesti sekurangnya {minLength} bilangan",
	PasswordMax: "*Kata Laluan mesti tidak melebihi {maxLength} bilangan",
	ConfirmPassword: "Sahkan Kata Laluan",
	ConfirmPasswordRequired: "*Sahkan Kata laluan diperlukan",
	Desc1: "Saya berumur lebih dari 18 tahun dan saya menerima ",
	Desc2: " dan ",
	VerificationNumber: "Nombor Pengesahan",
	TACCode: "Kod TAC",
	Resend: "Hantar Semula",
	ConfirmTAC: "Sahkan TAC",
	TACCodehasbeensenttoyourcontactnumber: "Kod TAC telah dihantar ke nombor hubungan anda.",
	TermsandConditions: "Terma",
	PrivacyPolicy: "Syarat serta Dasar Privasi ini",
	NotSamePassword: "*SahKan Kata Laluan berbeza dengan Kata Laluan",
	AcknowledgeUncheck: "*Sila terima Syarat Perkhidmatan kami sebelum meneruskan",
	RequestTACSuccess: "Permintaan TAC Berjaya",
	RequestTACFailed: "Permintaan TAC Gagal",
	RegisterSuccess: "Daftar Berjaya",
	RegisterError: "Daftar Gagal",
	VerifyTACError: "TAC Pengesahan Gagal",
	Next: "Terus",
	Account: "Akaun and telah",
	Success: "Berjaya Mendaftar",
	ReferralCode: "Kod Rujukan",
	InvalidReferralCode: "Kod Rujukan tidak sah",
};
