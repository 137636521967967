export default {
	Group: "{group} 组",
	Teams: "队伍",
	P: "P",
	W: "W",
	D: "D",
	L: "L",
	GF: "GF",
	GA: "GA",
	"+-": "+/-",
	PTS: "分数",
	HideMatches: "隐藏匹配",
	ShowMatches: "显示匹配",
	TodayMatches: "今天赛事",
	TomorrowMatches: "明日赛事",
	UpcomingMatches: "即将到来",
	PreviousMatches: "已往的赛事",
	notstarted: "未开始",
	h1: "上半场",
	hf: "中场休息",
	h2: "下半场",
	finished: "已结束",
	Date: "日期",
	Time: "时间",
	HomeTeam: "主队",
	HomeScore: "主队分数",
	AwayTeam: "客队",
	AwayScore: "客队分数",
	Status: "状态",
};
