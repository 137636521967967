<template>
	<HomeBanner></HomeBanner>
	<AnnouncementView></AnnouncementView>
	<HomeReputation></HomeReputation>
	<HomeQatar2022></HomeQatar2022>
	<HomePromotion></HomePromotion>
	<event-view></event-view>
</template>
<style>
.homeTitle {
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 36px;
	color: #28356a;
}
</style>
<script>
import AnnouncementView from "@/components/content/Announcement/AnnouncementView.vue";
import HomeBanner from "@/components/content/Home/HomeBanner.vue";
import HomePromotion from "@/components/content/Home/HomePromotion.vue";
import HomeDownload from "@/components/content/Home/HomeDownload.vue";
import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";
import EventView from "@/components/content/Event/EventView.vue";
import HomeQatar2022 from "@/components/content/Home/HomeQatar2022.vue";
import HomeReputation from "@/components/content/Home/HomeReputation.vue";

export default {
	props: ["token", "time"],
	data() {
		return {};
	},
	components: {
		HomeBanner,
		AnnouncementView,
		HomePromotion,
		EventView,
		HomeQatar2022,
		HomeReputation,
	},
	methods: {},
};
</script>
