import { PaymentStateInterface } from "./state";
import { StatementResponse } from "@/types/Payment/Response/StatementResponse";
import { BonusReferralResponse } from "@/types/Payment/Response/BonusReferralResponse";
import { MutationTree } from "vuex";

export enum PaymentMutations {
  SET_MEMBER_STATEMENT_DEPOSIT = "SET_MEMBER_STATEMENT_DEPOSIT",
  SET_MEMBER_STATEMENT_WITHDRAWAL = "SET_MEMBER_STATEMENT_WITHDRAWAL",
  SET_MEMBER_STATEMENT_TRANSFER = "SET_MEMBER_STATEMENT_TRANSFER",
  SET_MEMBER_ADJUSTMENT = "SET_MEMBER_ADJUSTMENT",
  SET_MEMBER_BONUS_REFERRAL = "SET_MEMBER_BONUS_REFERRAL",
}

export const mutations: MutationTree<PaymentStateInterface> = {
  [PaymentMutations.SET_MEMBER_STATEMENT_DEPOSIT](state, payload: StatementResponse[]) {
    state.depositStatement = payload;
  },
  [PaymentMutations.SET_MEMBER_STATEMENT_WITHDRAWAL](state, payload: StatementResponse[]) {
    state.withdrawalStatement = payload;
  },
  [PaymentMutations.SET_MEMBER_STATEMENT_TRANSFER](state, payload: StatementResponse[]) {
    state.transferStatement = payload;
  },
  [PaymentMutations.SET_MEMBER_ADJUSTMENT](state, payload: StatementResponse[]) {
    state.adjustment = payload;
  },
  [PaymentMutations.SET_MEMBER_BONUS_REFERRAL](state, payload: BonusReferralResponse[]) {
    state.bonusReferral = payload;
  },
};
