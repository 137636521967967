<template>
	<b-modal id="runningtext-modal" class="default-modal" hide-footer :title="$t('RunningTextModal.Announcement')">
		<b-container fluid class="rtContainer">
			<b-row v-for="(runningText, index) in runningTextList" :key="`runningTextList-${index}`">
				<b-col cols="1">
					<div class="runningTextRow">{{ index + 1 }}.</div>
				</b-col>
				<b-col cols="11">
					<div class="text-break runningTextRow">{{ runningText }}</div>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
export default {
	props: ["runningTextList"],
	methods: {},
};
</script>

<style scoped>
.rtContainer {
	padding: 0px 30px 20px;
}
.runningTextRow {
	text-align: justify;
	padding-right: 20px;
	line-height: 1.3rem;
	padding-bottom: 1rem;
	font-size: 1rem;
}
</style>
