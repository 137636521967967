export default {
	Referral: "Rujukan",
	MinDepositRequired: "Sekurang-kurangnya {minDeposit} deposit diperlukan untuk menyertai tawaran program rujukan",
	ReferralCode: "Kod Rujukan",
	Copy: "Salinan",
	ReferralLink: "Pautan Rujukan",
	SuccessfulRegistrations: "Pendaftaran Berjaya",
	SuccessfulDeposited: "Deposit Berjaya",
	MinDeposited: "Minimum {minDepositAmount}",
	ShareToFriends: "Kongsikan kepada rakan anda",
	ShareNow: "KONGSI SEKARANG",
	Facebook: "Facebook",
	WhatsApp: "WhatsApp",
	Telegram: "Telegram",
	Email: "Mel",
	LINE: "LINE",
	Twitter: "Twitter",
	CopySuccess: "Salin Berjaya",
	CopyError: "Salinan Kosong",
	TermConditionReferral: "Terma dan syarat dikenakan untuk semua bonus rujukan.",
};
