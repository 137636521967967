import { BetHistoryResponse } from "@/types/WinLosses/Response/BetHistoryResponse";


export interface WinLossesStateInterface {
    betHistory : BetHistoryResponse[]
}

function state(): WinLossesStateInterface {
  return {
    betHistory : []
  };
}

export default state;