import httpClient from "./HttpClient";
import { RegisterMemberRequest } from "@/types/Register/Request/RegisterMemberRequest";

const registerAccount = (
	loginId: string,
	password: string,
	countryCode: string,
	referralCode: string,
	token: string
): Promise<any> => {
	const endPoint = `/api/Members`;
	httpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	const request: RegisterMemberRequest = {
		password: password,
		loginId: loginId,
		countryCode: countryCode,
		referral: referralCode,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const requestTAC = (loginId: string, password: string, token: string): Promise<any> => {
	const endPoint = `/api/Members/${loginId}/Phone/Challenge`;
	httpClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	const request: RegisterMemberRequest = {
		password: password,
		loginId: loginId,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

const verifyTAC = (loginId: string, tac: string, accessToken: string): Promise<any> => {
	const endPoint = `/api/Members/${loginId}/Phone/Confirmation`;
	httpClient.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
	const request: RegisterMemberRequest = {
		loginId: loginId,
		token: tac,
	};

	return httpClient.post(endPoint, JSON.stringify(request)).then((response) => response.data);
};

export default {
	registerAccount,
	requestTAC,
	verifyTAC,
};
