import { SurePayDepositResponse } from "@/types/PaymentChannel/Response/SurePayDepositResponse";
import { MutationTree } from "vuex";
import { PaymentChannelStateInterface } from "./state";


export enum PaymentChannelMutations{
    SET_SUREPAY_DEPOSIT = "SET_SUREPAY_DEPOSIT"
}


export const mutations: MutationTree<PaymentChannelStateInterface> = {
    [PaymentChannelMutations.SET_SUREPAY_DEPOSIT](state,payload: SurePayDepositResponse){
        state.surepayDeposit = payload;
    }
}