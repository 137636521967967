<template>
	<b-modal
		id="changePassword-modal"
		v-model="modalShow"
		size="md"
		hide-footer
		centered
		data-backdrop="static"
		:title="$t('ChangePasswordModal.ChangePassword')"
		class="myaccount-modal"
		@show="resetData"
	>
		<b-container class="px-5">
			<b-row>
				<b-col cols="12">
					<b-form ref="form">
						<b-form-input v-model="changePasswordForm.phoneNo" hidden="hidden"></b-form-input>
						<b-input-group class="mt-3">
							<b-form-input
								v-model="changePasswordForm.currentPassword"
								:placeholder="$t('ChangePasswordModal.CurrentPassword')"
								:type="passwordFieldType"
								required
							></b-form-input>
						</b-input-group>
						<div
							class="input-errors"
							v-for="(error, index) of v$.changePasswordForm.currentPassword.$errors"
							:key="index"
						>
							<div class="error-msg">{{ error.$message }}</div>
						</div>

						<b-input-group class="mt-3">
							<b-form-input
								v-model="changePasswordForm.newPassword"
								:placeholder="$t('ChangePasswordModal.NewPassword')"
								:type="passwordFieldType"
								required
							></b-form-input>
						</b-input-group>
						<div class="input-errors" v-for="(error, index) of v$.changePasswordForm.newPassword.$errors" :key="index">
							<div class="error-msg">{{ error.$message }}</div>
						</div>
						<b-input-group class="mt-3">
							<b-form-input
								v-model="changePasswordForm.confirmPassword"
								:placeholder="$t('ChangePasswordModal.ConfirmPassword')"
								:type="passwordFieldType"
								required
							></b-form-input>
						</b-input-group>
						<div
							class="input-errors"
							v-for="(error, index) of v$.changePasswordForm.confirmPassword.$errors"
							:key="index"
						>
							<div class="error-msg">{{ error.$message }}</div>
						</div>
					</b-form>
				</b-col>
			</b-row>
			<b-row class="mx-0 my-3">
				<b-button variant="main" block @click="submitForm">{{ $t("ChangePasswordModal.Submit") }}</b-button>
				<b-button variant="none" class="text-dark" data-bs-dismiss="modal">{{
					$t("ChangePasswordModal.Cancel")
				}}</b-button>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, maxLength, sameAs } from "@vuelidate/validators";
export default {
	setup() {
		return { v$: useVuelidate() };
	},
	validations() {
		return {
			changePasswordForm: {
				currentPassword: {
					required: helpers.withMessage(() => this.$t("ChangePasswordModal.currentPasswordRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("ChangePasswordModal.PasswordMin", { minLength: $params.min }),
						minLength(6)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("ChangePasswordModal.PasswordMax", { maxLength: $params.max }),
						maxLength(16)
					),
				},
				newPassword: {
					required: helpers.withMessage(() => this.$t("ChangePasswordModal.newPasswordRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("ChangePasswordModal.PasswordMin", { minLength: $params.min }),
						minLength(6)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("ChangePasswordModal.PasswordMax", { maxLength: $params.max }),
						maxLength(16)
					),
				},
				confirmPassword: {
					required: helpers.withMessage(() => this.$t("ChangePasswordModal.confirmPasswordRequired"), required),
					minLength: helpers.withMessage(
						({ $params }) => this.$t("ChangePasswordModal.PasswordMin", { minLength: $params.min }),
						minLength(6)
					),
					maxLength: helpers.withMessage(
						({ $params }) => this.$t("ChangePasswordModal.PasswordMax", { maxLength: $params.max }),
						maxLength(16)
					),
					sameAsPassword: helpers.withMessage(
						() => this.$t("ChangePasswordModal.NotSamePassword"),
						sameAs(this.changePasswordForm.newPassword)
					),
				},
			},
		};
	},
	data() {
		return {
			changePasswordForm: {
				phoneNo: "",
				currentPassword: "",
				newPassword: "",
				confirmPassword: "",
			},
			passwordMin: 6,
			passwordMax: 16,
			passwordFieldType: "password",
			showHideText: "Show",
			isMobile: this.$isMobile(),
			modalShow: false,
		};
	},
	computed: {},
	components: {},
	methods: {
		async submitForm() {
			const result = await this.v$.$validate();
			if (!result) {
				return;
			}
			let toastPosition = this.isMobile ? "bottom-center" : "top-center";
			let loader = this.$loading.show({
				container: this.fullPage ? null : this.$refs.formContainer,
			});
			this.changePasswordForm.phoneNo = this.$store.getters["identityServer/getLoginId"];
			await this.$store
				.dispatch("member/changePassword", this.changePasswordForm)
				.then(() => {
					this.toast.success(this.$t("ChangePasswordModal.ChangePasswordSuccess"), {
						position: toastPosition,
						timeout: 2000,
						onClose: () => this.logOut(),
					});
					this.hideModal();
					loader.hide();
				})
				.catch(() => {
					loader.hide();
					this.toast.error(this.$t("ChangePasswordModal.ChangePasswordFailed"), { position: toastPosition });
				});
		},
		hideModal() {
			this.modalShow = false;
		},
		logOut() {
			this.$store.dispatch("identityServer/logOut");
			window.location.href = "/";
		},
		resetData() {
			this.v$.$reset();
			this.changePasswordForm.phoneNo = "";
			this.changePasswordForm.currentPassword = null;
			this.changePasswordForm.newPassword = "";
			this.changePasswordForm.confirmPassword = "";
		},
	},
};
</script>
