import axios from "axios";

const httpClient = axios.create({
	baseURL: "https://webservices.sentemail.online/api/public/ip",
	headers: {
		Accept: "application/json",
	},
});

export default httpClient;
