export default {
	Interchange: "Switch Me",
	WinoverExist: "Winover Exist.",
	NotTransferred: "Please note that balance will not be transferred over.",
	WaitingCreateAccountMessage: "Please wait while we create your account...",
	WaitingTransferMessage: "Your account balance is being transferred...",
	ExternalWinoverExistMessage: "Balance Transfer Failed due to Winover exist at destination site.",
	OK: "OK",
	Cancel: "Cancel",
	TransferFailed: "Balance transfer failed.",
	Proceed: "Proceed",
	DoYouWishToProceed: "Do you wish to proceed?",
};
