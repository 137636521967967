export default {
	Interchange: "Tukar Saya",
	WinoverExist: "Winover wujud",
	NotTransferred: "Harap maklum bahawa baki tidak akan dipindahkan.",
	WaitingCreateAccountMessage: "Sila tunggu semasa kami mendaftarkan akaun anda...",
	WaitingTransferMessage: "Baki akaun anda sedang dipindahkan...",
	ExternalWinoverExistMessage: "Pemindahan Baki Gagal kerana Winover wujud di tapak destinasi.",
	OK: "OK",
	Cancel: "Cancel",
	TransferFailed: "Pemindahan baki gagal.",
	Proceed: "Teruskan",
	DoYouWishToProceed: "Adakah anda ingin meneruskan?",
};
