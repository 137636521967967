export default {
  ChangePassword: "Tukar Kata Laluan",
  CurrentPassword: "Kata Laluan Terkini",
  NewPassword: "Kata Laluan Baharu",
  ConfirmPassword: "Sahkan Kata Laluan Baharu",
  Submit: "Tukar Kata Laluan",
  Cancel: "Batal",
  ForgotYourPassword: "Lupa Kata Laluan",
  currentPasswordRequired: "*Kata Laluan Terkini Diperlukan",
  newPasswordRequired: "*Kata Laluan Baharu Diperlukan",
  confirmPasswordRequired: "*Sahkan Kata Laluan Baharu Adalah Diperlukan",
  NotSamePassword: "*Kata Laluan Tidak Sama",
  PasswordMin: "*Kata Laluan mesti sekurangnya {minLength} bilangan",
  PasswordMax: "*Kata Laluan mesti tidak melebihi {maxLength} bilangan",
  ChangePasswordSuccess : "Tukar Kata Laluan Berjaya",
  ChangePasswordFailed : "Tukar Kata Laluan Failed"
};
