import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { WorldCupStateInterface } from "./state";
import { WorldCupByMatchesResponse } from "@/types/WorldCup/Response/WorldCupByMatchesResponse";
import { WorldCupMatchesResponse } from "@/types/WorldCup/Response/WorldCupMatchesResponse";
import { WorldCupStandingResponse } from "@/types/WorldCup/Response/WorldCupStandingResponse";
import { WorldCupTeamsResponse } from "@/types/WorldCup/Response/WorldCupTeamsResponse";

export const getters: GetterTree<WorldCupStateInterface, RootState> = {
	getMatchesByDay(state): WorldCupByMatchesResponse[] {
		return state.worldCupByMatches;
	},
	getMatches(state): WorldCupMatchesResponse[] {
		return state.worldCupMatches;
	},
	getMatchesById(state): WorldCupMatchesResponse[] {
		return state.worldCupMatches;
	},
	getStanding(state): WorldCupStandingResponse[] {
		return state.worldCupStanding;
	},
	getStandingByGroup(state): WorldCupStandingResponse[] {
		return state.worldCupStanding;
	},
	getTeams(state): WorldCupTeamsResponse[] {
		return state.worldCupTeam;
	},
	getTeamsById(state): WorldCupTeamsResponse[] {
		return state.worldCupTeam;
	},
};
