import { StatementResponse } from "@/types/Payment/Response/StatementResponse";
import { BonusReferralResponse } from "@/types/Payment/Response/BonusReferralResponse";

export interface PaymentStateInterface {
  depositStatement: StatementResponse[];
  withdrawalStatement: StatementResponse[];
  transferStatement: StatementResponse[];
  adjustment: StatementResponse[];
  bonusReferral: BonusReferralResponse[];
}

function state(): PaymentStateInterface {
  return {
    depositStatement: {} as StatementResponse[],
    withdrawalStatement: {} as StatementResponse[],
    transferStatement: {} as StatementResponse[],
    adjustment: [],
    bonusReferral: {} as BonusReferralResponse[],
  };
}

export default state;
