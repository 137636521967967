import { ActionTree } from "vuex";
import bonusService from "@/network/BonusService/Bonus";
import { BonusStateInterface } from "./state";
import { RootState } from "@/store/state";
import { BonusMutations } from "./mutations";

export const actions: ActionTree<BonusStateInterface, RootState> = {
	async fetchBonusList(context) {
		if (context.state.bonusList.length > 0) {
			return;
		}
		const res = await bonusService.getBonusList();
		context.commit(BonusMutations.SET_BONUS_LIST, res);
	},
	async fetchMemberBonusList(context) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getBonusByMemberGroup(memberId);
		context.commit(BonusMutations.SET_MEMBER_BONUS, res);
	},
	async fetchUniqueCode(context) {
		const res = await bonusService.getUniqueCode();
		context.commit(BonusMutations.SET_MEMBER_BONUS, res);
	},
	async fetchUniqueCodeStatus(context, payload) {
		const res = await bonusService.getUniqueCodeClaimedStatus(payload.loginId, payload.uniqueCodeId);
		return res;
	},
	async fetchBonusClaim(context, payload) {
		const memberId = context.rootGetters["identityServer/getMemberId"];
		const res = await bonusService.getBonusClaimed(memberId, payload.from, payload.to);
		context.commit(BonusMutations.SET_BONUS_CLAIMED, res);
	},
};
