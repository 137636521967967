import { Module } from "vuex";
import { RootState } from "@/store/state";
import state, { PaymentStateInterface } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const payment: Module<PaymentStateInterface, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
