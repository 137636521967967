export default {
  StartDate: "Tarikh Mula*",
  EndDate: "Tarikh Tamat*",
  Submit: "Hantar",
  BetID: "Bet ID",
  ID: "ID",
  GameName: "Nama Permainan",
  Provider: "Provider Permainan",
  BetAmount: "Jumlah Pertaruhan",
  WinLossAmount: "Menang/Kalah",
  GameDate: "Tarikh Main",
};
