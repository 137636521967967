export default {
  RemainingTurnover: "Baki Turnover",
  TargetWinover: "Sasaran Winover",
  Slots: "Slots",
  LiveCasino: "Live Casino",
  Fishing: "Fishing",
  Sports: "Sports",
  StartDate: "Tarikh Mula*",
  EndDate: "Tarikh Tamat*",
  Submit: "Hantar",
  TransactionID: "ID Transaksi",
  BonusName: "Nama Bonus",
  BonusAmount: "Amaun Bonus",
  TargetType: "Jenis Sasaran",
  TargetAmount: "Amaun Sasaran",
  Status: "Status",
  CreatedDate: "Tarikh Transaksi",
};
