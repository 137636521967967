export default {
  ChangePassword: "Change Password",
  CurrentPassword: "Current Password",
  NewPassword: "New Password",
  ConfirmPassword: "Confirm Password",
  Submit: "Update Password",
  Cancel: "Cancel",
  ForgotYourPassword: "Forgot Your Password",
  currentPasswordRequired: "*Current Password Required",
  newPasswordRequired: "*New Password Required",
  confirmPasswordRequired: "*Confirm Password Required",
  NotSamePassword: "*Not Same Password",
  PasswordMin: "*Password at least {minLength} character",
  PasswordMax: "*Password not more than {maxLength} character",
  ChangePasswordSuccess : "Change Password Success",
  ChangePasswordFailed : "Change Password Failed"
};
