<template>
  <b-img :src="imgUrl" :class="mode" fluid-grow></b-img>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      required: false,
      default: "",
    },
    mode: {
      type: String,
      required: false,
      default: "",
    },
    isFullUrl: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    imgUrl() {
      if (this.imgSrc) {
        if (this.isFullUrl) {
          return this.imgSrc;
        } else {
          return process.env.VUE_APP_Media_Domain + this.imgSrc;
        }
      } else {
        return process.env.VUE_APP_Media_Domain + "ibc/assets/images/Banner/default.png";
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>
