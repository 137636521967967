import { ContactResponse } from "@/types/Brand/Response/ContactResponse";

export interface BrandStateInterface {
  contact: ContactResponse[];
}

function state(): BrandStateInterface {
  return {
    contact: [],
  };
}

export default state;
