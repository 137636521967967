import httpClient from "./HttpClient";

const getLaunchGameUrl = (vendorId: number, memberId: string, category: string, isMobile: boolean, lang: string): Promise<string> => {
	const endPoint = `/api/Games/LaunchGame`;
	const params = {
		memberId: memberId,
		vendorId: vendorId,
		category: category,
		isMobile : isMobile,
		lang : lang
	};
	return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export { getLaunchGameUrl };
