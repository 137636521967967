import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { GlobalSettingStateInterface } from "./state";
import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";

export const getters: GetterTree<GlobalSettingStateInterface, RootState> = {
  getGlobalSetting(state): GlobalSettingResponse[] {
    return state.globalSetting;
  },
};
