import { ActionTree } from "vuex";
import vendorService from "@/network/VendorService/Vendor";
import { VendorStateInterface } from "./state";
import { RootState } from "@/store/state";
import { VendorMutations } from "./mutations";

export const actions: ActionTree<VendorStateInterface, RootState> = {
  async fetchVendor(context) {
    if(Object.keys(context.state.vendor).length !== 0){
      return;
    }
    const vendorList = await vendorService.getVendor();
    context.commit(VendorMutations.SET_VENDOR_INFO, vendorList[0]);
  },
  async fetchExternalVendor(context,payload) {
    const vendorList = await vendorService.getVendor(payload);
    return vendorList;
  },
  async fetchVendorAccount(context) {
    const vendor = context.state.vendor;
    const vendorAccount = await vendorService.getVendorAccount(vendor.id);
    context.commit(VendorMutations.SET_VENDOR_ACCOUNT,vendorAccount);

    return vendorAccount;
  },
  async fetchCategoryList(context) {
    if(Object.keys(context.state.categoryList).length !== 0){
      return;
    }
    const categoryList = await vendorService.getCategoryList();
    context.commit(VendorMutations.SET_CATEGORY_LIST, categoryList);
  }
};
