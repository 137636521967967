import { GlobalSettingResponse } from "@/types/GlobalSetting/Response/GlobalSettingResponse";

export interface GlobalSettingStateInterface {
  globalSetting: GlobalSettingResponse[];
}

function state(): GlobalSettingStateInterface {
  return {
    globalSetting: [],
  };
}

export default state;
