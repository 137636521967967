export default {
  Deposit: "存款",
  StartDate: "开始日期*",
  EndDate: "结束日期*",
  Submit: "提交",
  TransactionID: "备注号",
  TransactionType: "方式",
  Amount: "共计",
  CreatedDate: "日期",
  Status: "状态",
  AutoDeposit: "自动存款",
  ManualDeposit: "手动存款",
};
