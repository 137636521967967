export default {
  RemainingTurnover: "Remaining Turnover",
  TargetWinover: "Target Winover",
  Slots: "Slots",
  LiveCasino: "Live Casino",
  Fishing: "Fishing",
  Sports: "Sports",
  StartDate: "Start Date*",
  EndDate: "End Date*",
  Submit: "Submit",
  TransactionID: "Transaction ID",
  BonusName: "Bonus Name",
  BonusAmount: "Bonus Amount",
  TargetType: "Target Type",
  TargetAmount: "Target Amount",
  Status: "Status",
  CreatedDate: "Created Date",
};
