import { TenantStateInterface } from "./state";
import { ClientTenantInfoResponse } from "@/types/Tenant/Response/ClientTenantInfoResponse";
import { MutationTree } from "vuex";

export enum TenantMutations {
    SET_CLIENT_TENANT_INFO = "SET_CLIENT_TENANT_INFO",
}

export const mutations: MutationTree<TenantStateInterface> = {
    [TenantMutations.SET_CLIENT_TENANT_INFO](state, payload: ClientTenantInfoResponse[]) {
        state.ClientTenantInfo = payload;
    }
};
