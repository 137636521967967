export default {
	Group: "Group {group}",
	Teams: "Teams",
	P: "P",
	W: "W",
	D: "D",
	L: "L",
	GF: "GF",
	GA: "GA",
	"+-": "+/-",
	PTS: "PTS",
	HideMatches: "Hide Matches",
	ShowMatches: "Show Matches",
	TodayMatches: "Today's Matches",
	TomorrowMatches: "Tomorrow's Matches",
	UpcomingMatches: "Upcoming Matches",
	PreviousMatches: "Previous Matches",
	notstarted: "Not Started",
	h1: "1st Half",
	hf: "Half Time",
	h2: "2nd Half",
	finished: "Finished",
	Date: "Date",
	Time: "Time",
	HomeTeam: "Home Team",
	HomeScore: "Home Team Score",
	AwayTeam: "Away Team",
	AwayScore: "Away Team Score",
	Status: "Status",
};
