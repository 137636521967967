import { ActionTree } from "vuex";
import memberAccountService from "@/network/MemberAccountService/MemberAccount";
import actionAuditService from "@/network/ActionAuditService/ActionAudit";
import { MemberAccountStateInterface } from "./state";
import { RootState } from "@/store/state";
import { MemberAccountMutations } from "./mutations";
import { ResetWinoverRequest } from "@/types/MemberAccount/Request/ResetWinoverRequest";
import { ActionAuditContent } from "@/types/ActionAudit/Request/ActionAuditRequest";

export const actions: ActionTree<MemberAccountStateInterface, RootState> = {
  async fetchVendorBalance(context, payload) {
    const vendorAccountId = context.rootGetters["vendors/getVendorAccount"].id;

    const loginId = context.rootGetters["identityServer/getLoginId"];
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const res = await memberAccountService.getVendorBalance(
      vendorAccountId,
      memberId,
      loginId,
      payload.isFromDB
    );
    context.commit(MemberAccountMutations.SET_VENDOR_BALANCE, res);
  },
  async fetchMemberWinover(context) {
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const res = await memberAccountService.getMemberWinover(memberId);
    context.commit(MemberAccountMutations.SET_MEMBER_WINOVER, res);
  },
  async fetchExternalVendorWinover(context, payload) {
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const res = await memberAccountService.getMemberWinover(memberId, payload);
    return res;
  },
  async resetWinover(context, payload) {
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const winoverRequest: ResetWinoverRequest = {
      group: payload.group,
      winover: 0,
      lockdown: 0,
      memberId: memberId,
      clientId: process.env.VUE_APP_IdentityServer_ClientId,
    };
    const beforeWinoverRequest: ResetWinoverRequest = {
      group: payload.group,
      winover: payload.winover,
      lockdown: payload.lockdown,
      memberId: memberId,
      clientId: process.env.VUE_APP_IdentityServer_ClientId,
    };

    const actionContent: ActionAuditContent = {
      before: beforeWinoverRequest,
      after: winoverRequest,
    };
    await memberAccountService.resetWinover(winoverRequest);
    await actionAuditService.addActionAuditTrail(
      "MemberAccoutService",
      "Reset Winover",
      JSON.stringify(actionContent),
      memberId
    );
  },
  async fetchMemberAccount(context) {
    if (Object.keys(context.state.memberAccount).length !== 0) {
      return;
    }
    const vendorAccount = context.rootGetters["vendors/getVendorAccount"];
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const res = await memberAccountService.getMemberAccount(
      vendorAccount.id,
      memberId
    );
    context.commit(MemberAccountMutations.SET_MEMBER_ACCOUNT, res);
  },
  async updateGamePassword(context, payload) {
    const vendor = context.rootGetters["vendors/getVendor"];
    const loginId = context.rootGetters["identityServer/getLoginId"];
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const res = await memberAccountService.updateGamePassword(
      loginId,
      memberId,
      vendor.id,
      payload.vendorGameLoginId,
      payload.vendorGamePassword,
      payload.phone,
      payload.newPassword
    );
    context.commit(MemberAccountMutations.SET_MEMBER_ACCOUNT, res);
  },
  async fetchVendorBalanceByTenantId(context, payload) {
    const memberId = context.rootGetters["identityServer/getMemberId"];
    const res = await memberAccountService.getMemberBalanceByTenantId(memberId);
    const clientId = process.env.VUE_APP_IdentityServer_ClientId;
    const mainclient = res.filter((r) => r.clientId == clientId)[0];
	const mainClientIndex = res.indexOf(mainclient);
	res.splice(mainClientIndex,1);
    res.unshift(mainclient);
	console.log(res);
    context.commit(MemberAccountMutations.SET_ALL_SSO_MEMBER_BALANCE, res);
  },
};
