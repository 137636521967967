export default {
	Withdraw: "提款",
	withdrawalAmount: "*提款金额",
	accountBank: "银行",
	accountName: "户口名字",
	accountNumber: "户口号码",
	AmountRequired: "*需要提款金额",
	Min: "最低金额: {withdrawalMin}",
	Max: "最高金额: {withdrawalMax}",
	Currentbalance: "当前余额:",
	LastLogin: "上次登录",
	AmountMin: "*金额数量少于 {minValue} ",
	AmountMax: "*金额数量不超过 {maxValue} ",
	Cancel: "取消",
	WithdrawalSubmitted: "提款提交成功",
	WithdrawalError: "提款失败",
	WinoverExist: "总赢额存在",
	ReachDailyLimit: "您已达到每日提款限额。 请联系我们的客服。",
	InsufficientBalance: "您的余额不足",
	AllowOneActiveWithdrawal: "您之前的提款申请仍在处理中。 如果您需要任何帮助，请联系我们的客服。",
};
