import { SurePayDepositResponse } from "@/types/PaymentChannel/Response/SurePayDepositResponse";

export interface PaymentChannelStateInterface{
    surepayDeposit: SurePayDepositResponse;
}

function state(): PaymentChannelStateInterface{
    return{
        surepayDeposit: {} as SurePayDepositResponse
    };
}
export default state;
