<template>
	<b-container fluid class="standingContainer">
		<b-container class="mb-5">
			<div class="d-flex justify-content-end align-items-center w-100 mt-3">
				<div @click="refreshStanding()">
					<font-awesome-icon
						:icon="['fas', 'repeat']"
						:class="{ 'fa-spin': isSpin }"
						class="refresh-icon"
					></font-awesome-icon>
				</div>
			</div>
			<template v-if="todayMatches.length > 0">
				<div class="h3 groupTitle" id="TodayMatches">{{ $t("StandingsItem.TodayMatches") }}</div>
				<div class="table-responsive">
					<table class="table matchingTable">
						<thead class="thead-dark">
							<tr>
								<th>{{ $t("StandingsItem.Date") }}</th>
								<th>{{ $t("StandingsItem.Time") }}</th>
								<th>{{ $t("StandingsItem.HomeTeam") }}</th>
								<th>{{ $t("StandingsItem.HomeScore") }}</th>
								<th>{{ $t("StandingsItem.AwayTeam") }}</th>
								<th>{{ $t("StandingsItem.AwayScore") }}</th>
								<th>{{ $t("StandingsItem.Status") }}</th>
							</tr>
						</thead>
						<tbody class="tbody-light">
							<tr v-for="matchesItem in todayMatches" :key="matchesItem.id">
								<td>{{ matchesItem.match_dateOnly }}</td>
								<td>{{ matchesItem.match_timeOnly }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image
												:imgSrc="matchesItem.home_flag"
												mode="country_flag_img"
												:isFullUrl="true"
											></base-image>
										</div>
										<div class="team_name">
											{{ matchesItem.home_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.home_score }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image :imgSrc="matchesItem.away_flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">
											{{ matchesItem.away_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.away_score }}</td>
								<td>
									<div class="d-flex justify-content-center align-items-center">
										<font-awesome-icon
											:icon="['fas', 'circle']"
											class="live-icon"
											v-if="matchesItem.time_elapsed == 'h1' || matchesItem.time_elapsed == 'h2'"
										></font-awesome-icon>
										<span class="status_text">{{ $t("StandingsItem." + matchesItem.time_elapsed) }}</span>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-if="tomorrowMatches.length > 0">
				<div class="h3 groupTitle" id="TomorrowMatches">{{ $t("StandingsItem.TomorrowMatches") }}</div>
				<div class="table-responsive">
					<table class="table matchingTable">
						<thead class="thead-dark">
							<tr>
								<th>{{ $t("StandingsItem.Date") }}</th>
								<th>{{ $t("StandingsItem.Time") }}</th>
								<th>{{ $t("StandingsItem.HomeTeam") }}</th>
								<th>{{ $t("StandingsItem.HomeScore") }}</th>
								<th>{{ $t("StandingsItem.AwayTeam") }}</th>
								<th>{{ $t("StandingsItem.AwayScore") }}</th>
								<th>{{ $t("StandingsItem.Status") }}</th>
							</tr>
						</thead>
						<tbody class="tbody-light">
							<tr v-for="matchesItem in tomorrowMatches" :key="matchesItem.id">
								<td>{{ matchesItem.match_dateOnly }}</td>
								<td>{{ matchesItem.match_timeOnly }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image :imgSrc="matchesItem.home_flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">
											{{ matchesItem.home_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.home_score }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image :imgSrc="matchesItem.away_flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">
											{{ matchesItem.away_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.away_score }}</td>
								<td>
									<span class="status_text">{{ $t("StandingsItem." + matchesItem.time_elapsed) }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-if="upcomingMatches.length > 0">
				<div class="h3 groupTitle" id="UpcomingMatches">{{ $t("StandingsItem.UpcomingMatches") }}</div>
				<div class="table-responsive">
					<table class="table matchingTable">
						<thead class="thead-dark">
							<tr>
								<th>{{ $t("StandingsItem.Date") }}</th>
								<th>{{ $t("StandingsItem.Time") }}</th>
								<th>{{ $t("StandingsItem.HomeTeam") }}</th>
								<th>{{ $t("StandingsItem.HomeScore") }}</th>
								<th>{{ $t("StandingsItem.AwayTeam") }}</th>
								<th>{{ $t("StandingsItem.AwayScore") }}</th>
								<th>{{ $t("StandingsItem.Status") }}</th>
							</tr>
						</thead>
						<tbody class="tbody-light">
							<tr v-for="matchesItem in upcomingMatches" :key="matchesItem.id">
								<td>{{ matchesItem.match_dateOnly }}</td>
								<td>{{ matchesItem.match_timeOnly }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image :imgSrc="matchesItem.home_flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">
											{{ matchesItem.home_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.home_score }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image :imgSrc="matchesItem.away_flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">
											{{ matchesItem.away_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.away_score }}</td>
								<td>
									<span class="status_text">{{ $t("StandingsItem." + matchesItem.time_elapsed) }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
			<template v-if="previousMatches.length > 0">
				<div class="h3 groupTitle" id="PreviousMatches">{{ $t("StandingsItem.PreviousMatches") }}</div>
				<div class="table-responsive">
					<table class="table matchingTable">
						<thead class="thead-dark">
							<tr>
								<th>{{ $t("StandingsItem.Date") }}</th>
								<th>{{ $t("StandingsItem.Time") }}</th>
								<th>{{ $t("StandingsItem.HomeTeam") }}</th>
								<th>{{ $t("StandingsItem.HomeScore") }}</th>
								<th>{{ $t("StandingsItem.AwayTeam") }}</th>
								<th>{{ $t("StandingsItem.AwayScore") }}</th>
								<th>{{ $t("StandingsItem.Status") }}</th>
							</tr>
						</thead>
						<tbody class="tbody-light">
							<tr v-for="matchesItem in previousMatches" :key="matchesItem.id">
								<td>{{ matchesItem.match_dateOnly }}</td>
								<td>{{ matchesItem.match_timeOnly }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image :imgSrc="matchesItem.home_flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">
											{{ matchesItem.home_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.home_score }}</td>
								<td>
									<div class="d-flex align-items-center justify-content-start">
										<div class="team_flag">
											<base-image :imgSrc="matchesItem.away_flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">
											{{ matchesItem.away_team_en }}
										</div>
									</div>
								</td>
								<td>{{ matchesItem.away_score }}</td>
								<td>
									<span class="status_text">{{ $t("StandingsItem." + matchesItem.time_elapsed) }}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
			<hr class="mt-5" />
			<template v-for="standingItem in standingsList" :key="standingItem.group">
				<div class="h3 groupTitle">{{ $t("StandingsItem.Group", { group: standingItem.group }) }}</div>
				<div class="table-responsive">
					<table class="table">
						<thead class="thead-dark">
							<tr>
								<th>{{ $t("StandingsItem.Teams") }}</th>
								<th>{{ $t("StandingsItem.P") }}</th>
								<th>{{ $t("StandingsItem.W") }}</th>
								<th>{{ $t("StandingsItem.D") }}</th>
								<th>{{ $t("StandingsItem.L") }}</th>
								<th>{{ $t("StandingsItem.GF") }}</th>
								<th>{{ $t("StandingsItem.GA") }}</th>
								<th>{{ $t("StandingsItem.+-") }}</th>
								<th>{{ $t("StandingsItem.PTS") }}</th>
							</tr>
						</thead>
						<tbody class="tbody-light">
							<tr v-for="team in standingItem.teams" :key="team.team_id">
								<td>
									<div class="d-flex justify-content-start align-items-center">
										<div class="country_flag">
											<base-image :imgSrc="team.flag" mode="country_flag_img" :isFullUrl="true" />
										</div>
										<div class="team_name">{{ team.name_en }}</div>
									</div>
								</td>
								<td>{{ team.mp }}</td>
								<td>{{ team.w }}</td>
								<td>{{ team.d }}</td>
								<td>{{ team.l }}</td>
								<td>{{ team.gf }}</td>
								<td>{{ team.ga }}</td>
								<td>{{ team.gd }}</td>
								<td>{{ team.pts }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="w-100 d-flex justify-content-center align-items-center">
					<div class="btn btn-showHide" @click="showHideMatches(standingItem.group)">
						{{
							groupList.find((x) => x.group == standingItem.group).isShow
								? $t("StandingsItem.HideMatches")
								: $t("StandingsItem.ShowMatches")
						}}
						<font-awesome-icon
							:icon="
								groupList.find((x) => x.group == standingItem.group).isShow
									? ['fas', 'angle-up']
									: ['fas', 'angle-down']
							"
						></font-awesome-icon>
					</div>
				</div>
				<b-container class="matchContainer" v-show="groupList.find((x) => x.group == standingItem.group).isShow">
					<div
						v-for="matchesItem in matchesList.filter((x) => x.group == standingItem.group)"
						:key="matchesItem.id"
						class="matchesList"
					>
						<div class="home_team">
							<div class="team_name">{{ matchesItem.home_team_en }}</div>
							<div class="team_flag">
								<base-image :imgSrc="matchesItem.home_flag" mode="country_flag_img" :isFullUrl="true" />
							</div>
							<div class="team_score">{{ matchesItem.home_score }}</div>
						</div>
						<div class="away_team">
							<div class="team_name">{{ matchesItem.away_team_en }}</div>
							<div class="team_flag">
								<base-image :imgSrc="matchesItem.away_flag" mode="country_flag_img" :isFullUrl="true" />
							</div>
							<div class="team_score">{{ matchesItem.away_score }}</div>
						</div>
					</div>
				</b-container>
			</template>
		</b-container>
	</b-container>
</template>
<style scoped>
.groupTitle {
	color: #000;
	display: flex;
	justify-content: start;
	margin-top: 2rem;
}
.country_flag {
	width: 3rem;
	border-radius: 50%;
}
.matchesList {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	margin: 1rem 0px;
}
.home_team {
	display: flex;
	justify-content: end;
	align-items: center;
	width: 50%;
}
.team_name {
	color: #000000;
	margin: 0px 0.5rem;
}
.away_team {
	display: flex;
	justify-content: start;
	align-items: center;
	flex-direction: row-reverse;
	width: 50%;
}
.team_score {
	background: #000000;
	color: #ffffff;
	padding: 1rem;
	margin: 0px 0.1rem;
	font-size: 1.5rem;
}
.country_flag > .country_flag_img {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}
.team_flag {
	width: 3rem;
	border-radius: 50%;
}
.team_flag > .country_flag_img {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}
.team_name {
	margin-left: 0.5rem;
}
.thead-dark {
	color: #ffffff;
	background: #000000;
}
.tbody-light {
	background: #ffffff;
	color: #000000;
}
.table {
	margin: 0px;
}
.table-responsive {
	border-radius: 1rem;
}

.btn-showHide {
	color: #28356a;
	padding: 1rem;
	font-size: 1rem;
}
.refresh-icon {
	font-size: 1.5rem;
	color: #28356a;
	cursor: pointer;
}

.matchingTable .team_flag {
	width: 1.5rem;
	height: 1.5rem;
}
.matchingTable .team_flag > .country_flag_img {
	height: 1.5rem !important;
}

.live-icon {
	font-size: 0.5rem;
	color: #20c997;
	margin: 0 0.5rem;
}
@media (max-width: 767px) {
	.standingContainer {
		font-size: 0.8rem !important;
	}
	.country_flag_img {
		width: 2rem !important;
		height: 2rem !important;
	}
	.team_score {
		font-size: 1rem;
	}
	.matchContainer {
		padding: 0px;
	}
	.matchContainer .team_name {
		word-break: break-word;
	}
}
</style>
<script>
import moment from "moment";
export default {
	data() {
		return {
			firstDate: "20/11/2022",
			todayDate: moment().format("DD/MM/YYYY"),
			standingsList: [],
			groupList: [],
			matchesList: [],
			todayMatches: [],
			tomorrowMatches: [],
			upcomingMatches: [],
			previousMatches: [],
			isFirstLoad: true,
			isFirstLoadMatches: true,
			isSpin: false,
			iconDown: ["fas", "angel-down"],
			iconUp: ["fas", "angel-up"],
		};
	},
	props: ["scrollTo"],
	computed: {
		getStanding() {
			return this.$store.getters["worldCup/getStanding"];
		},
		getMatch() {
			return this.$store.getters["worldCup/getMatches"];
		},
	},
	methods: {
		showHideMatches(group) {
			this.groupList = this.groupList.map((x) => {
				if (x.group == group) {
					x.isShow = !x.isShow;
				}
				return x;
			});
		},
		async refreshStanding() {
			this.isSpin = true;
			await this.$store.dispatch("worldCup/fetchStanding").then(() => {
				this.isSpin = false;
			});
			await this.$store.dispatch("worldCup/fetchMatches");
		},
		filterTodayMatches() {
			this.todayMatches = this.matchesList.filter(
				(x) => moment(x.match_date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") == this.todayDate
			);
		},
		filterTomorrowMatches() {
			this.tomorrowMatches = this.matchesList.filter(
				(x) =>
					moment(x.match_date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") ==
					moment(this.todayDate, "DD/MM/YYYY").add(1, "days").format("DD/MM/YYYY")
			);
		},
		filterUpcomingMatches() {
			this.upcomingMatches = this.matchesList.filter(
				(x) =>
					moment(x.match_date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") != this.todayDate &&
					moment(x.match_date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") !=
						moment(this.todayDate, "DD/MM/YYYY").add(1, "days").format("DD/MM/YYYY") &&
					x.match_dateStr > moment(this.todayDate, "DD/MM/YYYY").unix()
			);
		},
		filterPreviousMatches() {
			this.previousMatches = this.matchesList.filter(
				(x) =>
					moment(x.match_date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") != this.todayDate &&
					x.match_dateStr < moment(this.todayDate, "DD/MM/YYYY").unix()
			);
		},
		getHeight() {
			if (this.isFirstLoadMatches) {
				let isScroll = false;
				let scrollId = "all";
				switch (this.scrollTo.toLowerCase()) {
					case "today":
						isScroll = true;
						scrollId = "TodayMatches";
						break;
					case "tomorrow":
						isScroll = true;
						scrollId = "TomorrowMatches";
						break;
					case "upcoming":
						isScroll = true;
						scrollId = "UpcomingMatches";
						break;
					case "previous":
						isScroll = true;
						scrollId = "PreviousMatches";
						break;
				}
				if (isScroll) {
					setTimeout(() => {
						this.isFirstLoadMatches = false;
						const scrollTop = document.getElementById(scrollId).offsetTop;
						window.scrollTo(0, scrollTop - 150);
					}, 100);
				}
			}
		},
	},
	watch: {
		getStanding(standing) {
			if (standing.status == "success") {
				this.standingsList = standing.data;
				if (this.isFirstLoad) {
					this.isFirstLoad = false;
					this.standingsList.map((standing) => {
						if (this.groupList.filter((x) => x.group == standing.group).length == 0) {
							const groupItem = { group: standing.group, isShow: false };
							this.groupList.push(groupItem);
						}
					});
				}
			}
		},
		getMatch(matches) {
			if (matches.status == "success") {
				this.matchesList = matches.data.map((item) => {
					item.match_date = moment(item.local_date, "M/D/YYYY HH:mm").add(5, "hours").format("DD/MM/YYYY HH:mm");
					item.match_dateOnly = moment(item.local_date, "M/D/YYYY HH:mm").add(5, "hours").format("DD/MM/YYYY");
					item.match_timeOnly = moment(item.local_date, "M/D/YYYY HH:mm").add(5, "hours").format("HH:mm");
					item.match_dateStr = moment(item.local_date, "M/D/YYYY HH:mm").unix();
					return item;
				});
				this.matchesList.sort((a, b) => {
					return (
						new Date(moment(a.match_date, "DD/MM/YYYY HH:mm").format()).getTime() -
						new Date(moment(b.match_date, "DD/MM/YYYY HH:mm").format()).getTime()
					);
				});
				this.filterTodayMatches();
				this.filterTomorrowMatches();
				this.filterUpcomingMatches();
				this.filterPreviousMatches();
				this.getHeight();
			}
		},
	},
	async created() {
		let loader = this.$loading.show({
			container: this.fullPage ? null : this.$refs.formContainer,
		});
		await this.$store
			.dispatch("worldCup/fetchMatches")
			.then(() => {
				loader.hide();
			})
			.catch(() => {
				loader.hide();
			});
		await this.$store.dispatch("worldCup/fetchStanding");
	},
};
</script>
