export interface IpAddressStateInterface {
    ipAddress: string;
  }
  
  function state(): IpAddressStateInterface {
    return {
      ipAddress: null,
    };
  }
  
  export default state;