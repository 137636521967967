<template>
	<b-container fluid class="downloadBg pt-4 pb-2" :style="{ backgroundImage: 'url(' + downloadBackground + ')' }">
		<b-container class="h-100">
			<div class="d-flex align-items-center h-100">
				<b-col cols="12" md="6" lg="4" class="ml-4">
					<div class="homeDownloadTitle">{{ $t("HomeDownload.DOWNLOADNOW") }}</div>
					<div class="homeDownloadDesc">{{ $t("HomeDownload.Description1") }}.</div>
					<DownloadQR></DownloadQR>
				</b-col>
			</div>
		</b-container>
	</b-container>
</template>
<script>
import DownloadQR from "@/components/content/Download/DownloadQR.vue";
export default {
	data() {
		return {
			downloadBackground: process.env.VUE_APP_Media_Domain + "ibc/assets/images/Home/DownloadBg.png",
		};
	},
	components: {
		DownloadQR,
	},
};
</script>
<style scoped>
.downloadBg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	height: 100%;
}
.homeDownloadTitle {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: calc(10px + 1.16vw);
	line-height: 56%;
	text-align: center;
	color: #f03f5a;
}
.homeDownloadDesc {
	margin-top: 1.6rem;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: calc(10px + 0.42vw);
	line-height: 118%;
	text-align: center;
	color: #ffffff;
}

@media (max-width: 767px) {
	.downloadBg {
		background-image: none !important;
	}
}
</style>
