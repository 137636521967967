import { TierSettingGroupByTierResponse } from "@/types/Rebate/Response/TierSettingGroupByTierResponse";

export interface RebateStateInterface {
  TierSettingGroupByTier: TierSettingGroupByTierResponse;
}

function state(): RebateStateInterface {
  return {
    TierSettingGroupByTier: {} as TierSettingGroupByTierResponse,
  };
}

export default state;
