export default {
	Deposit: "Deposit",
	depositAmount: "*Deposit Amount",
	accountBank: "Bank",
	accountName: "Account Name",
	accountNumber: "Account Number",
	AmountRequired: "*Deposit Amount Required",
	Min: "Min: {depositMin}",
	Max: "Max: {depositMax}",
	AutoDeposit: "Auto Deposit",
	ManualDeposit: "Manual Deposit",
	TopUp: "Top Up",
	Currentbalance: "Current balance:",
	Browse: "Browse",
	Cancel: "Cancel",
	ChooseFile: "Choose File",
	FileNameRequired: "*File Name Required",
	DepositChannelRequired: "*Deposit Channel Required",
	LastLogin: "Last Login",
	AmountMin: "*Amount Number at least {minValue} ",
	AmountMax: "*Amount Number not more than {maxValue} ",
	Copy: "Copy",
	CopySuccess: "Copy Success",
	CopyError: "Empty Copy",
	bankRequired: "*Please select a bank",
	DepositError: "Deposit Failed. {error}",
	DepositSubmitted: "Deposit Submitted Successfully",
	Player: "Player",
	PleaseSelect: "Please Select",
	OnlineTransfer: "Online Transfer",
	CashDepositMachine: "Cash Deposit Machine",
	ATM: "ATM",
	OverTheCounter: "Over The Counter",
	Cheque: "Cheque",
	PleaseSelectBonus: "Please Select Bonus",
	NoApplyAnyBonus: "No Apply Any Bonus",
	WinoverExist: "Winover Exist",
	DepositNotAvailable: "Deposit is not available",
	ClearBalanceBeforeClaimBonus: "Please clear your balance before proceed to claim bonus",
	MakeSureBalanceBelowThreshold:
		"Please make sure your game balance is below {thresholdAmount} before proceed to claim bonus",
	AllowOneActiveDeposit:
		"Your previous deposit request is still in processing. Please contact our customer service if you need any assistance.",
	DepositChannel: "Deposit Channel:",
	QRPay: "QR Pay",
	Bank: "Banks：",
	PaymentMethod: "Payment Method:",
};
