import httpClient, { ClientId } from "./HttpClient";
import { TierSettingGroupByTierResponse } from "@/types/Rebate/Response/TierSettingGroupByTierResponse";

const getTierSettingGroupByTier = (): Promise<TierSettingGroupByTierResponse[]> => {
  const tenantID = process.env.VUE_APP_IdentityServer_TenantId;
  const endPoint = `/api/Rebate/TierSettingGroupByTier/${ClientId}/${tenantID}`;
  const params = {
    tenantId: process.env.VUE_APP_IdentityServer_TenantId,
  };
  return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
  getTierSettingGroupByTier,
};
