import { ActionTree } from "vuex";
import { RootState } from "../state";
import { PaymentChannelStateInterface } from "./state";
import PaymentChannel from "@/network/PaymentChannelService/PaymentChannel";
import { PaymentChannelMutations } from "./mutation";

export const actions: ActionTree<PaymentChannelStateInterface, RootState> = {
  async addSurePayDeposit(context, payload) {
    const loginId = context.rootGetters["identityServer/getLoginId"];
    const ipAddress = context.rootGetters["ipAddress/getIPAddress"];
    const res = await PaymentChannel.addSurePayDepositTicket(
      payload.bankCode,
      payload.amount,
      payload.bonusId,
      loginId,
      payload.methodId,
      ipAddress
    );
    return res;
  },
};
