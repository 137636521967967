export default {
	FastProcessingTime: "Masa Pemprosesan Pantas",
	FastProcessingTimeText: `Deposit > 5 Minit {newline} Pengeluaran > 15 Minit`,
	OptimizedForMobile: "Dioptimumkan untuk mudah alih",
	OptimizedForMobileText: "Permainan kami boleh diakses pada semua platform mudah alih yang popular",
	SafeAndSecure: "Selamat & Dipercayai",
	SafeAndSecureText: "Menikmati permainan dengan tenang dan selamat",
	LivechatSupport: "Sokongan Live Chat 24/7",
	LivechatSupportText: "Pasukan khidmat pelanggan kami berdedikasi untuk melayani anda 24/7",
};
