import httpClient, { ClientId } from "./HttpClient";
import { BetHistoryResponse } from "@/types/WinLosses/Response/BetHistoryResponse";
import moment from "moment";

const getBetHistory = (memberId: string, from: string, to: string): Promise<BetHistoryResponse[]> => {
  const endPoint = `/api/WinLosses/${memberId}/${ClientId}`;
  const dateTimeFromUTC = moment(from, 'YYYY-MM-DD').utc().format("YYYY-MM-DD HH:mm:ss")
  const dateTimeToUTC = moment(to, 'YYYY-MM-DD').utc().add(1,'days').add(-1,'milliseconds').format("YYYY-MM-DD HH:mm:ss")
  const params = {
    dateTimeFrom: dateTimeFromUTC,
    dateTimeTo: dateTimeToUTC,
  };
  return httpClient.get(endPoint, { params }).then((response) => response.data);
};

export default {
  getBetHistory,
};
